const IntroImages = {
  bg_1: require("./bg_1.png"),
  avt_right: require("./avt_right.png"),
  min_3: require("./3-min.png"),
  min_4: require("./4-min.png"),
  min_5: require("./5-min.jpg"),
  min_6: require("./6-min.jpg"),
  min_7: require("./7-min.png"),
  min_8: require("./8-min.png"),
  min_9: require("./9-min.png"),
  min_10: require("./10-min.png"),
  min_11: require("./11-min.png"),
  min_12: require("./12-min.png"),
  min_13: require("./13-min.png"),
  p_1: require('./p_1.png'),
  p_2: require('./p_2.png'),
  min_14: require('./giá trị cốt lõi.png'),
  dabosa: require('./dabosa.png'),
};

export default IntroImages;
