const TourInlandImages = {
    ic_calendar: require('./ic_calendar.png'),
    ic_location: require('./ic_location.png'),
    catba: require('./catba.png'),
    ninhbinh: require('./ninhbinh.png'),
    phuquoc: require('./phuquoc.png'),
    quangbinh: require('./quangbinh.png'),
    quynhon: require('./quynhon.png'),
    sapa: require('./sapa.png'),
    suphi: require('./suphi.png'),
    taxua: require('./taxua.jpg'),
    mucangchai: require('./mucangchai.png'),
    phanthiet: require('./phanthiet.png'),
    hagiang: require('./hagiang.png'),
    detail_sapa: require('./detail_sapa.jpg'),
    detail_suphi: require('./detail_suphi.jpg'),
    detail_taxua: require('./detail_taxua.jpg'),
    detail_mucangchai: require('./detail_mucangchai.jpg'),
    detail_hagiang: require('./detail_hagiang.jpg'),
    detail_ninhbinh: require('./detail_ninhbinh.jpg'),
    detail_quangbinh: require('./detail_quangbinh.jpg'),
    hue:require('./hue.png'),
    sapa1: require('./sapa1.png'),
    sapa2: require('./sapa2.png'),
    sapa3: require('./sapa3.png'),
    sapa4: require('./sapa4.png'),
    sapa5: require('./sapa5.png'),
    sapa6: require('./sapa6.png'),
    sapa7: require('./sapa7.png'),
    sapa8: require('./sapa8.jpg'),
    sapa9: require('./sapa9.jpg'),
    sapa10: require('./sapa10.jpg'),
    sapa11: require('./sapa11.jpg'),
    sapa12: require('./sapa12.jpg'),
    sapa13: require('./sapa13.jpg'),
    sapa14: require('./sapa14.jpg'),
    sapa15: require('./sapa15.jpg'),
    sapa16: require('./sapa16.jpg'),
    sapa17: require('./sapa17.jpg'),
    sapa18: require('./sapa18.jpg'),
    sapa19: require('./sapa19.jpg'),
    sapa20: require('./sapa20.jpg'),
    sapa21: require('./sapa21.jpg'),
    sapa22: require('./sapa22.jpg'),
    sapa23: require('./sapa23.jpg'),
    sapa24: require('./sapa24.jpg'),
    sapa25: require('./sapa25.jpg'),
    sapa26: require('./sapa26.jpg'),
    sapa27: require('./sapa27.jpg'),
    sapa28: require('./sapa28.jpg'),
    sapa29: require('./sapa29.jpg'),
    sapa30: require('./sapa30.jpg'),
    sapa31: require('./sapa31.jpg'),
    sapa32: require('./sapa32.jpg'),
    sapa33: require('./sapa33.jpg'),
    sapa34: require('./sapa34.jpg'),
    sapa35: require('./sapa35.jpg'),
    sapa36: require('./sapa36.jpg'),
    sapa37: require('./sapa37.jpg'),
    sapa38: require('./sapa38.jpg'),
    sapa39: require('./sapa38.jpg'),
    suphi1: require('./suphi1.jpg'),
    suphi2: require('./suphi2.jpg'),
    suphi3: require('./suphi3.jpg'),
    suphi4: require('./suphi4.jpg'),
    suphi5: require('./suphi5.jpg'),
    suphi6: require('./suphi6.jpg'),
    suphi7: require('./suphi7.jpg'),
    suphi8: require('./suphi8.jpg'),
    suphi9: require('./suphi9.jpg'),
    suphi10: require('./suphi10.jpg'),
    suphi11: require('./suphi11.jpg'),
    suphi12: require('./suphi12.jpg'),
    suphi13: require('./suphi13.jpg'),
    suphi14: require('./suphi14.jpg'),
    suphi15: require('./suphi15.jpg'),
    suphi16: require('./suphi16.jpg'),
    suphi17: require('./suphi17.jpg'),
    suphi18: require('./suphi18.jpg'),
    suphi19: require('./suphi19.jpg'),
    suphi20: require('./suphi20.jpg'),
    suphi21: require('./suphi21.jpg'),
    suphi22: require('./suphi22.jpg'),
    suphi23: require('./suphi23.jpg'),
    suphi24: require('./suphi24.jpg'),
    suphi25: require('./suphi25.jpg'),
    suphi26: require('./suphi26.jpg'),
    suphi27: require('./suphi27.jpg'),
    taxua1: require('./taxua1.jpg'),
    taxua2: require('./taxua2.jpg'),
    taxua3: require('./taxua3.jpg'),
    taxua4: require('./taxua4.jpg'),
    taxua5: require('./taxua5.jpg'),
    taxua6: require('./taxua6.jpg'),
    taxua7: require('./taxua7.jpg'),
    taxua8: require('./taxua8.jpg'),
    taxua9: require('./taxua9.jpg'),
    taxua10: require('./taxua10.jpg'),
    taxua11: require('./taxua11.jpg'),
    taxua12: require('./taxua12.jpg'),
    taxua13: require('./taxua13.jpg'),
    taxua14: require('./taxua14.jpg'),
    taxua15: require('./taxua15.jpg'),
    taxua16: require('./taxua16.jpg'),
    taxua17: require('./taxua17.jpg'),
    taxua18: require('./taxua18.jpg'),
    taxua19: require('./taxua19.jpg'),
    taxua20: require('./taxua20.jpg'),
    taxua21: require('./taxua21.jpg'),
    taxua22: require('./taxua22.jpg'),
    taxua23: require('./taxua23.jpg'),
    taxua24: require('./taxua24.jpg'),
    taxua25: require('./taxua25.jpg'),
    taxua26: require('./taxua26.jpg'),
    taxua27: require('./taxua27.jpg'),
    cangchai1: require('./cangchai1.jpg'),
    cangchai2: require('./cangchai2.jpg'),
    cangchai3: require('./cangchai3.jpg'),
    cangchai4: require('./cangchai4.jpg'),
    cangchai5: require('./cangchai5.jpg'),
    cangchai6: require('./cangchai6.jpg'),
    cangchai7: require('./cangchai7.jpg'),
    cangchai8: require('./cangchai8.jpg'),
    cangchai9: require('./cangchai9.jpg'),
    cangchai10: require('./cangchai10.jpg'),
    cangchai11: require('./cangchai11.jpg'),
    cangchai12: require('./cangchai12.jpg'),
    cangchai13: require('./cangchai13.jpg'),
    cangchai14: require('./cangchai14.jpg'),
    cangchai15: require('./cangchai15.jpg'),
    cangchai16: require('./cangchai16.jpg'),
    cangchai17: require('./cangchai17.jpg'),
    cangchai18: require('./cangchai18.jpg'),
    cangchai19: require('./cangchai19.jpg'),
    cangchai20: require('./cangchai20.jpg'),
    cangchai21: require('./cangchai21.jpg'),
    cangchai22: require('./cangchai22.jpg'),
    cangchai23: require('./cangchai23.jpg'),
    cangchai24: require('./cangchai24.jpg'),
    cangchai25: require('./cangchai25.jpg'),
    cangchai26: require('./cangchai26.jpg'),
    cangchai27: require('./cangchai27.jpg'),
    cangchai28: require('./cangchai28.jpg'),
    cangchai29: require('./cangchai29.jpg'),
    cangchai30: require('./cangchai30.jpg'),
    cangchai31: require('./cangchai31.jpg'),
    hagiang1: require('./hagiang1.jpg'),
    hagiang2: require('./hagiang2.jpg'),
    hagiang3: require('./hagiang3.jpg'),
    hagiang4: require('./hagiang4.jpg'),
    hagiang5: require('./hagiang5.jpg'),
    hagiang6: require('./hagiang6.jpg'),
    hagiang7: require('./hagiang7.jpg'),
    hagiang8: require('./hagiang8.jpg'),
    hagiang9: require('./hagiang9.jpg'),
    hagiang10: require('./hagiang10.jpg'),
    hagiang11: require('./hagiang11.jpg'),
    hagiang12: require('./hagiang12.jpg'),
    hagiang13: require('./hagiang13.jpg'),
    hagiang14: require('./hagiang14.jpg'),
    hagiang15: require('./hagiang15.jpg'),
    hagiang16: require('./hagiang16.jpg'),
    hagiang17: require('./hagiang17.jpg'),
    hagiang18: require('./hagiang18.jpg'),
    hagiang19: require('./hagiang19.jpg'),
    hagiang20: require('./hagiang20.jpg'),
    hagiang21: require('./hagiang21.jpg'),
    hagiang22: require('./hagiang22.jpg'),
    hagiang23: require('./hagiang23.jpg'),
    hagiang24: require('./hagiang24.jpg'),
    hagiang25: require('./hagiang25.jpg'),
    hagiang26: require('./hagiang26.jpg'),
    hagiang27: require('./hagiang27.jpg'),
    hagiang28: require('./hagiang28.jpg'),
    hagiang29: require('./hagiang29.jpg'),
    hagiang30: require('./hagiang30.jpg'),
    hagiang31: require('./hagiang31.jpg'),
    quangbinh1: require('./quangbinh1.jpg'),
    quangbinh2: require('./quangbinh2.jpg'),
    quangbinh3: require('./quangbinh3.jpg'),
    quangbinh4: require('./quangbinh4.jpg'),
    quangbinh5: require('./quangbinh5.jpg'),
    quangbinh6: require('./quangbinh6.jpg'),
    quangbinh7: require('./quangbinh7.jpg'),
    quangbinh8: require('./quangbinh8.jpg'),
    quangbinh9: require('./quangbinh9.jpg'),
    quangbinh10: require('./quangbinh10.jpg'),
    quangbinh11: require('./quangbinh11.jpg'),
    quangbinh12: require('./quangbinh12.jpg'),
    quangbinh13: require('./quangbinh13.jpg'),
    quangbinh14: require('./quangbinh14.jpg'),
    quangbinh15: require('./quangbinh15.jpg'),
    quangbinh16: require('./quangbinh16.jpg'),
    quangbinh17: require('./quangbinh17.jpg'),
    quangbinh18: require('./quangbinh18.jpg'),
    quangbinh19: require('./quangbinh19.jpg'),
    ninhbinh1: require('./ninhbinh1.jpg'),
    ninhbinh2: require('./ninhbinh2.jpg'),
    ninhbinh3: require('./ninhbinh3.jpg'),
    ninhbinh4: require('./ninhbinh4.jpg'),
    ninhbinh5: require('./ninhbinh5.jpg'),
    ninhbinh6: require('./ninhbinh6.jpg'),
    ninhbinh7: require('./ninhbinh7.jpg'),
    ninhbinh8: require('./ninhbinh8.jpg'),
    ninhbinh9: require('./ninhbinh9.jpg'),
    ninhbinh10: require('./ninhbinh10.jpg'),
    ninhbinh11: require('./ninhbinh11.jpg'),
    ninhbinh12: require('./ninhbinh12.jpg'),
    ninhbinh13: require('./ninhbinh13.jpg'),
    ninhbinh14: require('./ninhbinh14.jpg'),
    ninhbinh15: require('./ninhbinh15.jpg'),
    ninhbinh16: require('./ninhbinh16.jpg'),
    ninhbinh17: require('./ninhbinh17.jpg'),
    ninhbinh18: require('./ninhbinh18.jpg'),
    ninhbinh19: require('./ninhbinh19.jpg'),
    ninhbinh20: require('./ninhbinh20.jpg'),
    ninhbinh21: require('./ninhbinh21.jpg'),
    ninhbinh22: require('./ninhbinh22.jpg'),
    ninhbinh23: require('./ninhbinh23.jpg'),
    danang1: require('./danang1.jpg'),
    danang2: require('./danang2.jpg'),
    danang3: require('./danang3.jpg'),
    danang4: require('./danang4.jpg'),
    danang5: require('./danang5.jpg'),
    danang6: require('./danang6.jpg'),
    danang7: require('./danang7.jpg'),
    danang8: require('./danang8.jpg'),
    danang9: require('./danang9.jpg'),
    danang10: require('./danang10.jpg'),
    danang11: require('./danang11.jpg'),
    danang12: require('./danang12.jpg'),
    danang13: require('./danang13.jpg'),
    danang14: require('./danang14.jpg'),
    danang15: require('./danang15.jpg'),
    danang16: require('./danang16.jpg'),
    danang17: require('./danang17.jpg'),
    danang18: require('./danang18.jpg'),
    danang19: require('./danang19.jpg'),
    danang20: require('./danang20.jpg'),
    danang21: require('./danang21.jpg'),
    danang22: require('./danang22.jpg'),
    danang23: require('./danang23.jpg'),
    danang24: require('./danang24.jpg'),
    danang25: require('./danang25.jpg'),
    danang26: require('./danang26.jpg'),
    danang27: require('./danang27.jpg'),
    danang28: require('./danang28.jpg'),
    danang29: require('./danang29.jpg'),
    danang30: require('./danang30.jpg'),
    danang31: require('./danang31.jpg'),
    danang32: require('./danang32.jpg'),
    danang33: require('./danang33.jpg'),
    danang34: require('./danang34.jpg'),
    danang35: require('./danang35.jpg'),
    danang36: require('./danang36.jpg'),
    danang37: require('./danang37.jpg'),
    danang38: require('./danang38.jpg'),
    danang39: require('./danang39.jpg'),
    danang40: require('./danang40.jpg'),
    danang41: require('./danang41.jpg'),
    danang42: require('./danang42.jpg'),
    danang43: require('./danang43.jpg'),
    hoian1: require('./hoian1.jpg'),
    hoian2: require('./hoian2.jpg'),
    hoian3: require('./hoian3.jpg'),
    hoian4: require('./hoian4.jpg'),
    hoian5: require('./hoian5.jpg'),
    hoian6: require('./hoian6.jpg'),
    hoian7: require('./hoian7.jpg'),
    hoian8: require('./hoian8.jpg'),
    hoian9: require('./hoian9.jpg'),
    hoian10: require('./hoian10.jpg'),
    hoian11: require('./hoian11.jpg'),
    hoian12: require('./hoian12.jpg'),
    hoian13: require('./hoian13.jpg'),
    hoian14: require('./hoian14.jpg'),
    hoian15: require('./hoian15.jpg'),
    hoian16: require('./hoian16.jpg'),
    hoian17: require('./hoian17.jpg'),
    hoian18: require('./hoian18.jpg'),
    hoian19: require('./hoian19.jpg'),
    hoian20: require('./hoian20.jpg'),
    hoian21: require('./hoian21.jpg'),
    hoian22: require('./hoian22.jpg'),
    hoian23: require('./hoian23.jpg'),
    hoian24: require('./hoian24.jpg'),
    hoian25: require('./hoian25.jpg'),
    hoian26: require('./hoian26.jpg'),
    hoian27: require('./hoian27.jpg'),
    hoian28: require('./hoian28.jpg'),
    hoian29: require('./hoian29.jpg'),
    hoian30: require('./hoian30.jpg'),
    hoian31: require('./hoian31.jpg'),
    hue1: require('./hue1.jpg'),
    hue2: require('./hue2.jpg'),
    hue3: require('./hue3.jpg'),
    hue4: require('./hue4.jpg'),
    hue5: require('./hue5.jpg'),
    hue6: require('./hue6.jpg'),
    hue7: require('./hue7.jpg'),
    hue8: require('./hue8.jpg'),
    hue9: require('./hue9.jpg'),
    hue10: require('./hue10.jpg'),
    hue11: require('./hue11.jpg'),
    hue12: require('./hue12.jpg'),
    hue13: require('./hue13.jpg'),
    hue14: require('./hue14.jpg'),
    hue15: require('./hue15.jpg'),
    hue16: require('./hue16.jpg'),
    hue17: require('./hue17.jpg'),
    hue18: require('./hue18.jpg'),
    hue19: require('./hue19.jpg'),
    hue20: require('./hue20.jpg'),
    hue21: require('./hue21.jpg'),
    hue22: require('./hue22.jpg'),
    hue23: require('./hue23.jpg'),
    hue24: require('./hue24.jpg'),
    hue25: require('./hue25.jpg'),
    hue26: require('./hue26.jpg'),
    hue27: require('./hue27.jpg'),
    hue28: require('./hue28.jpg'),
    hue29: require('./hue29.jpg'),
    hue30: require('./hue30.jpg'),
    hue31: require('./hue31.jpg'),
    nhatrang1: require('./nhatrang1.jpg'),
    nhatrang2: require('./nhatrang2.jpg'),
    nhatrang3: require('./nhatrang3.jpg'),
    nhatrang4: require('./nhatrang4.jpg'),
    nhatrang5: require('./nhatrang5.jpg'),
    nhatrang6: require('./nhatrang6.jpg'),
    nhatrang7: require('./nhatrang7.jpg'),
    nhatrang8: require('./nhatrang8.jpg'),
    nhatrang9: require('./nhatrang9.jpg'),
    nhatrang10: require('./nhatrang10.jpg'),
    nhatrang11: require('./nhatrang11.jpg'),
    nhatrang12: require('./nhatrang12.jpg'),
    nhatrang13: require('./nhatrang13.jpg'),
    nhatrang14: require('./nhatrang14.jpg'),
    nhatrang15: require('./nhatrang15.jpg'),
    nhatrang16: require('./nhatrang16.jpg'),
    nhatrang17: require('./nhatrang17.jpg'),
    nhatrang18: require('./nhatrang18.jpg'),
    nhatrang19: require('./nhatrang19.jpg'),
    nhatrang20: require('./nhatrang20.jpg'),
    nhatrang21: require('./nhatrang21.jpg'),
    nhatrang22: require('./nhatrang22.jpg'),
    nhatrang23: require('./nhatrang23.jpg'),
    nhatrang24: require('./nhatrang24.jpg'),
    nhatrang25: require('./nhatrang25.jpg'),
    nhatrang26: require('./nhatrang26.jpg'),
    nhatrang27: require('./nhatrang27.jpg'),
    nhatrang28: require('./nhatrang28.jpg'),
    nhatrang29: require('./nhatrang29.jpg'),
    nhatrang30: require('./nhatrang30.jpg'),
    nhatrang31: require('./nhatrang31.jpg'),
    phanthiet1: require('./phanthiet1.jpg'),
    phanthiet2: require('./phanthiet2.jpg'),
    phanthiet3: require('./phanthiet3.jpg'),
    phanthiet4: require('./phanthiet4.jpg'),
    phanthiet5: require('./phanthiet5.jpg'),
    phanthiet6: require('./phanthiet6.jpg'),
    phanthiet7: require('./phanthiet7.jpg'),
    phanthiet8: require('./phanthiet8.jpg'),
    phanthiet9: require('./phanthiet9.jpg'),
    phanthiet10: require('./phanthiet10.jpg'),
    phanthiet11: require('./phanthiet11.jpg'),
    phanthiet12: require('./phanthiet12.jpg'),
    phanthiet13: require('./phanthiet13.jpg'),
    phanthiet14: require('./phanthiet14.jpg'),
    phanthiet15: require('./phanthiet15.jpg'),
    phanthiet16: require('./phanthiet16.jpg'),
    phanthiet17: require('./phanthiet17.jpg'),
    phanthiet18: require('./phanthiet18.jpg'),
    phanthiet19: require('./phanthiet19.jpg'),
    phanthiet20: require('./phanthiet20.jpg'),
    phanthiet21: require('./phanthiet21.jpg'),
    phanthiet22: require('./phanthiet22.jpg'),
    phanthiet23: require('./phanthiet23.jpg'),
    phanthiet24: require('./phanthiet24.jpg'),
    phanthiet25: require('./phanthiet25.jpg'),
    phanthiet26: require('./phanthiet26.jpg'),
    phanthiet27: require('./phanthiet27.jpg'),
    quangdong1: require('./quangdong1.jpg'),
    quangdong2: require('./quangdong2.jpg'),
    quangdong3: require('./quangdong3.jpg'),
    quangdong4: require('./quangdong4.jpg'),
    quangdong5: require('./quangdong5.jpg'),
    quangdong6: require('./quangdong6.jpg'),
    quangdong7: require('./quangdong7.jpg'),
    quangdong8: require('./quangdong8.jpg'),
    quangdong9: require('./quangdong9.jpg'),
    quangdong10: require('./quangdong10.jpg'),
    quangdong11: require('./quangdong11.jpg'),
    quangdong12: require('./quangdong12.jpg'),
    quangdong13: require('./quangdong13.jpg'),
    quangdong14: require('./quangdong14.jpg'),
    quangdong15: require('./quangdong15.jpg'),
    quangdong16: require('./quangdong16.jpg'),
    quangdong17: require('./quangdong17.jpg'),
    quangdong18: require('./quangdong18.jpg'),
    quangdong19: require('./quangdong19.jpg'),
    quangdong20: require('./quangdong20.jpg'),
    quangdong21: require('./quangdong21.jpg'),
    quangdong22: require('./quangdong22.jpg'),
    quangdong23: require('./quangdong23.jpg'),
    quangdong24: require('./quangdong24.jpg'),
    quangdong25: require('./quangdong25.jpg'),
    quangdong26: require('./quangdong26.jpg'),
    quangdong27: require('./quangdong27.jpg'),
    quangdong28: require('./quangdong28.jpg'),
    quangdong29: require('./quangdong29.jpg'),
    quangdong30: require('./quangdong30.jpg'),
    quangdong31: require('./quangdong31.jpg'),
    legiang1: require('./legiang1.jpg'),
    legiang2: require('./legiang2.jpg'),
    legiang3: require('./legiang3.jpg'),
    legiang4: require('./legiang4.jpg'),
    legiang5: require('./legiang5.jpg'),
    legiang6: require('./legiang6.jpg'),
    legiang7: require('./legiang7.jpg'),
    legiang8: require('./legiang8.jpg'),
    legiang9: require('./legiang9.jpg'),
    legiang10: require('./legiang10.jpg'),
    legiang11: require('./legiang11.jpg'),
    legiang12: require('./legiang12.jpg'),
    legiang13: require('./legiang13.jpg'),
    legiang14: require('./legiang14.jpg'),
    legiang15: require('./legiang15.jpg'),
    legiang16: require('./legiang16.jpg'),
    legiang17: require('./legiang17.jpg'),
    legiang18: require('./legiang18.jpg'),
    legiang19: require('./legiang19.jpg'),
    legiang20: require('./legiang20.jpg'),
    legiang21: require('./legiang21.jpg'),
    legiang22: require('./legiang22.jpg'),
    legiang23: require('./legiang23.jpg'),
    legiang24: require('./legiang24.jpg'),
    legiang25: require('./legiang25.jpg'),
    legiang26: require('./legiang26.jpg'),
    legiang27: require('./legiang27.jpg'),
    legiang28: require('./legiang28.jpg'),
    legiang29: require('./legiang29.jpg'),
    legiang30: require('./legiang30.jpg'),
    legiang31: require('./legiang31.jpg'),
    legiang32: require('./legiang32.jpg'),
    legiang33: require('./legiang33.jpg'),
    legiang34: require('./legiang34.jpg'),
    legiang35: require('./legiang35.jpg'),
    cotran1: require('./cotran1.jpg'),
    cotran2: require('./cotran2.jpg'),
    cotran3: require('./cotran3.jpg'),
    cotran4: require('./cotran4.jpg'),
    cotran5: require('./cotran5.jpg'),
    cotran6: require('./cotran6.jpg'),
    cotran7: require('./cotran7.jpg'),
    cotran8: require('./cotran8.jpg'),
    cotran9: require('./cotran9.jpg'),
    cotran10: require('./cotran10.jpg'),
    cotran11: require('./cotran11.jpg'),
    cotran12: require('./cotran12.jpg'),
    cotran13: require('./cotran13.jpg'),
    cotran14: require('./cotran14.jpg'),
    cotran15: require('./cotran15.jpg'),
    cotran16: require('./cotran16.jpg'),
    cotran17: require('./cotran17.jpg'),
    cotran18: require('./cotran18.jpg'),
    cotran19: require('./cotran19.jpg'),
    cotran20: require('./cotran20.jpg'),
    cotran21: require('./cotran21.jpg'),
    cotran22: require('./cotran22.jpg'),
    cotran23: require('./cotran23.jpg'),
    cotran24: require('./cotran24.jpg'),
    cotran25: require('./cotran25.jpg'),
    cotran26: require('./cotran26.jpg'),
    cotran27: require('./cotran27.jpg'),
    cotran28: require('./cotran28.jpg'),
    cotran29: require('./cotran29.jpg'),
    cotran30: require('./cotran30.jpg'),
    cotran31: require('./cotran31.jpg'),
    thanhdo1: require('./thanhdo1.jpg'),
    thanhdo2: require('./thanhdo2.jpg'),
    thanhdo3: require('./thanhdo3.jpg'),
    thanhdo4: require('./thanhdo4.jpg'),
    thanhdo5: require('./thanhdo5.jpg'),
    thanhdo6: require('./thanhdo6.jpg'),
    thanhdo7: require('./thanhdo7.jpg'),
    thanhdo8: require('./thanhdo8.jpg'),
    thanhdo9: require('./thanhdo9.jpg'),
    thanhdo10: require('./thanhdo10.jpg'),
    thanhdo11: require('./thanhdo11.jpg'),
    thanhdo12: require('./thanhdo12.jpg'),
    thanhdo13: require('./thanhdo13.jpg'),
    thanhdo14: require('./thanhdo14.jpg'),
    thanhdo15: require('./thanhdo15.jpg'),
    thanhdo16: require('./thanhdo16.jpg'),
    thanhdo17: require('./thanhdo17.jpg'),
    thanhdo18: require('./thanhdo18.jpg'),
    thanhdo19: require('./thanhdo19.jpg'),
    thanhdo20: require('./thanhdo20.jpg'),
    thanhdo21: require('./thanhdo21.jpg'),
    thanhdo22: require('./thanhdo22.jpg'),
    thanhdo23: require('./thanhdo23.jpg'),
    thanhdo24: require('./thanhdo24.jpg'),
    thanhdo25: require('./thanhdo25.jpg'),
    thanhdo26: require('./thanhdo26.jpg'),
    thanhdo27: require('./thanhdo27.jpg'),
    thanhdo28: require('./thanhdo28.jpg'),
    thanhdo29: require('./thanhdo29.jpg'),
    thanhdo30: require('./thanhdo30.jpg'),
    thanhdo31: require('./thanhdo31.jpg'),
    thanhdo32: require('./thanhdo32.jpg'),
    thanhdo33: require('./thanhdo33.jpg'),
    thanhdo34: require('./thanhdo34.jpg'),
    thanhdo35: require('./thanhdo35.jpg'),
    hainam1: require('./hainam1.jpg'),
    hainam2: require('./hainam2.jpg'),
    hainam3: require('./hainam3.jpg'),
    hainam4: require('./hainam4.jpg'),
    hainam5: require('./hainam5.jpg'),
    hainam6: require('./hainam6.jpg'),
    hainam7: require('./hainam7.jpg'),
    hainam8: require('./hainam8.jpg'),
    hainam9: require('./hainam9.jpg'),
    hainam10: require('./hainam10.jpg'),
    hainam11: require('./hainam11.jpg'),
    hainam12: require('./hainam12.jpg'),
    hainam13: require('./hainam13.jpg'),
    hainam14: require('./hainam14.jpg'),
    hainam15: require('./hainam15.jpg'),
    hainam16: require('./hainam16.jpg'),
    hainam17: require('./hainam17.jpg'),
    hainam18: require('./hainam18.jpg'),
    hainam19: require('./hainam19.jpg'),
    hainam20: require('./hainam20.jpg'),
    hainam21: require('./hainam21.jpg'),
    hainam22: require('./hainam22.jpg'),
    hainam23: require('./hainam23.jpg'),
    hainam24: require('./hainam24.jpg'),
    hainam25: require('./hainam25.jpg'),
    hainam26: require('./hainam26.jpg'),
    hainam27: require('./hainam27.jpg'),
    thailan1: require('./thailan1.jpg'),
    thailan2: require('./thailan2.jpg'),
    thailan3: require('./thailan3.jpg'),
    thailan4: require('./thailan4.jpg'),
    thailan5: require('./thailan5.jpg'),
    thailan6: require('./thailan6.jpg'),
    thailan7: require('./thailan7.jpg'),
    thailan8: require('./thailan8.jpg'),
    thailan9: require('./thailan9.jpg'),
    thailan10: require('./thailan10.jpg'),
    thailan11: require('./thailan11.jpg'),
    thailan12: require('./thailan12.jpg'),
    thailan13: require('./thailan13.jpg'),
    thailan14: require('./thailan14.jpg'),
    thailan15: require('./thailan15.jpg'),
    thailan16: require('./thailan16.jpg'),
    thailan17: require('./thailan17.jpg'),
    thailan18: require('./thailan18.jpg'),
    thailan19: require('./thailan19.jpg'),
    thailan20: require('./thailan20.jpg'),
    thailan21: require('./thailan21.jpg'),
    thailan22: require('./thailan22.jpg'),
    thailan23: require('./thailan23.jpg'),
    thailan24: require('./thailan24.jpg'),
    thailan25: require('./thailan25.jpg'),
    thailan26: require('./thailan26.jpg'),
    thailan27: require('./thailan27.jpg'),
    thailan28: require('./thailan28.jpg'),
    thailan29: require('./thailan29.jpg'),
    thailan30: require('./thailan30.jpg'),
    thailan31: require('./thailan31.jpg'),
    thailan32: require('./thailan32.jpg'),
    thailan33: require('./thailan33.jpg'),
    thailan34: require('./thailan34.jpg'),
    thailan35: require('./thailan35.jpg'),
    thailan36: require('./thailan36.jpg'),
    thailan37: require('./thailan37.jpg'),
    thailan38: require('./thailan38.jpg'),
    thailan39: require('./thailan39.jpg'),
    singapore1: require('./singapore1.jpg'),
    singapore2: require('./singapore2.jpg'),
    singapore3: require('./singapore3.jpg'),
    singapore4: require('./singapore4.jpg'),
    singapore5: require('./singapore5.jpg'),
    singapore6: require('./singapore6.jpg'),
    singapore7: require('./singapore7.jpg'),
    singapore8: require('./singapore8.jpg'),
    singapore9: require('./singapore9.jpg'),
    singapore10: require('./singapore10.jpg'),
    singapore11: require('./singapore11.jpg'),
    singapore12: require('./singapore12.jpg'),
    singapore13: require('./singapore13.jpg'),
    singapore14: require('./singapore14.jpg'),
    singapore15: require('./singapore15.jpg'),
    singapore16: require('./singapore16.jpg'),
    singapore17: require('./singapore17.jpg'),
    singapore18: require('./singapore18.jpg'),
    singapore19: require('./singapore19.jpg'),
    singapore20: require('./singapore20.jpg'),
    singapore21: require('./singapore21.jpg'),
    singapore22: require('./singapore22.jpg'),
    singapore23: require('./singapore23.jpg'),
    singapore24: require('./singapore24.jpg'),
    singapore25: require('./singapore25.jpg'),
    singapore26: require('./singapore26.jpg'),
    singapore27: require('./singapore27.jpg'),
    singapore28: require('./singapore28.jpg'),
    singapore29: require('./singapore29.jpg'),
    singapore30: require('./singapore30.jpg'),
    singapore31: require('./singapore31.jpg'),
    nhatban1: require('./nhatban1.jpg'),
    nhatban2: require('./nhatban2.jpg'),
    nhatban3: require('./nhatban3.jpg'),
    nhatban4: require('./nhatban4.jpg'),
    nhatban5: require('./nhatban5.jpg'),
    nhatban6: require('./nhatban6.jpg'),
    nhatban7: require('./nhatban7.jpg'),
    nhatban8: require('./nhatban8.jpg'),
    nhatban9: require('./nhatban9.jpg'),
    nhatban10: require('./nhatban10.jpg'),
    nhatban11: require('./nhatban11.jpg'),
    nhatban12: require('./nhatban12.jpg'),
    nhatban13: require('./nhatban13.jpg'),
    nhatban14: require('./nhatban14.jpg'),
    nhatban15: require('./nhatban15.jpg'),
    nhatban16: require('./nhatban16.jpg'),
    nhatban17: require('./nhatban17.jpg'),
    nhatban18: require('./nhatban18.jpg'),
    nhatban19: require('./nhatban19.jpg'),
    nhatban20: require('./nhatban20.jpg'),
    nhatban21: require('./nhatban21.jpg'),
    nhatban22: require('./nhatban22.jpg'),
    nhatban23: require('./nhatban23.jpg'),
    nhatban24: require('./nhatban24.jpg'),
    nhatban25: require('./nhatban25.jpg'),
    nhatban26: require('./nhatban26.jpg'),
    nhatban27: require('./nhatban27.jpg'),
    nhatban28: require('./nhatban28.jpg'),
    nhatban29: require('./nhatban29.jpg'),
    nhatban30: require('./nhatban30.jpg'),
    nhatban31: require('./nhatban31.jpg'),
    nhatban32: require('./nhatban32.jpg'),
    nhatban33: require('./nhatban33.jpg'),
    nhatban34: require('./nhatban34.jpg'),
    nhatban35: require('./nhatban35.jpg'),
    nhatban36: require('./nhatban36.jpg'),
    nhatban37: require('./nhatban37.jpg'),
    nhatban38: require('./nhatban38.jpg'),
    nhatban39: require('./nhatban39.jpg'),
    nhatban40: require('./nhatban40.jpg'),
    nhatban41: require('./nhatban41.jpg'),
    nhatban42: require('./nhatban42.jpg'),
    nhatban43: require('./nhatban43.jpg'),
    nhatban44: require('./nhatban44.jpg'),
    nhatban45: require('./nhatban45.jpg'),
    nhatban46: require('./nhatban46.jpg'),
    nhatban47: require('./nhatban47.jpg'),
    nhatban48: require('./nhatban48.jpg'),
    nhatban49: require('./nhatban49.jpg'),
    nhatban50: require('./nhatban50.jpg'),
    nhatban51: require('./nhatban51.jpg'),
    nhatban52: require('./nhatban52.jpg'),
    nhatban53: require('./nhatban53.jpg'),
    nhatban54: require('./nhatban54.jpg'),
    nhatban55: require('./nhatban55.jpg'),
    hanquoc1: require('./hanquoc1.jpg'),
    hanquoc2: require('./hanquoc2.jpg'),
    hanquoc3: require('./hanquoc3.jpg'),
    hanquoc4: require('./hanquoc4.jpg'),
    hanquoc5: require('./hanquoc5.jpg'),
    hanquoc6: require('./hanquoc6.jpg'),
    hanquoc7: require('./hanquoc7.jpg'),
    hanquoc8: require('./hanquoc8.jpg'),
    hanquoc9: require('./hanquoc9.jpg'),
    hanquoc10: require('./hanquoc10.jpg'),
    hanquoc11: require('./hanquoc11.jpg'),
    hanquoc12: require('./hanquoc12.jpg'),
    hanquoc13: require('./hanquoc13.jpg'),
    hanquoc14: require('./hanquoc14.jpg'),
    hanquoc15: require('./hanquoc15.jpg'),
    hanquoc16: require('./hanquoc16.jpg'),
    hanquoc17: require('./hanquoc17.jpg'),
    hanquoc18: require('./hanquoc18.jpg'),
    hanquoc19: require('./hanquoc19.jpg'),
    hanquoc20: require('./hanquoc20.jpg'),
    hanquoc21: require('./hanquoc21.jpg'),
    hanquoc22: require('./hanquoc22.jpg'),
    hanquoc23: require('./hanquoc23.jpg'),
    hanquoc24: require('./hanquoc24.jpg'),
    hanquoc25: require('./hanquoc25.jpg'),
    hanquoc26: require('./hanquoc26.jpg'),
    hanquoc27: require('./hanquoc27.jpg'),
    hanquoc28: require('./hanquoc28.jpg'),
    hanquoc29: require('./hanquoc29.jpg'),
    hanquoc30: require('./hanquoc30.jpg'),
    hanquoc31: require('./hanquoc31.jpg'),
    hanquoc32: require('./hanquoc32.jpg'),
    hanquoc33: require('./hanquoc33.jpg'),
    hanquoc34: require('./hanquoc34.jpg'),
    hanquoc35: require('./hanquoc35.jpg'),
    hanquoc36: require('./hanquoc36.jpg'),
    hanquoc37: require('./hanquoc37.jpg'),
    hanquoc38: require('./hanquoc38.jpg'),
    hanquoc39: require('./hanquoc39.jpg'),
    hanquoc40: require('./hanquoc40.jpg'),
    hanquoc41: require('./hanquoc41.jpg'),
    hanquoc42: require('./hanquoc42.jpg'),
    hanquoc43: require('./hanquoc43.jpg'),
    hanquoc44: require('./hanquoc44.jpg'),
    hanquoc45: require('./hanquoc45.jpg'),
    hanquoc46: require('./hanquoc46.jpg'),
    hanquoc47: require('./hanquoc47.jpg'),
    hanquoc48: require('./hanquoc48.jpg'),
    hanquoc49: require('./hanquoc49.jpg'),
    hanquoc50: require('./hanquoc50.jpg'),
    hanquoc51: require('./hanquoc51.jpg'),
    hanquoc52: require('./hanquoc52.jpg'),
    hanquoc53: require('./hanquoc53.jpg'),
    hanquoc54: require('./hanquoc54.jpg'),
    hanquoc55: require('./hanquoc55.jpg'),
    bia_hagiang: require('./bia_hagiang.jpg'),
    bia_nhatrang: require('./bia_nhatrang.jpg'),
    bia_hoian: require('./bia_hoian.jpg'),
    bia_danang: require('./bia_danang.jpg'),

    }

export default TourInlandImages