import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useRef } from "react";
import "./home.css";
import HeaderImages from "src/assets/Header";
import TourInland from "./Components/TourInland";
import TourInternational from "./Components/TourInternational";
import Travel_Guide from "./Components/TravelGuide";
import Typical from "./Components/Typical";
import Travel from "./Components/Travel";
import AOS from "aos";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";

function Home() {
  const myRef = useRef<any>(null)
const {state} = useLocation()
const id = state?.id || ""

  useEffect(() => {
    AOS.init({ duration: 1000 });
    switch (id) {
      case 7:
        window.scrollTo(0, 900);
        break;
      case 8:
        window.scrollTo(0, 2200);
        break;
      case 9:
        window.scrollTo(0, 3220);
        break;
      default:
        break;
    }
    
  }, [id]);

  return (
    <div>
      <Travel aos="fade-up" />
      <TourInland aos="fade-up" />
      <TourInternational aos="fade-up" />
      <Travel_Guide aos="fade-up" />
      <Typical aos="fade-up" />
    </div>
  );
}

export default Home;
