import React from "react";
import ContactImages from "src/assets/Contact";
import FooterImages from "src/assets/Footer";
import HeadTab from "src/components/HeadTab/HeadTab";

const Contact = () => {
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">Thông tin liên hệ</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div className="row col-md-12 pt-5">
          <div className="col-md-3">
            <div>
              <div className="text-success fw-semibold">Hotline Du Học:</div>
              <div className="text-success">096 163 1818</div>
              <div className="text-success fw-semibold">Hotline Du Lịch:</div>
              <div className="text-success">096 135 1818</div>
            </div>
          </div>
          <div className="d-block d-sm-none my-2" />
          <div className="col-md-3">
            <div>
              <div className="text-success fw-bolder">Email:</div>
              <div className="text-success fw-bolder">
                dabosagroup@gmail.com
              </div>
              <div className="text-success fw-bolder">Website:</div>
              <div className="text-success fw-bolder">dabosagroup.com</div>
            </div>
          </div>
          <div className="d-block d-sm-none mt-2" />
          <div className="col-md-6">
            <div className="text-success fw-semibold fs-5">
              Liên kết mạng xã hội:
            </div>

            <div>
              <div className="d-flex align-items-center gap-3 pt-4">
                <div className="gap-2 d-flex align-items-center flex-column">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(
                          `https://www.facebook.com/DabosaGroup.Official`,
                          "_blank"
                        )
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.facebook}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(
                          `https://www.facebook.com/DabosaTravel/`,
                          "_blank"
                        )
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.facebook}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA TRAVEL
                    </div>
                  </button>
                </div>
                <div className="gap-2 d-flex align-items-center flex-column">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.youtube.com/@GROUPDABOSA`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.youtube}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.tiktok.com/@dabosa.group`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `https://www.tiktok.com/@dabosa.group`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row col-md-12 pt-5 align-items-md-center">
          <div className="col-md-5">
            <div className="fw-bolder fs-3 text-success">Trụ sở chính:</div>
            <div className="fw-semibold fs-4 text-success">
              CÔNG TY CỔ PHẦN TẬP ĐOÀN DABOSA
            </div>
            <div className="text-success pt-1">
              Biệt thự số 2 - OBT2 Khu đô thị <br /> Bắc Linh Đàm, Đại Kim,
              Hoàng Mai, Hà Nội
            </div>
          </div>
          <div className="d-block d-sm-none my-2" />
          <div className="col-md-7">
            <img src={ContactImages.contact_map} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
