const TourInternationalImages = {
    bangkok: require('./bangkok.png'),
    nhat: require('./nhat.png'),
    seoul: require('./seoul.png'),
    sing: require('./sing.png'),
    cotran: require('./cotran.png'),
    legiang: require('./legiang.png'),
    hainam: require('./hainam.png'),
    thanhdo: require('./thanhdo.png'),
    quangdong: require('./quangdong.png'),
}

export default TourInternationalImages