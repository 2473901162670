import ProgramImages from "src/assets/Home/Progams";
import TourInlandImages from "src/assets/Home/TourInland";
import TourInternationalImages from "src/assets/Home/TourInternational";

export const Study_Nhat = `
 <div class='d-flex flex-column align-items-center'>
<img src=${ProgramImages.nhatban1} class='w-100' />
</div>
<div class='fw-bolder fs-1  text-success py-3'>
VÌ SAO NÊN DU HỌC NHẬT BẢN?
</div>

<div class=' fs-5 ps-5'  >
• Chất lượng giáo dục vô cùng tốt, giá trị bằng cấp quốc tế công nhận
</div>
<div class=' fs-5 ps-5' >
• Nền kinh tế TOP đầu thế giới.
</div>
<div class=' fs-5 ps-5' >
• Chế độ phúc lợi tốt, cơ hội vĩnh trú và định cư
</div>
<div class=' fs-5 ps-5' >
• Cơ hội việc làm đa dạng, thu nhật cao tại Nhật Bản cũng như về Việt Nam
</div>
<div class=' fs-5 ps-5' >
• Chi phí thấp, đa dạng chương trình, tỉ lệ đỗ Visa cao, phù hợp mọi hoàn cảnh
</div>
<div class='fw-bolder pt-5 fs-1  text-success pb-3 fs-5'>
CÁC CHƯƠNG TRÌNH DU HỌC NHẬT BẢN
</div>
<div class='fw-bolder text-success fs-4'>
DU HỌC TỰ TÚC
</div>
<div class=' fs-5 ps-5'>
Du học sinh cần đóng tất cả các khoản phí học tập, sinh hoạt, ký túc xá ở Việt Nam cũng như Nhật Bản, thu nhập 25-35 triệu/tháng.
</div>
<div class='py-2 ps-3 fw-bolder fs-5 '>
1. Tổng quan:
</div>
<div class='ps-5 fs-5 '>
Dành cho học sinh Việt Nam muốn tới Nhật Bản học tập và làm việc, sinh sống; Du học sinh sẽ tự chi trả các khoản phí sinh hoạt, học phí hoặc các khoản chi phí cá nhân khác; Tự do lựa chọn ngành nghề, việc làm thêm; Đi làm thêm thu nhập 25 - 35 triệu/tháng trở lên; Sau khi học chuyên ngành, có thể ở lại Nhật sinh sống và làm việc vô thời hạn.
</div>
<div class='py-2 ps-3 fw-bolder fs-5 '>
2. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5'>
Tốt nghiệp cấp 3 (THPT, GDTX), Cao đẳng, Đại học tại Việt Nam; Tuổi từ 18 đến 35 tuổi; Trình độ N4 hoặc N5, chưa có sẽ được đào tạo đến khi xuất cảnh; Không có tiền án, tiền sự và không có người thân (bố, mẹ, anh chị em ruột) đã hoặc đang bất hợp pháp tại Nhật; Nếu từng đi Nhật về thì phải không bất hợp pháp và còn giữ hồ sơ gốc;
</div>
<div class='pt-3 fw-bolder text-success fs-4 '>
DU HỌC BỔNG BÁO
</div>
<div class=' fs-5 ps-5'>
Được toà báo đài thọ học phí tại trường Nhật ngữ, hỗ trợ phí sinh hoạt và đi làm phát báo, thu nhập 20-25 triệu/tháng.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
1. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5'>
Dành cho các bạn muốn đến Nhật Bản với chi phí thấp, gia đình không có điều kiện; Điều kiện: Nam, nữ 18 - 25 tuổi; tốt nghiệp THPT, GDTX, Trung cấp/Cao đẳng/Đại học tại Việt Nam với điểm trung bình môn 7.0 trở lên; có bằng lái xe máy tại Việt Nam. Tiếng Nhật N5 hoặc N4 trước khi xuất cảnh; không có tiền án, tiền sự; không mắc các bệnh truyền nhiễm (HIV/AID, Viêm gan B, C,...); không có bố, mẹ, anh, chị, em ruột (trên sổ hộ khẩu) đã hoặc đang bất hợp pháp tại Nhật.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
2. Quyền lợi:
</div>
<div class='ps-5 fs-5 '>
Được tòa soạn báo hỗ trợ học phí lên đến 100% trong thời gian học tại trường Nhật ngữ, không cần hoàn lại. Công việc làm thêm là đi phát báo cho tòa soạn đó. Hỗ trợ tiền thuê nhà và sinh hoạt phí hằng tháng; Có lương làm thêm hằng tháng từ công việc đi phát báo, trung bình khoảng 20-25 triệu/tháng; Hỗ trợ tiền vé máy bay chiều đi sang Nhật lần đầu; Được tham gia bảo hiểm tai nạn lao động, bảo hiểm y tế; Hỗ trợ phương tiện di chuyển khi làm việc.
</div>
<div class='fw-bolder fw-bolder text-success fs-4 pt-3'>
DU HỌC ĐIỀU DƯỠNG
</div>
<div class=' fs-5 ps-5' >
Được hỗ trợ cho vay trả sau với lãi suất học phí trường Nhật ngữ và học chuyên ngành; làm Điều dưỡng, thu nhập 20-25 triệu/tháng.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
1. Tổng quan:
</div>
<div class='ps-5 fs-5 '>
Được tài trợ bởi các Viện dưỡng lão của Nhật, đổi lại là làm việc tại đó sau tốt nghiệp; Tự chi trả học phí 01 năm đầu tại trường Nhật ngữ hoặc sẽ được tài trợ (tuỳ chương trình) để đạt trình độ N3 và cho 02 năm học ngành Điều dưỡng tại Senmon.
</div>
<div class='py-3  ps-3 fw-bolder fs-5 '>
2. Điều kiện tuyển sinh:
</div>
<div class='ps-5 fs-5 '>
Tốt nghiệp THPT, GDTX, Cao đẳng, Đại học với điểm trung bình môn 7.0 trở lên; Tốt nghiệp không quá 5 năm; Tiếng Nhật N5 hoặc N4; Không có tiền án, tiền sự; Không mắc các bệnh truyền nhiễm (HIV/AID, Viêm gan B, C,...), sức khỏe tốt; Yêu thích chăm sóc người già;
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
3. Cơ hội nghề nghiệp
</div>
<div class='ps-5 fs-5 '>
Chất lượng đào tạo y tế của Nhật thuộc TOP đầu thế giới; Làm thêm khi học tiếng và chuyên ngành với thu nhập 20-25 triệu/tháng; thu nhập sau tốt nghiệp 50-60 triệu/tháng trở lên; Ở lại làm việc vô thời hạn;
</div>
<div class='fw-bolder py-3 text-success fs-4'>
DU HỌC CHUYỂN ĐỔI ĐI LÀM TOKUTEI GINOU (KỸ NĂNG ĐẶC ĐỊNH)
</div>
<div class=' ps-3 fw-bolder pb-3 fs-5 '>
1. Điều kiện tham gia:
</div>
<div class=' ps-5 fs-5 '>
Tuổi từ 18 - 35 tuổi; Có bằng THPT, GDTX trở lên; Sức khỏe tốt, không bệnh truyền nhiễm; Không bị cấm xuất cảnh; Thực tập sinh về nước phải còn đủ giấy tờ gốc và không bất hợp pháp;
</div>
<div class=' ps-3 py-3 fw-bolder fs-5 '>
2. Lợi ích:
</div>
<div class=' ps-5 fs-5 '>
- Thu nhập 35-45 triệu 01 tháng trở lên
</div>
<div class=' ps-5 fs-5 '>
- Không phụ thuộc Công ty môi giới
</div>
<div class=' ps-5 fs-5 '>
- Được chuyển Công ty khi chưa hết hợp đồng
</div>
<div class=' ps-5 fs-5 '>
- Dễ dàng có được việc làm tại Nhật
</div>
<div class=' ps-5 fs-5 '>
- Cơ hội cấp Visa vĩnh trú tại Nhật Bản
</div>
<div class='py-3 ps-3 fw-bolder fs-5'>
3. Hình thức 1
</div>
<div class=' ps-5 fs-5 '>
Bước 1: Học tại DABOSA đến N4 kết hợp học chuyên môn tay nghề;
</div>
<div class=' ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật và sang Philippin hoặc Campuchia để thi chứng chỉ Kỹ năng Đặc định;
</div>
<div class=' ps-5 fs-5 '>
Bước 3: Về Việt Nam thi đơn hàng, xử lý giấy tờ và xuất cảnh sang Nhật.
</div>
<div class=' ps-5 fs-5  '>
Bước 4: Đi làm tại Nhật theo đơn hàng đã thi đỗ tại Việt Nam. Tổng thời gian tính đến xuất cảnh sang Nhật: 8 tháng - 1 năm.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
4. Hình thức 2
</div>
<div class=' ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N5 kết hợp phỏng vấn trường Nhật ngữ, xử lý giấy tờ;
</div>
<div class=' ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật TOP-J hoặc NAT-TEST tại Việt Nam;
</div>
<div class=' ps-5 fs-5 '>
Bước 3: Có COE (Tư cách Lưu trú), xin Visa và xuất cảnh sang Nhật kỳ tháng: 1,4,7,10;
</div>
<div class=' ps-5 fs-5 '>
Bước 4: Học trường Nhật ngữ 01 năm để đạt N4 hoặc N3, thi chứng chỉ tiếng Nhật JLPT, thi chứng chỉ Tokutei. Đi làm thêm 25-35 triệu/tháng.
</div>
<div class=' ps-5 fs-5 '>
Bước 5: Chuyển Visa đi làm. Tổng thời gian tính đến xuất cảnh: 4-6 tháng.
</div>
<div class=' ps-3 fs-5 py-3 fw-bolder'>
5. 14 NGÀNH TOKUTEI GINOU
</div>
<div class=' ps-5 fs-5 '>
Hộ lý, Vệ sinh tòa nhà, Vật liệu, Xây dựng, Chế tạo máy, Điện - Điện tử, Đóng tàu, Bảo dưỡng ô tô, Hàng không, Khách sạn, Nông nghiệp, Ngư nghiệp, Chế biến thực phẩm, Dịch vụ ăn uống.
</div>
<div class='py-3  fw-bolder text-success fs-4'>
DU HỌC CHUYỂN ĐỔI ĐI LÀM VISA SHYU (VISA KỸ SƯ)
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
1. Điều kiện tham gia:
</div>
<div class=' ps-5 pb-3 fs-5 '>
Tuổi từ 18 - 35 tuổi; Có bằng Cao đẳng, Đại học tại Việt Nam; Sức khỏe tốt, không bệnh truyền nhiễm; Không bị cấm xuất cảnh; Không có người thân bất hợp pháp tại Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Lợi ích:
</div>
<div class='pb-3 ps-5 fs-5 '>
Thu nhập cao từ 40-50 triệu trở lên/tháng, chưa tính trợ cấp, chế độ như người bản địa;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bảo lãnh người thân
Bảo lãnh vợ/chồng/con sang sinh sống tại Nhật sau khi làm được 1-2 năm;
</div>
<div class='pb-3 ps-5 fs-5 '>
Được chuyển đổi công ty
Được chuyển đổi công ty cùng ngành làm việc sau 6 tháng - 1 năm;
</div>
<div class='pb-3 ps-5 fs-5 '>
Không phụ thuộc công ty môi giới
Không phụ thuộc hay mất phí quản lý cho công ty môi giới tại Nhật
</div>
<div class='pb-3 ps-5 fs-5 '>
Cơ hội vĩnh trú và định cư
Làm việc vô thời hạn, tiến tới vĩnh trú và định cư lâu dài, tự do đi lại Việt- Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
3. Hình thức 1
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N4 hoặc N3 trở lên;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật và thi đơn hàng;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 3: Đỗ đơn hàng, xử lý giấy tờ xin COE và Visa;
</div>
<div class='pb-3 ps-5 fs-5 '>
Bước 4: Đi làm tại Nhật theo đơn hàng đã thi đỗ tại Việt Nam. Chỉ nhận các bằng Cao đẳng, Đại học liên quan đến ngành Kỹ thuật như: Điện, điện tử, xây dựng, CNC, công nghệ thông tin, ô tô, nông nghiệp, điều dưỡng, chế biến thực phẩm, chế tạo máy. Không có nhiều sự lựa chọn ngành nghề hay khu vực muốn đến của Nhật Bản; Tổng thời gian tính đến xuất cảnh sang Nhật: 6 tháng - 1 năm.
</div>
<div  class='pb-3 ps-3 fw-bolder fs-5 '>
4. Hình thức 2
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 1: Học tiếng tại DABOSA đến N5 kết hợp phỏng vấn trường Nhật ngữ, xử lý giấy tờ;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 2: Thi chứng chỉ tiếng Nhật TOP-J hoặc NAT-TEST tại Việt Nam;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 3: Có COE, xin Visa và xuất cảnh sang Nhật kỳ tháng: 1,4,7,10 như du học sinh;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 4: Học tại trường Nhật ngữ 01 năm để đạt N4 hoặc N3, thi chứng chỉ tiếng Nhật JLPT kết hợp đi làm thêm thu nhập 25-35 triệu/tháng;
</div>
<div  class='pb-3 ps-5 fs-5 '>
Bước 5: Chuyển Visa Shyu đi làm; Không giới hạn chuyên ngành của bằng Cao đẳng, Đại học; Được tự do lựa chọn ngành nghề, doanh nghiệp, khu vực; Tổng thời gian tính đến xuất cảnh: 4-6 tháng.
</div>
<div class='py-3 fw-bolder text-success fs-1'>
LỘ TRÌNH DU HỌC NHẬT BẢN
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Các kỳ bay
</div>
<div class='pb-3 ps-5 fs-5 '>
- Có 04 kỳ bay hằng năm vào tháng 01, 04, 07, 10. Có thể bay vào đúng tháng đó hoặc tháng trước đó.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
Kỳ tháng 01: Visa 1 năm 3 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 6 - tháng 8 năm trước; nhận kết quả COE (tư cách lưu trú) vào tháng 11 năm trước;
</div>
<div div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 02: Visa 2 năm
</div>
<div div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 9 - tháng 11 năm trước; nhận kết quả COE (tư cách lưu trú) vào tháng 2 cùng năm;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 07: Visa 1 năm 9 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 12 năm trước - tháng 2 cùng năm; nhận kết quả COE (tư cách lưu trú) vào tháng 5 cùng năm;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Kỳ tháng 10: Visa 1 năm 6 tháng
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tuyển từ tháng 3 - đầu tháng 5 cùng năm; nhận kết quả COE (tư cách lưu trú) vào tháng 8 cùng năm;
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
SINH HOẠT PHÍ HÀNG THÁNG (THAM KHẢO)
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Lưu ý: Chi phí biến đổi hơn/kém tuỳ theo tỉ giá Yên-VND, khu vực sinh sống và nhu cầu cá nhân cùng học phí từng ngành, trường.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 ' >
Tiền thuê ký túc:
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tokyo, Osaka, Kyoto khoảng 30.000-35.000 Yên (khoảng 5-7 triệu VND). Nếu ở vùng khác sẽ thấp hơn 20-30%. Ở ghép hoặc ngoại thành sẽ thấp hơn.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền ăn:
</div>
<div class='pb-3 ps-5 fs-5 '>
- Nếu tự nấu ăn, trung bình khoảng 30.000 Yên (khoảng 5 triệu VND). Nếu ăn ngoài, trung bình khoảng 40.000 Yên (khoảng 7 triệu VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền điện, nước, gas:
</div>
<div class='pb-3 ps-5 fs-5'>
- Điện và nước tính theo số điện và số khối nước. Trung bình 2.500-3.500 Yên (400-600 nghìn VND). Gas dùng cho nấu ăn và nước nóng. Trung bình 1.500-2.500 Yên (khoảng 250-450 nghìn VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền điện thoại & Internet
</div>
<div class='pb-3 ps-5 fs-5 '>
- Tổng chi phí cho cả tiền điện thoại và tiền mạng hàng tháng thường sẽ khoảng 4.500 – 5.000 yên (khoảng 1 triệu VND).
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền đi lại:
</div>
<div class='pb-3 ps-5 fs-5'>
- Chi phí đi tàu điện ngầm dành cho du học sinh hàng tháng là khoảng 10.000 – 15.000 Yên (tức là khoảng 1.6– 2.5 triệu VND)
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Tiền học phí:
</div>
<div class='pb-3 ps-5 fs-5'>
- Học phí 01 năm trường Nhật ngữ khoảng 650.000-750.000 Yên (110-130 triệu VND). Học phí Senmon 01 năm: 900.000 - 1.300.000 Yên (155-220 triệu VND)
</div>
<div class='py-3 fw-bolder text-success fs-1'>
QUY TRÌNH TẠI DABOSA GROUP
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
1. Nhập học tại Dabosa
</div>
<div class='pb-3 ps-5 fs-5'>
Lắng nghe tư vấn chương trình phù hợp; Ký hợp đồng, nộp tiền đợt 1 và nhập học;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
2. Học tập tại Dabosa
</div>
<div class='pb-3 ps-5 fs-5'>
Học tiếng Nhật 04 kỹ năng đến N5 hoặc N4; Học kỹ năng mềm, văn hoá Nhật Bản;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
3. Phỏng vấn trường Nhật ngữ
</div>
<div class='pb-3 ps-5 fs-5 '>
Luyện tập phỏng vấn cùng giáo viên; Kết nối, phỏng vấn trường Nhật ngữ; Xử lý hồ sơ để xin COE;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
4. Nhận tư cách lưu trú (COE)
</div>
<div class='pb-3 ps-5 fs-5 '>
Nhận COE, nộp học phí 01 năm đầu và các khoản phí còn lại theo; nộp hồ sơ xin Visa;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
5. Nhập học trường Nhật ngữ
</div>
<div class='pb-3 ps-5 fs-5 '>
Học trường Nhật ngữ 1-2 năm đạt N3 hoặc N2; Đi làm thêm với thu nhập 25 - 35 triệu/tháng;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
6. Học Senmon, Đại học hoặc chuyển Visa đi làm
</div>
<div class='pb-3 ps-5 fs-5 '>
Nếu học Senmon, cần có N3, học 02 năm; Nếu học Đại học, cần có N2, thi EJU đầu vào, học 3-4 năm; Hoặc chuyển Visa đi làm Tokutei hoặc Shyu;
</div>
<div class='py-3 fw-bolder text-success fs-1'>
VÌ SAO NÊN CHỌN DABOSA?
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
1. Tại Việt Nam
</div>
<div class='pb-3 ps-5 fs-5 '>
Trang bị ngoại ngữ, kỹ năng và kiến thức văn hóa, pháp luật Nhật Bản; Giao lưu cùng Senpai (tiền bối) có thêm kinh nghiệm khi du học; Hoạt động ngoại khóa, teambuilding; Cơ sở vật chất tiện nghi, hiện đại; Có khu giải trí nghỉ ngơi, đọc sách; Hướng dẫn tận tình, đưa đón sân bay; Được lựa chọn tỉnh muốn đến của Nhật; Chương trình đa dạng, chi phí ưu đãi.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Tại Nhật Bản
</div>
<div class='pb-3 ps-5 fs-5 '>
Đồng hành sát sao; Giới thiệu việc làm, nơi ở, hướng dẫn các giấy tờ cá nhân; Được làm thêm 28 giờ/tuần trong thời gian đi học; 40 giờ/tuần vào kỳ nghỉ, thu nhập 25 - 35 triệu/tháng; Cầu nối giữa học viên và gia đình. Hỗ trợ gia đình xử lý Visa thăm thân;
</div>
<div class='pb-6 fw-bolder text-success fs-1'>
TỔNG CHI PHÍ CÁC CHƯƠNG TRÌNH
</div>
<div class='pb-3 fw-bolder fs-5'>
Lưu ý: Chi phí thực tế có thể hơn hoặc kém do phụ thuộc vào biến động của tỉ giá ngoại tệ, giá vé máy bay, nhu cầu thực tế của khách hàng về chương trình.
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
1. Du học tự túc
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 160 triệu - 200 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay, 6 tháng - 01 năm học phí đầu tiên tại trường Nhật ngữ.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
2. Học bổng báo, điều dưỡng
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 70 triệu - 80 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
3. Tokutei Ginou (kỹ sư)
</div>
<div class='pb-3 ps-5 fs-5 '>
Khoảng 120 triệu - 180 triệu VND: Tiền học tiếng, kỹ năng mềm, thi chứng chỉ tiếng, tài liệu học tập, đồng phục (3 áo), ký túc xá DABOSA, xử lý hồ sơ, đưa đón sân bay, vé máy bay, 06 tháng - 01 năm học phí đầu tiên tại trường Nhật ngữ. Khoảng 40 triệu - 120 triệu VND nếu chỉ cần xử lý giấy tờ và kết nối đơn hàng phù hợp và vé máy bay 01 chiều đi. Chi phí biến động theo chi phí đơn hàng và giá vé máy bay.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
4. Tiến độ đóng tiền du học
</div>
<div class='pb-3 ps-5 fs-5 '>
• Đợt 01: Đóng 28 triệu VND, chưa tính tiền Ký túc xá tại DABOSA. Nếu ở KTX, đóng 1,3 triệu/tháng, đã bao gồm điện, nước.
</div>
<div class='pb-1 ps-5 fs-5 '>
• Đợt 02: Đóng số tiền còn lại.
</div>
<div class='pb-3 ps-5 fs-5 '>
Lưu ý: Tiền học phí 01 năm đầu học viên có thể đóng tại ngân hàng.
</div>
<div class='py-3 fw-bolder fs-1 text-success'>
MỘT SỐ CÔNG VIỆC LÀM THÊM TẠI NHẬT BẢN:
</div>
<div class=' pb-3 ps-5 fs-5 '>
Làm thêm 28 giờ/tuần trong khi đi học và 40 giờ/tuần trong kỳ nghỉ với thu nhập 25-35 triệu/tháng trở lên; DABOSA đồng hành và giới thiệu việc làm thêm tại Nhật;
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Nhà hàng
</div>
<div class='pb-3  ps-5 fs-5 '>
- Công việc phụ bếp, bưng bê hoặc rửa bát tại các Nhà hàng của Nhật
</div>
<div class='pb-3 ps-3 fw-bolder fs-5'>
Nhân viên Cửa hàng Tiện lợi
</div>
<div class='pb-3 ps-5 fs-5 '>
- Làm thu ngân, lau dọn, sắp xếp cửa hàng
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Siêu thị
</div>
<div class='pb-3 ps-5 fs-5 '>
- Làm thu ngân, phục vụ hoặc chế biến thực phẩm
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Phát báo
</div>
<div class='pb-3 ps-5 fs-5 '>
- Đi phát báo bằng xe máy vào buổi sáng, 2 giờ - 7 giờ sáng
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Nhân viên Tạp vụ
</div>
<div class='pb-3 ps-5 fs-5 '>
- Lau dọn vệ sinh tòa nhà, khách sạn hoặc cơ sở làm việc.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Biên phiên dịch
</div>
<div class='pb-3 ps-5 fs-5 '>
- Dịch thuật, dạy tiếng Việt cho người Nhật hoặc làm việc tại Trung tâm Du lịch.
</div>
<div class='pb-3 ps-3 fw-bolder fs-5 '>
Một số kỳ nghỉ lễ của Nhật Bản
</div>
<div class='pb-3 ps-5 fs-5 '>
- DHS có thể làm đến 40 giờ/tuần thay vì 28 giờ/tuần như khi đi học, nâng cao thu nhập; DHS có thể đi thăm quan, trải nghiệm, khám phá cảnh đẹp, lễ hội và văn hóa Nhật Bản; DHS có thể về thăm nhà tại Việt Nam trong kỳ nghỉ lễ này.
</div>
<div class='pb-3 fw-bolder ps-3 fs-5 '>
Kỳ nghỉ xuân
</div>
<div class='ps-5 fs-5 '>
- Kỳ nghỉ xuân chính thức được bắt đầu vào cuối tháng 2 và kéo dài đến đầu tháng 3. Tuy nhiên, thời gian tối đa sinh viên được nghỉ chỉ có 2 – 3 tuần thường là 25/2 – 10/3.
</div>
<div class='py-3 fw-bolder ps-3 fs-5 '>
Kỳ nghỉ hè
</div>
<div class='ps-5 fs-5 '>
Du học sinh sẽ được nhà trường cho nghỉ hè khoảng 7 tuần. Thời gian có thể bắt đầu từ giữa tháng 7 và kéo dài đến cuối tháng 8.
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
Kỳ nghỉ đông
</div>
<div class='ps-5 fs-5 '>
- Tết Dương lịch là Tết chính Nghỉ Quốc Khánh Nhật Bản 11/2 Ngày Xuân phân 20 hoặc 21/3 Lễ Tạ ơn Người lao động 23/11 Ngày sinh Nhật Hoàng 23/12 Tuần lễ Obon 13 -15/11 AL
</div>
<div class='py-3 ps-3 fw-bolder fs-5 '>
Kỳ nghỉ khác
</div>
<div class='ps-5 fs-5 '>
- Kỳ nghỉ đông từ cuối tháng 12 đến khoảng giữa tháng 1. Đa số các trường Nhật Bản thường cho sinh viên nghỉ đông từ 28/12 đến 14/1.
</div>
`;
export const Study_Han = `
<div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.hanquoc1} class='w-100' />
   </div>
<div class='fs-1 text-success fw-bolder py-3'>
TOP 5 LÝ DO CHỌN HÀN QUỐC</div>
<div class='ps-5 fs-5'>
- Là một trong những nền giáo dục TOP đầu thế giới</div>
 
<div class='ps-5 fs-5'>
- Đất nước phát triển, môi trường chất lượng cao</div>
 
<div class='ps-5 fs-5'>
- Học phí và chi phí sinh hoạt hợp lý, nhiều học bổng</div>
 
<div class='ps-5 fs-5'>
- Điều kiện làm việc tốt, nhiều công việc cho sinh viên</div>
 
<div class='ps-5 fs-5'>
- Đa dạng văn hoá, khí hậu ôn hoà với 4 mùa rõ rệt</div>
 
<div class='fs-1 text-success fw-bolder py-3 ' >
CÁC CHƯƠNG TRÌNH HỌC TẠI HÀN QUỐC </div>
 
<div class='ps-5 fs-5'>
- VISA D4-1: Visa học tiếng trong tối đa 02 năm để đạt TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-1: Visa học Cao đẳng trong 2-3 năm, cần có TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-2: Visa học Đại học trong 4-5 năm, cần có TOPIK 3 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA D2-3: Visa học Thạc sĩ trong 2-3 năm, cần có TOPIK 4 trở lên. </div>
 
<div class='ps-5 fs-5'>
- VISA E7: Visa kỹ sư. </div>
 
<div class='ps-5 fs-5'>
- VISA E9: Visa lao động phổ thông.</div>
 
<div class='fs-1 py-3 text-success fw-bolder' >
CÁC TOP TRƯỜNG TẠI HÀN QUỐC </div>
 
<div class='ps-3 fw-bolder fs-5' >
TRƯỜNG TOP 1</div>
 
<div class=' ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp dưới 1%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường cấp Code xin Visa bay thẳng, không phỏng vấn Đại sứ quán </div>
 
<div class='ps-5 fs-5'>
- Trường ra thư mời, phỏng vấn Đại sứ quán để xin Visa </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nặng học thuật, điều kiện đầu vào cao</div>
 
<div class='fw-bolder ps-3 fs-5' >
TRƯỜNG TOP 2</div>
 <div class='ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp trên 1% - dưới 10%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường ra thư mời, phỏng vấn Đại sứ quán để cấp Code xin Visa </div>
 
<div class='ps-5 pb-3 fs-5'>
- Tỉ lệ đỗ Visa 50/50, điều kiện đầu vào dễ hơn TOP 1</div>
 
<div class='fw-bolder ps-3 fs-5'>
TRƯỜNG TOP 3</div>
 
<div class='ps-5 fs-5'>
- Có tỉ lệ du học sinh bất hợp pháp trên 10%/năm </div>
 
<div class='ps-5 fs-5'>
- Trường cấp Code xin Visa bay thẳng, không phỏng vấn Đại sứ quán </div>
 
<div class='ps-5 fs-5'>
- Điều kiện đầu vào dễ hơn TOP 1, dễ đi và chắc chắn nhất</div>
 
<div class='ps-5 fs-5 fs-5'>
- Cần mở sổ đóng băng 8-10 triệu Won Hàn, tính theo thời điểm mở</div>
 
<div class=' fs-1 text-success fw-bolder py-3' >
CÁC KỲ NHẬP HỌC</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 3: Kỳ chính</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học chính của hệ tiếng Visa D4-1 và hệ chuyên ngành Visa D2 </div>
 
<div class=' ps-5 fs-5'>
- Nhiều sự lựa chọn </div>
 
<div class=' ps-5 fs-5'>
- Tỉ lệ cạnh tranh cao, xin Visa sẽ khó hơn </div>
 
<div class=' ps-5 fs-5'>
- Nộp hồ sơ từ tháng 9-tháng 11 năm trước</div>
 
<div class=' ps-5 pb-3 fs-5'>
- Thời tiết dễ chịu, thích ứng nhanh</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 6: </div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học của hệ tiếng Visa D4-1 </div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh thấp, tăng cao tỉ lệ đỗ Visa </div>
 
<div class='ps-5 fs-5'>
- Nộp hồ sơ từ tháng 12 năm trước-tháng 2 cùng năm</div>
 
<div class='ps-5 pb-3 fs-5'>
- Thời tiết nóng hơn</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ tháng 9: Kỳ chính</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học chính của hệ tiếng Visa D4-1 và hệ chuyên ngành Visa D2 </div>
 
<div class=' ps-5 fs-5'>
- Nhiều sự lựa chọn  </div>
 
<div class=' ps-5 fs-5'>
- Thời tiết dễ chịu, thích ứng nhanh</div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh cao, xin Visa sẽ khó hơn </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nộp hồ sơ từ tháng 3-tháng 5 cùng năm </div>
 
<div  class=' ps-3 fw-bolder fs-5' >
Kỳ tháng 12:</div>
 
<div class='ps-5 fs-5'>
- Là kỳ nhập học của hệ tiếng Visa D4-1</div>
 
<div class='ps-5 fs-5'>
- Tỉ lệ cạnh tranh thấp, tăng cao tỉ lệ đỗ Visa; </div>
 
<div class='ps-5  fs-5'>
- Nộp hồ sơ từ tháng 12 năm trước-tháng 2 cùng năm </div>
 
<div class='ps-5 fs-5'>
- Thời tiết lạnh, mang hành lý nặng hơn </div>
<div class=' fs-1 text-success fw-bolder 3 '>
Các LOAI VISA DU HỌC HÀN QUỐC </div>
 
<div class=' fs-4 text-success fw-bolder ' >
VISA D4-1: Visa học tiếng tại Hàn</div>
 
<div class=' ps-5 fs-5'>
- Thời gian học tiếng tại Hàn tối đa 2 năm, cần đạt TOPIK 3 trở lên sau tốt nghiệp; </div>
 
<div class='ps-5 fs-5'>
- Sau tốt nghiệp, chuyển sang Visa D2-1 hoặc D2-2 học Cao đẳng/Đại học tại Hàn</div>
 
<div class='ps-5 fs-5'>
- Học từ thứ 2 đến thứ 6, nghỉ thứ 7 - Chủ nhật </div>
 
<div class='ps-5 fs-5'>
- Được đi làm thêm 20 giờ/tuần </div>
 
<div class='ps-5 fs-5'>
- Vào kỳ nghỉ được làm toàn thời gian </div>
 
<div class='ps-5 pb-3 fs-5'>
- Hiệu lực Visa 6 tháng - 1 năm, sau đó cần gia hạn</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Điều kiện tham gia: </div>
<div class=' ps-5  fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học tại Việt Nam không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên </div>
 
<div class='ps-5 fs-5'>
- Nghỉ học không quá 10 buổi/3 năm (theo học bạ) </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm </div>
 
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
 
<div class='ps-5 fs-5'>
- Trường TOP 1: Sổ tiết kiệm trị giá 8-10 triệu Won (150-190 triệu VND), thời hạn 12 tháng, gửi trước 6 tháng; không có sẽ được Công ty hướng dẫn </div>
<div class='ps-5 fs-5'>
- Trường TOP 2:  Sổ tiết kiệm trị giá 10.000 USD (khoảng 250 triệu VND), thời hạn 12 tháng, gửi trước 6 tháng; không có sẽ được Công ty hướng dẫn; </div>
 
 
<div class='ps-5 fs-5'>
- Trường TOP 3: Sổ đóng băng trị giá 8-10 triệu Won (150-190 triệu VND) </div>
 
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D4-6: Visa học nghề tại Hàn</div>
 
<div class='ps-5 fs-5'>
- Thời gian học rất ít từ thứ 2 đến thứ 5, các buổi chiều và thứ 6, 7 và chủ nhật đi thực hành tại Công ty liên kết với trường và được hưởng lương. </div>
 
<div class='ps-5 fs-5'>
- Thời hạn học từ 2 – 3 năm tùy vào lựa chọn khóa học. </div>
 
<div class='ps-5 fs-5'>
- Sau khi tốt nghiệp khóa học nghề cơ hội được ở lại Hàn Quốc làm việc lâu dài với visa E7. </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sau 3 tháng được sang học đi làm ngay, không bị giới hạn thời gian như chương trình visa D4-1 Đạt TOPIK 2 học luôn chuyên ngành nghề. </div>
 
<div class=' ps-3 fw-bolder fs-5'>
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Tuổi từ 18 đến 25 </div>
 
<div class='ps-5 fs-5'>
- Sinh viên đã hoặc đang học Trung cấp, Cao đẳng, Đại học </div>
 
<div class='ps-5 fs-5'>
- Có Topik 2 trở lên (được đào tạo) </div>
 
<div class='ps-5 fs-5'>
- Có trường Cao đẳng nghề của Hàn tiếp nhận </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Một số điều cần lưu ý:</div>
 
<div class='ps-5 fs-5'>
- Bắt buộc phỏng vấn tại Đại sứ quán để xin Visa nên sẽ khó và tỉ lệ đỗ Visa 50/50 </div>
 
<div class='ps-5 fs-5'>
- Yêu cầu tiếng cao hơn so với du học sinh thường </div>
 
<div class='ps-5 fs-5'>
- Sau khi tốt nghiệp sinh viên được cấp chứng chỉ nghề, nếu không học lên lên chuyên ngành hệ Đại học hay Cao đẳng nghề thì khó xin Visa E7 </div>
 
<div class='ps-5 fs-5'>
- Chi phí đi cao hơn, khoảng hơn 10,000 USD và chứng minh tài chính qua sổ tiết kiệm 5000-10,000 USD (tuỳ trường) gửi trước 6 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D2-1, D2-2: Visa học Cao đẳng, Đại học</div>
 
<div class='ps-5 fs-5'>
- Thời hạn Visa là 1-2 năm, sau đó gia hạn thêm; Đi làm thêm 25 giờ/tuần; thứ 7, Chủ nhật và kỳ nghỉ được làm thoải mái </div>
 
<div class='ps-5 pb-3 fs-5'>
- Được bảo lãnh người thân (cha mẹ, vợ/chồng/con, anh chị em) sang du lịch dưới 90 ngày</div>
 
<div  class=' ps-3 fw-bolder fs-5'>
Visa D2-1: </div>
 
<div class='ps-5 pb-3 fs-5'>
Học Cao đẳng tại Hàn trong 2-3 năm, cần có TOPIK 3/IELTS 5.5 trở lên </div>
 
<div class='ps-3 fw-bolder fs-5'>
Visa D2-2: </div>
 
<div class='ps-5 pb-3 fs-5'>
Học Đại học tại Hàn Quốc trong 4-5 năm, cần có TOPIK 3/IELTS 5.5 trở lên </div>
 
<div class=' ps-3 fw-bolder fs-5' >
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học tại Việt Nam không quá 3 năm </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên  </div>
 
<div class='ps-5 fs-5'>
- Nghỉ học không quá 10 buổi/3 năm (theo học bạ) </div>
 
<div class='ps-5 fs-5'>
- Topik 3 hoặc IELTS 5.5 trở lên </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
<div class='ps-5 fs-5'>
Sổ tiết kiệm 16-20 triệu Won (khoảng 300-380 triệu VND) kỳ hạn 12 tháng, gửi trước tối thiểu 3 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 ' >
VISA D2-3: Visa học Thạc sĩ</div>
 
<div class='ps-5 fs-5'>
- Chương trình học chuyên ngành 2 năm, học 2 kỳ/năm, mỗi kỳ 2,5-3 tháng, tuần học 1-2 buổi, còn lại là nghỉ để tự học nghiên cứu hoặc đi làm thêm </div>
 
<div class='ps-5 fs-5'>
- Nếu có Topik 4 từ Việt Nam, sẽ học thẳng lên chuyên ngành từ năm đầu tại Hàn </div>
 
<div class='ps-5 fs-5'>
- Nếu chưa có Topik 4 từ Việt Nam, sang Hàn học tiếng đến Topik 4 rồi mới học chuyên ngành </div>
 
<div class='ps-5 fs-5'>
- Học phí dao động 4-8 triệu Won Hàn/kỳ (80 - 150 triệu VND/kỳ) </div>
 
<div class='ps-5 pb-3 fs-5'>
- Được làm thêm 35 giờ/tuần vào kỳ học, không giới hạn vào cuối tuần và kỳ nghỉ</div>
<div class=' ps-3  fw-bolder fs-5' >
Điều kiện tham gia:</div>
 
<div class='ps-5 fs-5'>
-Tốt nghiệp Đại học tại Việt Nam, tuổi từ 23-30 tuổi </div>
 
<div class='ps-5 fs-5'>
- Điểm trung bình GPA từ 6,5/10 trở lên hoặc 2.0/4.0 trở lên </div>
 
<div class='ps-5 fs-5'>
- Topik 4 hoặc IELTS 6.0 trở lên </div>
 
<div class='ps-5 pb-3 fs-5'>
- Sức khoẻ tốt, không có bệnh truyền nhiễm</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Chứng minh tài chính:</div>
 
<div class='ps-5 fs-5'>
Sổ tiết kiệm 16-20 triệu Won (khoảng 300-380 triệu VND) hoặc 20,000 USD trở lên, kỳ hạn 12 tháng, gửi trước tối thiểu 3 tháng</div>
 
<div class=' fs-4 text-success fw-bolder py-3 fs-5' >
Chi phí sinh hoạt tại Hàn Quốc</div>
 
<div class=' ps-3 fw-bolder fs-5 '>
Tại thủ đô SEOUL</div>
 
<div class='ps-5 fs-5'>
+ Nhà ở: 180,000 - 500,000 Won/tháng (4-13 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Ăn uống: 220,000 - 450,000 Won/tháng (4,5-8,5 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Đi lại: 50,000 Won/tháng (1 triệu VND/tháng) </div>
 
<div class='ps-5 fs-5'>
+ Chi phí liên lạc: 30,000 Won/tháng (600,000 VND/tháng) </div>
 
<div class='ps-5 pb-3 fs-5'>
+ Chi phí mua sắm: 100,000 Won/tháng (2 triệu VND/tháng) </div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại BUSAN và các thành phố khác</div>
 
<div class='ps-5 fs-5'>
+ Nhà ở: 180,000 - 300,000 Won/tháng (4-6 triệu VND/tháng) </div>
 
<div class='ps-5 fs-5'>
+ Ăn uống: 200,000 - 300,000 Won/tháng (4,5-6 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Đi lại: 30,000 Won/tháng (1 triệu VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Chi phí liên lạc: 30,000 Won/tháng (600,000 VND/tháng)</div>
 
<div class='ps-5 fs-5'>
+ Chi phí mua sắm: 100,000 Won/tháng (2 triệu VND/tháng)</div>
 
<div  class=' fs-1 text-success fw-bolder py-3'>
QUY TRÌNH DU HỌC HÀN QUỐC TẠI DABOSA</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Nhập học tại Dabosa</div>
 
<div class=' ps-5 fs-5' >
- Lắng nghe tư vấn chương trình phù hợp; Ký hợp đồng, nộp tiền đợt 1 và nhập học</div>
 
<div class=' ps-3 fw-bolder fs-5' >
Học tập tại Dabosa</div>
 
<div class=' ps-5 fs-5' >
- Học tiếng Hàn 04 kỹ năng đến Topik 1 hoặc 2; Học kỹ năng mềm, văn hoá Hàn Quốc</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Chuẩn bị hồ sơ du học</div>
 
<div class=' ps-5 fs-5' >
- Trung tâm hướng dẫn học viên chuẩn bị, bổ sung và hoàn thiện các giấy tờ, thủ tục theo yêu cầu</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Nộp học phí sang trường Hàn</div>
 
<div class=' ps-5 fs-5' >
- Nộp học phí theo INVOICE thông báo của trường Hàn gửi về</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Xin Visa du học</div>
 
<div class=' ps-5 fs-5' >
- Tuỳ theo diện TOP trường, có thể sẽ cần phỏng vấn Đại sứ quán hoặc Visa thẳng</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Học nâng cao tiếng Hàn và chuyên ngành</div>
 
<div class=' ps-5 fs-5'  >
- Học tiếng Hàn đến Topik 3 hoặc 4 trong 1-2 năm đầu rồi lên học chuyên ngành trong 3-4 năm tiếp theo, kết hợp với đi làm thêm</div>
 
<div class=' fs-1 text-success fw-bolder py-3 ' >
VÌ SAO NÊN CHỌN DABOSA?</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại Việt Nam</div>
 
<div class='ps-5 fs-5'>
+ Trang bị ngoại ngữ, kỹ năng và kiến thức văn hóa, pháp luật Hàn Quốc </div>
 
<div class='ps-5 fs-5'>
+ Giao lưu cùng tiền bối có thêm kinh nghiệm khi du học </div>
 
<div class='ps-5 fs-5'>
+ Hoạt động ngoại khóa, teambuilding </div>
 
<div class='ps-5 fs-5'>
+ Cơ sở vật chất tiện nghi, hiện đại</div>
 
<div class='ps-5 fs-5'>
+ Có khu giải trí nghỉ ngơi, đọc sách</div>
 
<div class='ps-5 fs-5'>
+ Hướng dẫn tận tình, tận tâm </div>
 
<div class='ps-5 fs-5'>
+ Chương trình đa dạng, chi phí ưu đãi</div>
 
<div class=' ps-3 fw-bolder fs-5'>
Tại Hàn Quốc</div>
 
<div class='ps-5 fs-5'>
+ Đồng hành sát sao </div>
 
<div class='ps-5 fs-5'>
+ Giới thiệu việc làm, nơi ở, hướng dẫn các giấy tờ cá nhân</div>
 
<div class='ps-5 fs-5'>
+ Cầu nối giữa học viên và gia đình</div>
 
<div class='ps-5 fs-5'>
+ Hỗ trợ gia đình xử lý Visa thăm thân</div>
 
<div class='fs-1 text-success fw-bolder py-3 ' >
CHI PHÍ DU HỌC HÀN QUỐC</div>
 
<div class='ps-3 fw-bolder fs-5 '>Dao động khoảng 7000 - 9000 USD, bao gồm: </div>
 
<div class='ps-5 fs-5'>
+ Học tiếng Hàn 04 kỹ năng, thi chứng chỉ tiếng Hàn, đồng phục, giáo trình tài liệu học tập, học kỹ năng mềm, hoạt động ngoại khoá, ký túc xá tại DABOSA. </div>
 
<div class='ps-5 fs-5'>
+ Xử lý hồ sơ, giấy tờ, chuyển phát hồ sơ Việt-Hàn, khám sức khoẻ. </div>
 
<div class='ps-5 fs-5'>
+ Học phí 01 năm đầu tiên tại Hàn theo INVOICE. Vé máy bay 01 chiều. </div>
<div class='ps-3 fw-bolder fs-5'>
Lưu ý:</div>
<div class='ps-5 fs-5'>
- Chi phí biến động theo tỉ giá ngoại tệ (USD, Won Hàn) và mức học phí cần đóng sang Hàn cũng như mức độ hồ sơ của học viên. Chưa bao gồm tiền đóng băng, ký quỹ theo yêu cầu của trường Hàn.</div>
<div class=' fs-4 text-success fw-bolder py-3 ' >
Tiến độ đóng tiền</div>
 <div class='ps-3 fw-bolder fs-5'>
Đợt 01: </div>
 
<div class='ps-5 pb-3 fs-5' >
Đóng 28 triệu VND, nếu ở KTX tại DABOSA đóng 1,3 triệu/tháng, đã bao gồm điện, nước. </div>
 
<div class='ps-3 fw-bolder fs-5'>
Đợt 02: </div>
 
<div class='ps-5 fs-5'>
Đóng học phí 01 năm theo INVOICE của trường Hàn, khám sức khoẻ và dịch vụ chứng minh tài chính (nếu có). Đợt 03: Đóng số tiền còn lại khi có Code.</div>
 
<div class=' fs-1 text-success fw-bolder py-3' >
MỘT SỐ KỲ NGHỈ LỄ CỦA HÀN QUỐC</div>
 
<div class='ps-5 fs-5'>
+ DHS có thể làm không giới hạn trong kỳ nghỉ để nâng cao thu nhập </div>
 
<div class='ps-5 fs-5'>
+ DHS có thể đi thăm quan, trải nghiệm, khám phá cảnh đẹp, lễ hội và văn hóa Hàn Quốc </div>
 
<div class='ps-5 pb-3 fs-5'>
+ DHS có thể về thăm nhà tại Việt Nam trong kỳ nghỉ lễ này</div>
 
<div class='ps-3 fw-bolder fs-5 '>
Nghỉ Tết</div>
 
<div class='ps-5 fs-5'>
- Tết Dương 01 ngày 1/1 </div>
 
<div class='ps-5 fs-5'>
-  Nghỉ Tết Âm lịch (Seollal) 03 ngày, đó là: ngày 30, mùng 1 và mùng 2 âm lịch </div>
 
<div class='ps-5 pb-3 fs-5'>
- Nghỉ Tết Phật Đản 01 ngày</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ nghỉ hè</div>
 
<div class='ps-5 pb-3 fs-5' >
-  Kỳ nghỉ hè sẽ kéo dài 2 tháng, từ đầu tháng 7 đến cuối tháng 8, một số trường có thể được nghỉ đến giữa tháng 9.</div>
 
<div class='ps-3 fw-bolder fs-5'>
Kỳ nghỉ đông</div>
 
<div class='ps-5  pb-3 fs-5'>
-  Kỳ nghỉ mùa đông tại Hàn Quốc kéo dài từ tháng 1 đến hết tháng 2. Đây cũng là thời điểm lạnh nhất tại Hàn Quốc. Nhiệt độ luôn xuống dưới 0 độ C.</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nghỉ lễ khác</div>
 
<div class='ps-5 fs-5'>
-  Quốc tế Lao động (1/5 DL) Ngày Lập quốc (03/10) Hàn văn Hangeullal (9/10 DL) Ngày độc lập (15/8 DL) Lễ Giáng sinh ( 25/12 DL)</div>
 
<div class=' fs-1 text-success fw-bolder py-3'>
MỘT SỐ CÔNG VIỆC LÀM THÊM TẠI HÀN:
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thêm 20-25 giờ/tuần trong khi đi học và không giới hạn trong kỳ nghỉ
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Nhà hàng
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Công việc phụ bếp, bưng bê hoặc rửa bát tại các Nhà hàng của Hàn
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Làm tại nông trại
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Phụ giúp công việc trồng trọt, thu hoạch, chăn nuôi tại nông trại
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Cửa hàng Tiện lợi
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thu ngân, lau dọn, sắp xếp cửa hàng
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Dạy tiếng Việt
</div>
<div class='ps-5 pb-3 fs-5'>
- Dạy tiếng Việt cho người Hàn Quốc
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Nhân viên Siêu thị
</div>
 
<div class='ps-5 pb-3 fs-5'>
- Làm thu ngân, phục vụ hoặc chế biến thực phẩm
</div>
 
<div class='ps-3 fw-bolder fs-5'>
Phụ việc ở trường
</div>
 
<div class='ps-5 fs-5'>
- Phụ giúp công việc tại trường học, phòng thí nghiệm hoặc phụ việc Giáo sư
</div>
`;
export const Study_Duc = `
 <img src=${ProgramImages.duc1} class='w-100' />
<div class='fw-bolder  text-success  fs-1 py-3'>
ĐIỂM NỔI BẬT
</div>

<div class='ps-3 fw-bolder fs-5'>
• Miễn 100% học phí tại Đức :
</div>
<div class='ps-5 pb-3 fs-5'>
- Hầu hết các trường học nghề ở Đức đều miễn phí hoàn toàn tiền học phí cho sinh viên, nhằm khuyến khích sinh viên đi học để có kiến thức, tạo điều kiện giáo dục bình đẳng cho người dân.
</div>

<div class=' ps-3 fw-bolder fs-5'>
• Tỉ lệ đỗ Visa gần như 100% :
</div>
<div class='ps-5 pb-3 fs-5'>
- Chỉ cần có chứng chỉ tiếng Đức B1 trở lên với 4 kỹ năng kèm theo bản hợp đồng của doanh nghiệp tại Đức nơi học viên thực hành trong quá trình học nghề.
</div>

<div class='ps-3 fw-bolder fs-5 '>
• Có việc làm ngay trong tháng đầu sang Đức :
</div>
<div class='ps-5 pb-3 fs-5'>
- Học viên sang Đức diện vừa học nghề vừa thực hành nhận lương tại doanh nghiệp với thu nhập mỗi tháng 900 - 1200 Euro (khoảng 25 - 35 triệu/tháng, chưa tính thưởng, làm thêm). Sau khi tốt nghiệp, đi làm chính thức với thu nhập 2700 - 3500 Euro (70 - 90 triệu/tháng).
</div>

<div class=' ps-3 fw-bolder fs-5 '>
• Cơ hội ở lại Đức lâu dài :
</div>
<div class='ps-5 fs-5'>
- Sau 3 năm học nghề và 2 năm làm chính thức tại Đức, học viên có thể xin thẻ xanh hoặc xin cấp quốc tịch định cư để tự do đi lại giữa Việt Nam - Đức hoặc tự do đi lại trong Liên minh Châu Âu chỉ với Visa của Đức.
</div>

<div class='fw-bolder  text-success  fs-1 py-3 '>
ĐIỀU KIỆN TUYỂN SINH
</div>
<div class='ps-5 fs-5'>
- Nam, nữ Độ tuổi từ 18 - 35 tuổi
</div>
<div class='ps-5 fs-5'>
- Tốt nghiệp THPT/GDTX, Trung cấp, Cao đẳng hoặc Đại học
</div>
<div class='ps-5 fs-5'>
- Không yêu cầu mức điểm số Trình độ tiếng Đức B1 trở lên, chưa có sẽ được đào tạo
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
YÊU CẦU KHÁC
</div>

<div class='ps-5 fs-5'>
- Không mắc bệnh truyền nhiễm: HIV/AIDS, Viêm gan B, C.
</div>
<div class='ps-5 fs-5'>
- Đã tiêm Vắc-xin (viêm gan B,C, sởi, rubella, lao phổi,...).
</div>
<div class='ps-5 fs-5'>
- Không có người thân (bố, mẹ, anh, chị, em ruột) đang bất hợp pháp ở Đức.
</div>
<div class='ps-5 fs-5'>
- Không bị cấm xuất cảnh khỏi Việt Nam.
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
KỲ NHẬP HỌC
</div>

<div class='d-flex'>
<div class='fw-bolder fs-5 ps-3'>
Kỳ mùa hè:
</div>
<div class='ps-2 fs-5'>
Tháng 3 hoặc tháng 4.
</div>
</div>
<div class='d-flex'>
<div class='fw-bolder fs-5 ps-3'>
Kỳ mùa đông:
</div>
<div class='ps-2 fs-5'>
Tháng 8 hoặc tháng 9.
</div>
</div>
<div class='ps-5 fs-5'>
- Thời điểm xuất cảnh sang Đức phụ thuộc vào Visa và kỳ nhập học của các trường.
</div>
<div class='ps-5 fs-5'>
- Có thể sang Đức 02 tuần trước thời gian nhập học.
</div>


<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC CHỨNG CHỈ TIẾNG ĐỨC
</div>
<div class='ps-3 fw-bolder fs-5'>
03 loại chứng chỉ phổ biến ở Việt Nam:
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của Viện Goethe 
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của TELC
</div>
<div class='ps-5 fs-5'>
- Chứng chỉ của ECL
</div>

<div class='fw-bolder  text-success  fs-1 py-3'>
QUY TRÌNH DU HỌC ĐỨC TẠI DABOSA
</div>
<div class='ps-5 fs-5'>
- Lắng nghe tư vấn và ký hợp đồng tham gia chương trình
</div>
<div class='ps-5 fs-5'>
- Đào tạo tiếng Đức 04 kỹ năng, học kỹ năng mềm và văn hóa Đức
</div>
<div class='ps-5 fs-5'>
- Phỏng vấn doanh nghiệp Đức nơi học viên thực hành nghề
</div>
<div class='ps-5 fs-5'>
- Nhận hợp đồng gốc và xin Visa tại Đại sứ quán Đức
</div>
<div class='ps-5 fs-5'>
- Đỗ Visa và xuất cảnh sang Đức theo thời gian trên Visa
</div>
<div class='ps-5 fs-5'>
- Học nghề và thực hành tại doanh nghiệp, nhận trợ cấp hàng tháng
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
LỘ TRÌNH DU HỌC ĐỨC
</div>
<div class='ps-3 fw-bolder '>
TẠI VIỆT NAM :
</div>
<div class='ps-5 fs-5'>
- Học tiếng Đức 04 kỹ năng: nghe, nói, đọc, viết đến B1 hoặc B2 và thi chứng chỉ tiếng Đức Goethe hoặc TELC.
</div>
<div class='ps-5 fs-5'>
- Trung bình khoảng 8-10 tháng.
</div>
<div class='ps-5 fs-5'>
- Học kỹ năng mềm: giao tiếp, làm việc nhóm, quản lý tài chính, thuyết trình,...
</div>
<div class='ps-5 pb-3 fs-5'>
- Tìm hiểu văn hóa, pháp luật nước Đức.
</div>
<div class='ps-3 fw-bolder fs-5  '>
TẠI CHLB ĐỨC :
</div>
<div class='ps-5 fs-5'>
- Học nghề kết hợp đi làm thực tập tại doanh nghiệp liên quan đến ngành nghề trong 03 năm, hưởng thu nhập 900 - 1200 Euro/tháng (khoảng 25 - 35 triệu VND).
</div>
<div class='ps-5 fs-5'>
- Sau khi học nghề, đi làm chính thức với thu nhập 2500 - 3500 Euro/tháng (khoảng 70 - 90 triệu).
</div>
<div class='ps-5 fs-5'>
- Sau 05 năm tại Đức, có thể xin thẻ xanh hoặc xét định cư.
</div>
<div class='fw-bolder   text-success  fs-1 py-3'>
CHI PHÍ
</div>
<div class='ps-5 fs-5'>
- Học tiếng Đức 4 kỹ năng đến B1, kỹ năng mềm và tìm hiểu văn hóa Đức, Ký túc xá tại Dabosa.
</div>
<div class='ps-5 fs-5'>
- 01 lần thi chứng chỉ tiếng Đức B1, xử lý hồ sơ, kết nối phỏng vấn trường học và doanh nghiệp Đức, xin hợp đồng, xin Visa, vé máy bay,...
</div>
<div class='ps-5 fs-5'>
- Chưa bao gồm chi phí đóng băng chứng minh tài chính (nếu bên Đức yêu cầu), dao động 1800 Euro - 2500/3000 Euro
</div>
<div class='ps-5 fs-5'>
* Lưu ý: Chí biến động tăng/giảm theo thời điểm do tỉ giá ngoại tệ Euro - VND, giá vé máy bay và chưa bao gồm chi phí chứng minh tài chính (nếu bên Đức yêu cầu).
</div>
<div class='pb-3 fs-5 ps-5 '>
TỔNG PHÍ: 8000 - 9000 Euro
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
TIẾN ĐỘ ĐÓNG TIỀN
</div>
<div class='ps-5 fs-5'>
• Đợt 1: Đóng 40 triệu VND khi ký hợp đồng du học cùng Dabosa. Nếu ở Ký túc xá của Dabosa, đóng 1,3 triệu/tháng, đã bao gồm điện, nước. Đóng 3 hoặc 6 tháng.
</div>
<div class='ps-5 fs-5'>
• Đợt 2: Đóng 30 triệu VND khi vào học chương trình A2 tiếng Đức (cách đợt 1 khoảng 3 tháng)
</div>
<div class='ps-5 fs-5'>
• Đợt 3: Đóng 130 triệu VND khi nhận hợp đồng của doanh nghiệp Đức (cách đợt 2 khoảng 5-7 tháng)
</div>
<div class='ps-5 fs-5'>
• Đợt 4: Đóng số còn lại khi có Visa
</div>
<div class='ps-5 fs-5'>
=> Trường hợp cần đóng băng chứng minh tài chính sẽ có thông báo sau khi phỏng vấn doanh nghiệp và nhận hợp đồng gửi về Việt Nam.
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CHI PHÍ SINH HOẠT TẠI ĐỨC
</div>
<div class='ps-3 fs-5 fw-bolder '>
CÁC KHOẢN PHÍ :
</div>
<div class='ps-5 fs-5'>
- Tiền thuê nhà, nhà ở: 250 - 400 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Tiền ăn uống: 200 - 300 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Di chuyển bằng phương tiện công cộng: 80 - 150 Euro/tháng
</div>
<div class='ps-5 fs-5'>
- Bảo hiểm: 80 - 160 Euro/tháng
</div>
<div class='ps-5 pb-3 fs-5'>
- Điện, nước, internet: 100 - 150 Euro/tháng
</div>
<div class=' ps-3 fw-bolder fs-5 '>
TỔNG :
</div>
<div class='ps-5 fs-5'>
710 - 1310 Euro/tháng
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC KỲ NGHỈ Ở ĐỨC
</div>
<div class='ps-3 fw-bolder fs-5 '>
KỲ NGHỈ HÈ :
</div>
<div class='ps-5 fs-5'>
- Kỳ nghỉ hè sẽ có sự chênh lệch tùy theo khu vực, không diễn ra cùng thời điểm. Ở Tây Đức, kỳ nghỉ hè diễn ra từ tháng 8 đến tháng 10 hằng năm. Ở Đông Đức, kỳ nghỉ hè diễn ra từ giữa tháng 7 đến hết tháng 8 hằng năm.
</div>
<div class='ps-5 pb-3 fs-5'>
- Trong kỳ nghỉ hè, du học sinh có thể đi du lịch, nghỉ dưỡng, khám phá Đức hoặc làm thêm để nâng cao thu nhập cho bản thân hoặc gửi về Việt Nam phụ giúp gia đình.
</div>
<div class=' fw-bolder ps-3 fs-5 '>
KỲ NGHỈ ĐÔNG :
</div>
<div class='ps-5 fs-5'>
- Kỳ nghỉ đông ở Đức sẽ có thời gian nghỉ chung từ giữa tháng 12 đến hết tháng 1 năm sau, là thời điểm nhiệt độ xuống thấp nhất, lạnh nhất trong năm. Với kỳ nghỉ đông, du học sinh thường ở nhà nghỉ ngơi, tự ôn tập và nâng cao trình độ ngoại ngữ, kiến thức hoặc về thăm gia đình tại Việt Nam
</div>
<div class='fw-bolder  text-success  fs-1 py-3'>
CÁC NGÀNH NGHỀ LÀM THÊM
</div>
<div class='ps-3 fw-bolder fs-5 '>
NGÀNH NHÀ HÀNG :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Tư vấn cho khách hàng Tiếp nhận, xử lý yêu cầu, thu ngân đơn đặt hàng của khách Dọn dẹp, trang trí, bày biện không gian của nhà hàng Tham gia công tác tổ chức sự kiện tại nhà hàng
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khả năng giao tiếp khá trở lên, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và có sự linh hoạt
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH LÀM BÁNH :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Tính toán công thức, chuẩn bị nguyên liệu làm bánh Làm các loại bánh mì, bánh ngọt, bánh quy,...theo yêu cầu Kiểm soát quy trình làm bánh, nướng bánh theo hướng dẫn Đảm bảo vệ sinh không gian và vệ sinh thực phẩm
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm, làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH ĐẦU BẾP :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Lên thực đơn, chuẩn bị nguyên liệu Tính toán, nấu và trình bày món ăn Xây dựng quy trình nấu ăn, làm việc Dọn dẹp, vệ sinh nơi làm việc
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc 40 giờ/tuần, làm việc lịch phân công Thu nhập khi học 900-950 Euro/tháng và 2500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
CHẾ BIẾN THỰC PHẨM :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Kiểm tra chất lượng Chế biến các thực phẩm từ thịt và xúc xích Tư vấn bán hàng và học các kiến thức về thành phần Giữ gìn vệ sinh và bảo quản thực phẩm
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Làm việc 40 giờ/tuần, theo lịch/ca được phân công Thu nhập khi học nghề khởi điểm từ 900 - 950 Euro Sau khi tốt nghiệp, từ 2500 Euro/tháng trở lên
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Cẩn thận, chăm chỉ, chu đáo Khả năng làm việc nhóm, chịu được áp lực và có sự linh hoạt
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH ĐIỀU DƯỠNG :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Học viên sẽ được đào tạo bài bản và chi tiết cách hướng dẫn, giáo dục, chăm sóc và hỗ trợ cho người có khiếm khuyết về thể chất và tinh thần. Từ đó giúp bệnh nhân phục hồi được các chức năng và có thể tự mình làm được sinh hoạt cá nhân.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc theo lịch phân công Thu nhập khi học 1100-1400 Euro/tháng và 3000 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='pb-3 ps-5 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder  fs-5'>
NGÀNH TRỢ LÝ NHA KHOA :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Hỗ trợ bác sĩ nha khoa, quản lý hoạt động chung của phòng khám, lên lịch hẹn và quản lý lịch làm việc của bác sĩ với bệnh nhân, chăm sóc bệnh nhân.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3 năm và làm việc theo lịch phân công Thu nhập khi học 1100-1400 Euro/tháng và 3000 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='ps-5 pb-3 fs-5'>
- Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
<div class=' ps-3 fw-bolder fs-5 '>
NGÀNH CƠ KHÍ ĐIỆN TỬ :
</div>
<div class='ps-5 fs-5'>
- Mô tả công việc: Lập trình và vận hành máy móc, thiết bị gia công, sản xuất linh kiện kim loại, điện tử. Thiết kế bản vẽ vi mạch và làm việc với sơ dồ mạch. Kiểm tra, bảo dưỡng máy móc, thiết bị.
</div>
<div class='ps-5 fs-5'>
- Thời gian làm việc, thu nhập: Học 3-5 năm và làm việc theo lịch phân công Thu nhập khi học 900-1400 Euro/tháng và 2800 - 3500 Euro/tháng trở lên khi làm chính thức
</div>
<div class='pb-5 ps-5 fs-5'>
*Yêu cầu: Khéo léo, sáng tạo, cẩn thận, chăm chỉ, chu đáo, nhẫn nại Chịu được áp lực và sự linh hoạt, kỹ năng quản lý công việc
</div>
`;
export const Study_UC = `
 <img src=${ProgramImages.uc1} class='w-100' />
 <div class='py-3 fw-bolder text-success fs-1'>
PHÚC LỢI:
</div>
 <div class='pb-3 fs-5 '>
- Top các nền giáo dục hàng đầu thế giới
</div>
 <div class='pb-3 fs-5'>
- Nhiều cơ hội học bổng cho sinh viên quốc tế tại Úc
</div>
 <div class='pb-3 fs-5'>
- Cơ hội làm việc tại Úc rộng mở
</div>
 <div class='pb-3 fs-5'>
- Chi phí du học hợp lý
</div>

 <div class=' pb-3 fw-bolder  text-success  fs-1'>
ĐIỀU KIỆN TUYỂN SINH:
</div>

 <div class='pb-3 fs-5'>
- Nam, nữ tuổi từ 19 - 30 tuổi
</div>
 <div class='pb-3 fs-5'>
- Tốt nghiệp THPT, Cao đẳng, Đại học, điểm TB từ 6.5 trở lên, trống không quá 02 năm (từ khi tốt nghiệp đến khi đi làm có hợp đồng, bảo hiểm đầu tiên)
</div>
 <div class='pb-3 fs-5'>
- Trình độ tiếng Anh IELTS 5.5 - 6.0 trở lên hoặc PTE 46
</div>
 <div class='pb-3 fs-5'>
- Sức khỏe tốt, không mắc bệnh truyền nhiễm
</div>
 <div class='pb-3 fs-5'>
- Chứng minh tài chính: Thu nhập cố định của bố mẹ (40-60 triệu/tháng), tài sản đối ứng (bất động sản, ô tô,...), sổ tiết kiệm tại ngân hàng khoảng 800 triệu - 1 tỷ trở lên (nếu không có, liên hệ để được hỗ trợ)
</div>
 <div class='pb-3 fw-bolder  text-success  fs-1'>
QUY TRÌNH:
</div>
<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 1:
</div>
<div class='ps-3 pb-3 fs-5'>
Tư vấn lựa chọn và ký hợp đồng
</div>
</div> 

 <div class='d-flex'>
 <div class='pb-3 fw-bolder fs-5'>
Bước 2: 
</div>
<div class='ps-3 pb-3 fs-5'>
Học tiếng Anh và ôn luyện thi IELTS
</div>
</div>

<div class='d-flex'>
 <div class='pb-3 fw-bolder fs-5'>
Bước 3: 
</div>
<div class='ps-3 pb-3 fs-5'>
Xử lý hồ sơ giấy tờ nộp cho trường
</div>
</div>

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 4:
</div>
<div class='ps-3 pb-3 fs-5'>
Nhận INVOICE, đóng học phí và tiền đặt cọc
</div>
</div> 

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 5: 
</div>
<div class='ps-3 pb-3 fs-5'>
Xin Visa
</div>
</div> 

<div class='d-flex'>
<div class='pb-3 fw-bolder fs-5'>
Bước 6: 
</div>
<div class='ps-3 pb-3 fs-5'>
Khám sức khỏe, lấy vân tay
</div>
</div> 

<div class='d-flex' >
 <div class='pb-3 fw-bolder fs-5'>
Bước 7: 
</div> 
<div class='ps-3 pb-3 fs-5'>
Nhận Visa và xuất cảnh
</div>
</div>

`;
export const Study_TrungQuoc = `
 <img src=${ProgramImages.trungquoc1} class='w-100 pb-3' />
<div class='pb-3 fw-bolder text-success fs-1'>
1. ĐIỂM NỔI BẬT
</div>
<div class=' ps-5 fs-5'>
- Chất lượng giáo dục hàng đầu thế giới
</div>
<div class='ps-5 fs-5'>
- Chi phí học tập hợp lý
</div>
<div class='ps-5 fs-5'>
- Rất gần Việt Nam, an ninh trật tự tốt
</div>
<div class='ps-5 fs-5'>
- Chất lượng đào tạo tốt, cơ hội việc làm rộng mở
</div>
<div class='pb-3 ps-5 fs-5'>
- Thủ tục Visa đơn giản, nhanh chóng
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
2. ĐIỀU KIỆN THAM GIA 
</div>
<div class='fw-bolder ps-3 fs-5'>
-   Hệ học tiếng 1 năm:
</div>
<div class='ps-5 fs-5'>
+ Tốt nghiệp THPT trở lên, GPA trên 7.0
</div>
<div class='ps-5 fs-5'>
+ Có chứng chỉ HSK3 trở lên
</div>
<div class='ps-5 fs-5'>
+ Giấy giới thiệu của Giáo viên, Hiệu trưởng, Trường học
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class=' ps-3 fw-bolder fs-5'>
-   Hệ Đại học:
</div>
<div class=' ps-5 fs-5'>
+ Tốt nghiệp THPT trở lên, GPA trên 7.0
</div>
<div class=' ps-5 fs-5'>
+ Không quá 25 tuổi
</div>
<div class='ps-5 fs-5'>
+ Có chứng chỉ HSK4 trở lên, hoặc IELTS từ 5.0 trở lên
</div>
<div class='ps-5 fs-5'>
+ Giấy giới thiệu Giáo viên, Hiệu trưởng, Trường học,…
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class='fw-bolder ps-3 fs-5'>
-  Hệ Thạc sĩ:
</div>
<div class=' ps-5 fs-5'>
+ Tốt nghiệp Đại học, GPA từ 7.0 trở lên
</div>
<div class=' ps-5 fs-5'>
+ Không quá 35 tuổi
</div>
<div class=' ps-5 fs-5'>
+ Có chứng chỉ HSK5 trở lên hoặc IELTS 6.0 trở lên
</div>
<div class='ps-5 fs-5'>
+ 02 Giấy giới thiệu từ Giáo sư, Tiến sĩ
</div>
<div class='ps-5 fs-5'>
+ 01 Bản kế hoạch học tập/nghiên cứu viết 800 – 1500 chữ
</div>
<div class='pb-3 ps-5 fs-5'>
+ Giấy chứng nhận tham gia các hoạt động xã hội, ngoại khóa, nghiên cứu hoặc công việc.
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
3. MỘT SỐ NGÀNH THÔNG DỤNG
</div>
<div class='pb-3 ps-5 fs-5'>
- Ngôn ngữ Trung, Y khoa, Kinh tế, Du lịch, Sản xuất phim,...
</div>
<div class='pb-3 fw-bolder  text-success fs-1'>
4. QUY TRÌNH
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 1: Tư vấn, lựa chọn và ký hợp đồng
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 2: Học tiếng và thi chứng chỉ
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 3: Xử lý hồ sơ
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 4: Xin Visa
</div>
<div class='pb-3 ps-5 fs-5'>
- Bước 5: Xuất cảnh
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
5. CHI PHÍ
</div>
<div class='pb-3 ps-5 fs-5'>
- Chỉ từ 80 triệu VND
</div>

`;
export const Study_DaiLoan = `
 <img src=${ProgramImages.dailoan1} class='w-100' />
<div class='pb-3 fw-bolder text-success fs-1'>
1. PHÚC LỢI
</div>
<div class='fs-5 ps-5'>
- Nền giáo dục Đài Loan hiện nay xếp hạng thứ 17 thế giới.
</div>
<div class='ps-5 fs-5'>
- Chương trình giáo dục theo chuẩn của Mỹ 100%
</div>
<div class='ps-5 fs-5'>
- Bằng tốt nghiệp tại các trường Đại học ở Đài Loan được trên 200 quốc gia trên thế giới công nhận.
</div>
<div class=' ps-5 fs-5'>
- Cơ hội việc làm rộng mở ngay sau khi tốt nghiệp
</div>
<div class=' ps-5 fs-5'>
- Chuyên ngành đào tạo phong phú với nhiều ngành nghề 
</div>
<div class=' ps-5 fs-5'>
- Mức chi phí vừa phải, hợp với túi tiền của đại đa số người dân Việt Nam
</div>
<div class=' ps-5 fs-5'>
- Yêu cầu đầu vào vô cùng dễ dàng, nhiều chương trình học bổng
</div>
<div class='pb-3 ps-5 fs-5'>
- Thời gian đi học ít, chỉ tầm 2 – 3 tiếng/ngày nên có thể đi làm thêm 20h/tuần
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
2. ĐIỀU KIỆN TUYỂN SINH
</div>
<div class='ps-5 fs-5'>
- Nam, nữ từ 18 - 28 tuổi
</div>
<div class=' ps-5 fs-5'>
- Tốt nghiệp THPT, GDTX, Trung cấp, Cao đẳng, Đại học từ 6.0 trở lên
</div>
<div class='pb-3 ps-5 fs-5'>
- Sức khỏe tốt, không mắc bệnh truyền nhiễm
</div>
<div class='pb-3 fw-bolder  text-success fs-1'>
3. CHI PHÍ
</div>
<div class='pb-3 ps-5 fs-5'>
- Chỉ từ 60 triệu trở lên, tùy theo tỷ giá ngoại tệ và gói dịch vụ
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
4. CÁC CHƯƠNG TRÌNH
</div>
<div class='fw-bolder fs-5  ps-3 '>
4.1. Du học tiếng
</div>
<div class='ps-5 fs-5'>
- Dành cho những bạn học sinh chưa đáp ứng điều kiện tiếng Trung để theo học khóa học chính quy hệ Cao đẳng, Đại học hay Thạc sĩ.
</div>
<div class=' ps-5 fs-5'>
- Thời gian khóa học sẽ kéo dài từ 6 tháng – 01 năm tùy theo năng lực
</div>
<div class='pb-3 ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 1 trở lên hoặc chứng chỉ tiếng Anh TOEIC 350 điểm trở lên tại Việt Nam
</div>
<div class='fw-bolder fs-5 ps-3'>
4.2. Du học Đại học
</div>
<div class='ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 3 trở lên hoặc chứng chỉ tiếng Anh IELTS 5.5 trở lên nếu học bằng tiếng Anh, tùy theo trường và tùy theo ngành
</div>
<div class='pb-3 ps-5 fs-5'>
- Thời gian học 4 năm, ngành nghề phong phú, đa dạng
</div>
<div class='fw-bolder ps-3 fs-5'>
4.3. Du học Thạc sĩ
</div>
<div class=' ps-5 fs-5'>
- Có chứng chỉ năng lực Hoa ngữ TOCFL cấp độ 4 trở lên hoặc chứng chỉ tiếng Anh IELTS 6.0 trở lên nếu học bằng tiếng Anh, tùy theo trường và tùy theo ngành
</div>
<div class='ps-5 fs-5'>
- Thời gian học 2 - 4 năm
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
5. QUY TRÌNH
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 1: Lắng nghe tư vấn và quyết định tham gia, nộp giấy tờ theo hướng dẫn
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 2: Học tiếng
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 3: Xác nhận hồ sơ Việt - Anh hoặc Việt - Trung
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 4: Xác nhận hồ sơ qua Cục Lãnh sự
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 5: Xác nhận hồ sơ tại Văn phòng Đài Bắc
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 6: Nộp hồ sơ xin Visa
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 7: Lấy kết quả Visa
</div>
<div class='pb-1 ps-5 fs-5'>
- Bước 8: Xuất cảnh
</div>
<div class='pb-3 fw-bolder text-success fs-1'>
6. KỲ NHẬP HỌC
</div>
<div class=' ps-5 fs-5'>
- Kỳ tháng 9: Nộp hồ sơ tháng 1 - tháng 3 cùng năm
</div>
<div class='pb-3 ps-5 fs-5 '>
- Kỳ tháng 2: Nộp hồ sơ tháng 7 - tháng 10 năm trước
</div>
 
`;
export const cam_nang_nhat = `
<div class='fw-semibold fs-1 text-success py-3'>
Cẩm nang Du học Nhật Bản 2024: Ngành nào phù hợp với bạn?
</div>
<div  class='pb-3 fs-5 ps-5'>
Du học Nhật Bản đang trở thành xu hướng với nền giáo dục chất lượng cao, môi trường học tập hiện đại và cơ hội phát triển đa dạng. Năm 2024, các ngành học tại Nhật tiếp tục có sự phát triển mạnh mẽ, đặc biệt là những ngành gắn liền với công nghệ, kinh doanh và văn hóa. Nếu bạn đang cân nhắc du học Nhật, đây là lúc lý tưởng để tìm hiểu những ngành học phù hợp nhất!
</div>
<div class='fw-semibold fs-1 text-success py-3'>
1. Ngành Công nghệ Thông tin (CNTT)
</div>
<div class='pb-3 fs-5 ps-5' >
• Tại sao nên chọn CNTT? Nhật Bản là một trong những quốc gia hàng đầu về công nghệ và kỹ thuật số. Chính phủ Nhật đang đầu tư mạnh vào các ngành công nghệ mũi nhọn như trí tuệ nhân tạo (AI), dữ liệu lớn (Big Data) và an ninh mạng. Học ngành CNTT ở Nhật Bản sẽ mang lại cho bạn cơ hội học tập trong một môi trường tiên tiến, trang thiết bị hiện đại và đội ngũ giảng viên hàng đầu.
</div>
<div class='pb-3 fs-5 ps-5' >
• Chương trình học: Sinh viên sẽ được học về lập trình, phát triển phần mềm, trí tuệ nhân tạo và các kỹ thuật tiên tiến trong an ninh mạng.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội việc làm: Các công ty Nhật Bản luôn cần nhân sự công nghệ có tay nghề cao. Sinh viên quốc tế tốt nghiệp có thể xin việc tại Nhật với mức lương hấp dẫn và nhiều cơ hội thăng tiến.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
2. Ngành Kinh tế – Quản trị
</div>
<div class='pb-3 fs-5 ps-5' >
• Lý do nên chọn ngành Kinh tế – Quản trị: Nhật Bản là một trong những nền kinh tế lớn nhất thế giới, và các công ty Nhật nổi tiếng với cách quản trị hiệu quả. Ngành này phù hợp với những ai muốn học về kinh tế toàn cầu, quản lý và vận hành doanh nghiệp theo phong cách Nhật Bản.
</div>
<div class='pb-3 fs-5 ps-5' >
• Nội dung đào tạo: Chương trình học tập trung vào các kiến thức về kinh tế, tài chính, quản trị kinh doanh, và quản lý chuỗi cung ứng.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội nghề nghiệp: Sinh viên có thể làm việc tại các tập đoàn đa quốc gia hoặc các công ty Nhật ở Việt Nam và trên thế giới.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
3. Ngành Ngôn ngữ & Văn hóa Nhật Bản
</div>
<div class='pb-3 fs-5 ps-5' >
• Nếu bạn yêu thích văn hóa và ngôn ngữ Nhật Bản, ngành học này chắc chắn là lựa chọn hoàn hảo. Bạn sẽ được đào sâu về ngôn ngữ, văn hóa, lịch sử và xã hội Nhật Bản.
</div>
<div class='pb-3 fs-5 ps-5' >
• Chương trình học: Các khóa học tiếng Nhật nâng cao, văn học Nhật Bản, văn hóa truyền thống và hiện đại.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội việc làm: Sinh viên tốt nghiệp có thể trở thành phiên dịch viên, biên dịch viên, giáo viên tiếng Nhật hoặc làm việc trong ngành du lịch, sự kiện và truyền thông.
</div>
<div class='fw-semibold fs-1 text-success py-3'>
4. Các ngành Kỹ thuật và Sáng tạo
</div>
<div class='pb-3 fs-5 ps-5' >
• Nhật Bản là đất nước đi đầu trong các ngành công nghiệp kỹ thuật và sáng tạo, đặc biệt là kỹ thuật ô tô và thiết kế. Sinh viên sẽ được đào tạo chuyên sâu và có cơ hội thực hành tại các tập đoàn lớn như Toyota, Honda, Sony.
</div>
<div class='pb-3 fs-5 ps-5' >
• Nội dung đào tạo: Các môn học về kỹ thuật cơ khí, kỹ thuật điện, thiết kế và sáng tạo.
</div>
<div class='pb-3 fs-5 ps-5' >
• Cơ hội nghề nghiệp: Sinh viên tốt nghiệp có thể làm việc trong ngành ô tô, hàng không, điện tử hoặc trở thành nhà thiết kế sáng tạo.
</div>
<div class='pb-3 fw-bolder fs-5 ps-3' >
Kết luận: Làm sao để chọn ngành phù hợp khi du học Nhật Bản?
</div>
<div class='pb-3 fs-5 ps-5' >
• Chọn ngành học phù hợp không chỉ dựa trên sở thích mà còn cần cân nhắc đến xu hướng việc làm và cơ hội phát triển cá nhân. Nếu bạn yêu thích công nghệ, quản trị hay văn hóa Nhật Bản, Nhật Bản chắc chắn sẽ mang đến cho bạn nền tảng tốt để phát triển sự nghiệp. Để chuẩn bị tốt nhất, hãy tìm hiểu kỹ về từng ngành học và lộ trình phát triển để có thể tận dụng tối đa các cơ hội mà du học Nhật Bản mang lại.
</div>
`;
export const cam_nang_nghe_duc = `
    <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.duc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Cẩm nang Du học Nghề Đức từ A – Z
</div>
<div class='ps-5  fs-5'>
Du học nghề tại Đức là một trong những lựa chọn phổ biến dành cho sinh viên quốc tế, nhờ chi phí hợp lý, cơ hội việc làm cao và chương trình đào tạo thực tế. Đức cũng được biết đến với hệ thống giáo dục nghề nghiệp song hành (Dual System), kết hợp giữa lý thuyết và thực hành tại doanh nghiệp, giúp sinh viên sẵn sàng cho thị trường lao động ngay sau khi tốt nghiệp. </div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Lý do chọn du học nghề tại Đức </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Chi phí thấp và có lương trợ cấp:  </div>
<div class='ps-5  fs-5'>
- Du học nghề tại Đức đặc biệt hấp dẫn vì học viên thường không phải trả học phí và còn được hưởng trợ cấp hàng tháng từ các công ty thực tập. Điều này giúp giảm gánh nặng tài chính cho sinh viên quốc tế. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Chất lượng giáo dục nghề cao:  </div>
<div class='ps-5  fs-5'>
- Đức nổi tiếng với các ngành nghề kỹ thuật và công nghệ, và sinh viên được đào tạo bởi các giảng viên chuyên nghiệp kết hợp với việc thực hành ngay tại doanh nghiệp. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Cơ hội việc làm rộng mở:  </div>
<div class='ps-5  fs-5'>
- Sau khi tốt nghiệp, sinh viên nghề có cơ hội làm việc ngay tại Đức với các công ty hàng đầu. Chính phủ Đức cũng hỗ trợ sinh viên quốc tế gia hạn visa để tìm việc sau khi học xong. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Các ngành nghề phổ biến tại Đức </div>
<div class=' fw-bolder ps-3 fs-5'>
• Điều dưỡng:
</div>
<div class='ps-5 fs-5'>
- Ngành điều dưỡng tại Đức luôn thiếu nhân lực, là lựa chọn hàng đầu với mức lương và cơ hội làm việc lâu dài.
 </div>
<div class='ps-3 fw-bolder fs-5'>
• Kỹ thuật cơ khí và điện tử: 
</div>
<div class='ps-5 fs-5'>
- Đức là cái nôi của ngành kỹ thuật, nơi sinh viên có thể tiếp cận công nghệ tiên tiến, môi trường làm việc chuyên nghiệp. ]
</div>
<div class='ps-3 fw-bolder fs-5'>
• Kỹ thuật cơ khí và điện tử:
 </div>
<div class='ps-5 fs-5'>
- Đức là cái nôi của ngành kỹ thuật, nơi sinh viên có thể tiếp cận công nghệ tiên tiến, môi trường làm việc chuyên nghiệp.
 </div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Điều kiện và hồ sơ cần thiết </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Yêu cầu học vấn: </div>
<div class='ps-5 fs-5'>
- Thường tối thiểu là tốt nghiệp trung học phổ thông (THPT). </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Trình độ tiếng Đức:  </div>
<div class='ps-5 fs-5'>
- Thông thường từ trình độ B1 đến B2, tùy ngành học và chương trình yêu cầu. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Hồ sơ du học nghề:  </div>
<div class='ps-5 fs-5'>
- Hồ sơ gồm giấy tờ học vấn, chứng chỉ tiếng Đức, thư động lực (Motivationsschreiben), sơ yếu lý lịch (Lebenslauf), và đôi khi cần thư mời thực tập từ công ty Đức. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Chương trình đào tạo và lộ trình nghề nghiệp </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Thời gian học:  </div>
<div class='ps-5 fs-5'>
- Chương trình du học nghề thường kéo dài từ 2-3 năm. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Phương pháp đào tạo: </div>
<div class='ps-5 fs-5'>
- Kết hợp giữa học lý thuyết tại trường và thực hành tại doanh nghiệp. Học viên sẽ làm việc từ 3-4 ngày/tuần và học lý thuyết từ 1-2 ngày còn lại. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Phương pháp đào tạo:  </div>
<div class='ps-5 fs-5'>
- Kết hợp giữa học lý thuyết tại trường và thực hành tại doanh nghiệp. Học viên sẽ làm việc từ 3-4 ngày/tuần và học lý thuyết từ 1-2 ngày còn lại. </div>
<div class='fw-bolder fs-1  text-success py-3'>
5. Lợi ích và thách thức khi du học nghề tại Đức </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Lợi ích:  </div>
<div class='ps-5 fs-5'>
- Tiết kiệm chi phí, có thu nhập trong quá trình học, và cơ hội định cư tại Đức. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
• Thách thức: </div> 
<div class='ps-5 fs-5'>
- Rào cản ngôn ngữ là một khó khăn lớn đối với nhiều sinh viên quốc tế, đồng thời việc thích nghi với văn hóa làm việc nghiêm túc của Đức cũng cần thời gian. </div>
<div class='ps-5 fs-5'>
- Du học nghề tại Đức là một hướng đi tiết kiệm và hiệu quả cho các bạn trẻ. Lựa chọn ngành nghề phù hợp, học hỏi tiếng Đức và nắm rõ thông tin về thị trường lao động sẽ giúp bạn có một hành trình học tập và làm việc thành công tại quốc gia châu Âu này. </div>

`;

export const cam_nang_uc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.uc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Những điều thú vị khi sinh sống và học tập tại Úc
</div>
<div class='ps-5 fs-5'>
Úc là điểm đến lý tưởng cho sinh viên quốc tế nhờ vào môi trường sống năng động, nền giáo dục chất lượng cao, và cơ hội phát triển cá nhân. Khi sinh sống và học tập tại đây, bạn sẽ được trải nghiệm nhiều điều thú vị từ văn hóa, khí hậu cho đến phong cách sống. </div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Khám phá văn hóa đa dạng </div>
<div class='ps-5 fs-5'>
- Úc là nơi giao thoa của nhiều nền văn hóa, thu hút người dân từ khắp nơi trên thế giới. Bạn có thể dễ dàng bắt gặp những nét văn hóa từ châu Á, châu Âu, và thổ dân Úc bản địa trong đời sống hàng ngày. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Phong tục và lễ hội đặc sắc: </div>
<div class='ps-5 fs-5'>
- Các lễ hội như Australia Day, Sydney Festival, Melbourne International Comedy Festival tạo không khí sôi động quanh năm. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Ẩm thực đa dạng: </div>
<div class='ps-5 fs-5'>
- Bạn có thể tìm thấy hàng trăm món ăn từ các nền văn hóa khác nhau, từ nhà hàng châu Á đến những món ăn đặc trưng của người Úc như bánh Lamington hay thịt Kangaroo. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Khí hậu và cảnh đẹp tự nhiên </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Khí hậu dễ chịu: </div>
<div class='ps-5  fs-5'>
- Úc có bốn mùa rõ rệt nhưng lại tương đối ấm áp quanh năm. Các thành phố lớn như Sydney, Melbourne, Brisbane mang lại trải nghiệm sống tiện nghi với khí hậu thoải mái.</div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Cảnh đẹp nổi tiếng:  </div> 
<div class='ps-5  fs-5'>
- Những điểm đến như Sydney Opera House, Great Barrier Reef, và Blue Mountains là nơi lý tưởng cho các hoạt động ngoài trời.</div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Hệ thống giáo dục hiện đại </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Các trường đại học hàng đầu: </div>
<div class='ps-5  fs-5'>
- Úc có nhiều trường đại học thuộc top thế giới như Đại học Sydney, Đại học Melbourne, Đại học Quốc gia Úc (ANU).</div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Phương pháp học đa dạng: </div>
<div class='ps-5  fs-5'>
- Học sinh có thể tự do lựa chọn giữa học online, học tại lớp và các hoạt động nghiên cứu thực tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Cân bằng giữa học tập và làm thêm </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Chính sách làm việc bán thời gian: </div>
<div class='ps-5  fs-5'>
- Úc cho phép sinh viên quốc tế làm việc 20 giờ/tuần trong thời gian học và toàn thời gian vào kỳ nghỉ. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
• Trải nghiệm học tập và làm thêm: </div>
<div class='ps-5  fs-5'>
- Sinh viên có thể tham gia các công việc trong ngành dịch vụ, bán lẻ hoặc thậm chí thực tập tại các công ty lớn, vừa học vừa làm để tích lũy kinh nghiệm. </div>
<div class='ps-5  fs-5'>
- Học tập và sinh sống tại Úc không chỉ giúp bạn có được nền giáo dục chất lượng mà còn mang lại cơ hội khám phá và phát triển bản thân. Với môi trường thân thiện và nhiều tiềm năng, Úc chắc chắn là một trong những lựa chọn hàng đầu cho các bạn trẻ muốn vươn xa. </div>

`;

export const cam_nang_hanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.han} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
8 lý do bạn nên du học Hàn Quốc
</div>
<div class='ps-5 fs-5'  >
• Hàn Quốc là điểm đến hấp dẫn cho sinh viên quốc tế với nền giáo dục tiên tiến, văn hóa độc đáo và sự phát triển nhanh chóng của các ngành công nghiệp. Dưới đây là 8 lý do hàng đầu mà bạn nên cân nhắc du học Hàn Quốc.
</div>
<div class='fw-bolder fs-1  text-success py-3'>
1. Chất lượng giáo dục cao và chi phí hợp lý </div>
<div class='ps-5 fs-5'  >
• Hệ thống giáo dục tiên tiến: Hàn Quốc có nhiều trường đại học nằm trong top thế giới, với chương trình học đa dạng và được cập nhật thường xuyên. </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt hợp lý: So với các nước phát triển, chi phí sinh hoạt và học phí tại Hàn Quốc dễ chịu hơn, phù hợp với nhiều sinh viên quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
2. Cơ hội học bổng phong phú </div>
<div class='ps-5 fs-5'>
• Chính phủ Hàn Quốc và các trường đại học cung cấp nhiều học bổng hỗ trợ học phí, sinh hoạt phí và thậm chí hỗ trợ toàn phần cho sinh viên quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
3. Ngành công nghiệp giải trí và công nghệ phát triển mạnh mẽ </div>
<div class='ps-5 fs-5'>
• Hàn Quốc nổi tiếng với K-pop, phim truyền hình và công nghệ tiên tiến. Bạn sẽ có cơ hội học tập và tham gia vào ngành công nghiệp sáng tạo và công nghệ cao. </div>
<div class='fw-bolder fs-1  text-success py-3'>
4. Văn hóa đặc sắc và thân thiện với sinh viên quốc tế </div>
<div class='ps-5 fs-5'>
• Cộng đồng đa dạng: Các trường học và cộng đồng quốc tế tại Hàn Quốc luôn thân thiện và hỗ trợ sinh viên nước ngoài hòa nhập. </div>
<div class='ps-5 fs-5'>
• Hoạt động ngoại khóa đa dạng: Các trường đại học tổ chức nhiều sự kiện, câu lạc bộ và hoạt động giúp sinh viên gắn kết và giao lưu. </div>
<div class='fw-bolder fs-1  text-success py-3'>
5. Hệ thống cơ sở vật chất hiện đại </div>
<div class='ps-5  fs-5'>
• Các trường đại học ở Hàn Quốc có cơ sở vật chất hiện đại, phục vụ tốt cho việc học tập, nghiên cứu và sinh hoạt của sinh viên. </div>
<div class='fw-bolder fs-1  text-success py-3'>
6. Visa du học dễ dàng </div>
<div class='ps-5 fs-5'>
• Chính phủ Hàn Quốc đơn giản hóa thủ tục visa cho sinh viên quốc tế, tạo điều kiện thuận lợi cho các bạn trẻ từ khắp nơi trên thế giới đến học tập. </div>
<div class='fw-bolder fs-1  text-success py-3'>
7. Cộng đồng du học sinh sôi động </div>
<div class='ps-5 fs-5'>
• Hàn Quốc có cộng đồng du học sinh lớn từ nhiều quốc gia, là nơi bạn có thể tìm kiếm sự hỗ trợ và kết bạn quốc tế. </div>
<div class='fw-bolder fs-1  text-success py-3'>
8. Cơ hội việc làm sau khi tốt nghiệp </div>
<div class='ps-5 fs-5'>
• Với nền kinh tế phát triển, sinh viên quốc tế sau khi tốt nghiệp có thể dễ dàng tìm việc làm tại Hàn Quốc, đặc biệt trong các ngành công nghệ, giải trí và giáo dục.
</div>
<div class='fw-bolder fs-1  text-success py-3'>
9. Kết luận  </div>
<div class='ps-5 fs-5'>
• Du học Hàn Quốc là một hành trình đáng trải nghiệm với môi trường học tập chất lượng và văn hóa độc đáo. Hãy cân nhắc các cơ hội học tập tại đây để mở rộng tầm nhìn và phát triển tương lai của bạn.
</div>

`;
export const cam_nang_mientrung = `
<div class='fw-semibold py-3'>
Top 10 bãi biển đẹp nhất miền Trung
</div>
<div class='pb-3' >
Miền Trung Việt Nam là điểm đến lý tưởng với những bãi biển hoang sơ, cát trắng và nước biển trong xanh. Dưới đây là 10 bãi biển đẹp nhất miền Trung mà bạn nên ghé thăm:
</div>
<div class='pb-3 fw-bolder' >
1. Bãi biển Mỹ Khê – Đà Nẵng
</div>
<div class='pb-3' >
Mỹ Khê luôn nằm trong danh sách những bãi biển đẹp nhất hành tinh do Forbes bình chọn. Với làn nước trong vắt, bãi cát mịn và hệ thống tiện ích hiện đại, đây là điểm đến lý tưởng cho cả gia đình và những ai yêu thích thể thao biển.
</div>
<div class='pb-3 fw-bolder'>
2. Bãi biển Non Nước – Đà Nẵng
</div>
<div class='pb-3' >
Không xa Mỹ Khê, Non Nước sở hữu không gian yên tĩnh, phong cảnh hữu tình và là nơi lý tưởng để thư giãn. Bạn có thể trải nghiệm các dịch vụ du thuyền và lướt ván ở đây.
</div>
<div class='pb-3 fw-bolder'>
3. Cù Lao Chàm – Quảng Nam
</div>
<div class='pb-3' >
Với hệ sinh thái biển đa dạng và phong cảnh tuyệt đẹp, Cù Lao Chàm là một điểm đến lý tưởng cho những ai thích khám phá thiên nhiên. Bạn có thể lặn ngắm san hô và thưởng thức hải sản tươi ngon.
</div>
<div class='pb-3 fw-bolder'>
4. Bãi biển An Bàng – Hội An
</div>
<div class='pb-3' >
An Bàng giữ được vẻ đẹp hoang sơ với bãi cát trắng mịn và biển xanh mát. Các quán cafe và nhà hàng ven biển ở đây cũng có không gian thư giãn lý tưởng.
</div>
<div class='pb-3 fw-bolder'>
5. Bãi Xép – Phú Yên
</div>
<div class='pb-3' >
Bãi Xép được chọn làm bối cảnh trong bộ phim "Tôi thấy hoa vàng trên cỏ xanh" với cảnh sắc thiên nhiên hùng vĩ. Đứng trên đồi cát, bạn có thể ngắm nhìn toàn cảnh biển xanh biếc trải dài bất tận.
</div>
<div class='pb-3 fw-bolder'>
6. Bãi biển Lăng Cô – Huế
</div>
<div class='pb-3' >
Lăng Cô là một trong những bãi biển đẹp nhất Việt Nam, nơi có cát trắng, nước biển trong và hệ sinh thái phong phú. Bãi biển này nằm gần nhiều địa điểm du lịch nổi tiếng như Vườn quốc gia Bạch Mã.
</div>
<div class='pb-3 fw-bolder'>
7. Bãi Dài – Khánh Hòa
</div>
<div class='pb-3' >
Bãi Dài mang vẻ đẹp yên bình với bờ cát dài, nước biển xanh và không gian hoang sơ. Đây cũng là điểm đến lý tưởng để cắm trại và ngắm hoàng hôn.
</div>
<div class='pb-3 fw-bolder'>
8. Bãi biển Nha Trang – Khánh Hòa
</div>
<div class='pb-3' >
Nha Trang là trung tâm du lịch biển lớn của miền Trung với các bãi biển nổi tiếng. Bạn có thể tham gia các hoạt động như lặn biển, chèo thuyền kayak và khám phá các đảo quanh đây.
</div>
<div class='pb-3 fw-bolder'>
9. Bãi biển Thiên Cầm – Hà Tĩnh
</div>
<div class='pb-3' >
Thiên Cầm mang vẻ đẹp dịu dàng và yên bình, ít bị tác động bởi du lịch nên vẫn giữ được nét hoang sơ. Đây là nơi lý tưởng để nghỉ dưỡng và tận hưởng không khí trong lành.
</div>
<div class='pb-3 fw-bolder'>
10. Bãi biển Cửa Đại – Quảng Nam
</div>
<div class='pb-3' >
Cửa Đại nổi bật với cảnh biển trong xanh và các dải cát trắng dài. Đây là nơi thích hợp để dạo chơi, ngắm hoàng hôn và thưởng thức các món hải sản tươi ngon.
</div>
`;
export const cam_nang_phuquoc = `
<div class='fw-semibold py-3'>
Review Du lịch Phú Quốc
</div>
<div class='pb-3' >
Phú Quốc, đảo ngọc của Việt Nam, không chỉ là thiên đường nhiệt đới với biển xanh, cát trắng mà còn mang đến vô vàn trải nghiệm thú vị từ thiên nhiên, văn hóa đến ẩm thực phong phú. Dưới đây là hướng dẫn chi tiết giúp bạn có một kỳ nghỉ trọn vẹn và ý nghĩa khi đến với Phú Quốc
</div>
<div class='pb-3 fw-bolder'>
1. Thời điểm lý tưởng để đến Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc có khí hậu nhiệt đới ẩm với hai mùa rõ rệt:
</div>
<div class='pb-3 '>
Mùa khô (từ tháng 11 đến tháng 4): Đây là mùa cao điểm du lịch Phú Quốc vì thời tiết nắng ráo, biển trong xanh, ít mưa, thuận lợi cho các hoạt động ngoài trời.
</div>
<div class='pb-3 '>
Mùa mưa (từ tháng 5 đến tháng 10): Phú Quốc vào mùa mưa sẽ có những cơn mưa lớn kéo dài, đôi khi biển động. Tuy nhiên, nếu thích sự yên tĩnh, tránh đông đúc và muốn tiết kiệm chi phí, bạn có thể lựa chọn thời điểm này để đến đảo.
</div>
<div class='pb-3 fw-bolder'>
2. Các điểm tham quan nổi bật tại Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc sở hữu nhiều điểm đến đẹp và hấp dẫn, từ những bãi biển hoang sơ, làng chài truyền thống đến các khu vui chơi hiện đại:
</div>
<div class='pb-3 '>
• Bãi Sao: Được mệnh danh là bãi biển đẹp nhất Phú Quốc với cát trắng mịn màng và làn nước trong xanh. Đây là nơi lý tưởng để tắm biển, chụp ảnh sống ảo, và thư giãn. Bạn có thể thưởng thức nước dừa tươi và thuê ghế ngồi dọc bãi biển.
</div>
<div class='pb-3 '>
• Bãi Khem: Cách thị trấn Dương Đông khoảng 25km, Bãi Khem là nơi lý tưởng để tận hưởng vẻ đẹp hoang sơ và yên bình. Nước biển ở đây trong veo và bãi cát trắng, thích hợp cho các hoạt động tắm biển và lặn ngắm san hô.
</div>
<div class='pb-3 '>
• VinWonders Phú Quốc: Là khu vui chơi giải trí lớn nhất Việt Nam với đa dạng trò chơi từ công viên nước, khu vui chơi trong nhà đến rạp chiếu phim 4D. VinWonders thích hợp cho các gia đình, nhóm bạn trẻ muốn tận hưởng ngày vui chơi sôi động và nhiều hoạt động giải trí phong phú.
</div>
<div class='pb-3 '>
• Vinpearl Safari: Là vườn thú bán hoang dã đầu tiên tại Việt Nam với hàng trăm loài động vật từ khắp nơi trên thế giới. Vinpearl Safari là nơi lý tưởng để khám phá và trải nghiệm gần gũi với thiên nhiên.
</div>
<div class='pb-3 '>
• Làng chài Hàm Ninh: Đây là làng chài truyền thống lâu đời tại Phú Quốc, nổi tiếng với vẻ đẹp giản dị và bình yên. Tại đây, bạn có thể thưởng thức hải sản tươi ngon và ngắm cảnh hoàng hôn tuyệt đẹp trên biển.
</div>
<div class='pb-3 '>
• Hòn Thơm: Bạn có thể đi cáp treo vượt biển từ Phú Quốc đến Hòn Thơm để khám phá thiên đường san hô, lặn ngắm cá và san hô tự nhiên. Hòn Thơm còn có công viên nước và nhiều trò chơi mạo hiểm hấp dẫn.
</div>
<div class='pb-3 fw-bolder'>
3. Trải nghiệm ẩm thực độc đáo
</div>
<div class='pb-3 '>
Phú Quốc là thiên đường ẩm thực với nhiều món hải sản và đặc sản độc đáo. Dưới đây là những món ăn bạn không nên bỏ qua:
</div>
<div class='pb-3 '>
• Nhum biển: Nhum thường được nướng mỡ hành hoặc hấp, có hương vị béo ngậy và mùi thơm đặc trưng. Đây là món ăn nhiều dinh dưỡng, có thể ăn sống kèm mù tạt và chanh.
</div>
<div class='pb-3 '>
• Ghẹ Hàm Ninh: Ghẹ ở làng chài Hàm Ninh nhỏ nhưng thịt ngọt và chắc. Ghẹ có thể hấp, rang muối, hoặc nướng tùy theo sở thích, kèm theo nước chấm muối tiêu chanh rất ngon.
</div>
<div class='pb-3 '>
• Gỏi cá trích: Đây là món ăn đặc trưng của Phú Quốc, cá trích tươi được làm sạch, trộn cùng hành, rau sống, dừa nạo và đậu phộng rang. Khi ăn, bạn cuốn gỏi với bánh tráng và chấm nước mắm Phú Quốc đậm đà.
</div>
<div class='pb-3 '>
• Bún kèn: Là món ăn sáng nổi tiếng tại Phú Quốc, bún kèn được chế biến từ cá nhồng hoặc cá ngân, nấu với nước cốt dừa, ăn kèm rau sống và giá đỗ. Hương vị bún kèn thơm ngậy, cay nồng, rất đáng thử.
</div>
<div class='pb-3 fw-bolder'>
4. Địa điểm lưu trú tại Phú Quốc
</div>
<div class='pb-3 '>
Phú Quốc có nhiều loại hình lưu trú từ khách sạn, resort cao cấp đến các homestay bình dân. Dưới đây là một số gợi ý:
</div>
<div class='pb-3 '>
• Resort cao cấp: JW Marriott Phú Quốc, InterContinental Phú Quốc Long Beach Resort.
</div>
<div class='pb-3 '>
• Khách sạn tầm trung: Seashells Phú Quốc Hotel & Spa, Salinda Resort.
</div>
<div class='pb-3 '>
• Homestay: D'Hotel, 9Station Hostel.
</div>
<div class='pb-3 '>
Kết luận
</div>
<div class='pb-3 '>
Phú Quốc là thiên đường nghỉ dưỡng, thích hợp cho kỳ nghỉ ngắn hoặc dài. Với các bãi biển tuyệt đẹp, nhiều điểm vui chơi và nền ẩm thực phong phú, Phú Quốc chắc chắn sẽ mang đến trải nghiệm tuyệt vời cho bất kỳ ai ghé thăm.
</div>
`;
export const cam_nang_hoian = `
<div class='fw-semibold py-3'>
5 món ăn bạn không thể bỏ qua khi đến Hội An
</div>
<div class='pb-3 '>
Hội An không chỉ nổi tiếng với phố cổ và cảnh quan thơ mộng mà còn sở hữu nền ẩm thực phong phú, đặc trưng với những món ăn truyền thống hấp dẫn. Dưới đây là 5 món ăn đặc trưng bạn nhất định phải thử khi đến Hội An.
</div>
<div class='pb-3  fw-bolder'>
1. Cao lầu
</div>
<div class='pb-3 '>
Cao lầu là món mì truyền thống chỉ có tại Hội An, được làm từ sợi mì đặc biệt dai và vàng óng nhờ sử dụng nước giếng Bá Lễ và tro từ cây tràm. Mì ăn kèm với thịt heo, rau sống, bánh tráng và nước sốt đậm đà. Cao lầu mang hương vị độc đáo, vừa giòn của bánh tráng, vừa ngọt của thịt heo và mát lạnh từ rau sống.
</div>
<div class='pb-3  fw-bolder'>
2. Bánh mì Phượng
</div>
<div class='pb-3 '>
Bánh mì Phượng nổi tiếng quốc tế và đã từng được nhiều tạp chí ẩm thực khen ngợi. Bánh mì Hội An đặc biệt nhờ lớp vỏ giòn tan, bên trong là nhân pate, thịt nguội, chả, dưa leo, và rau thơm, tạo nên hương vị thơm ngon, khó quên. Bạn có thể tìm thấy bánh mì Phượng tại nhiều cửa hàng nhỏ, hoặc thử bánh mì gánh của các cô bán hàng rong quanh phố cổ.
</div>
<div class='pb-3  fw-bolder'>
3. Bánh bao – bánh vạc
</div>
<div class='pb-3 '>
Bánh bao và bánh vạc là hai loại bánh làm từ bột gạo mềm mịn, hấp dẫn và độc đáo. Bánh bao có nhân tôm, thịt, hành, bánh vạc được làm từ tôm giã nhuyễn với hành tây và tiêu. Hai món bánh này thường được ăn kèm với nước mắm pha chua ngọt, mang đến hương vị dân dã mà đậm đà.
</div>
<div class='pb-3  fw-bolder'>
4. Cơm gà Hội An
</div>
<div class='pb-3 '>
Cơm gà Hội An là món ăn đơn giản nhưng có hương vị đậm đà và hấp dẫn. Gà được xé nhỏ, trộn với hành tây, rau răm và các loại gia vị, ăn kèm với cơm vàng ươm và nước mắm chua ngọt. Đây là món ăn phổ biến, được phục vụ ở hầu hết các quán ăn, nhà hàng tại Hội An, mang lại trải nghiệm ẩm thực đặc trưng của phố cổ.
</div>
<div class='pb-3  fw-bolder'>
5. Hoành thánh
</div>
<div class='pb-3 '>
Hoành thánh Hội An có nhiều loại, từ hoành thánh chiên, hấp cho đến hoành thánh nước, nhưng nổi tiếng nhất là hoành thánh chiên với nhân tôm thịt, vỏ ngoài giòn rụm. Hoành thánh thường được ăn kèm với nước chấm ngọt chua, thích hợp làm món ăn vặt hoặc khai vị.
</div>
<div class='pb-3 '>
Kết luận
</div>
<div class='pb-3 '>
Đến Hội An, bạn không chỉ được chiêm ngưỡng vẻ đẹp cổ kính mà còn có cơ hội khám phá nền ẩm thực độc đáo. Những món ăn đặc trưng tại đây không chỉ là một phần của trải nghiệm du lịch mà còn là nét văn hóa đặc trưng, giúp bạn hiểu thêm về con người và cuộc sống nơi đây.
</div>
`;
export const tour_sapa = `
<div class=' fs-1' >
Tour Sapa
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.sapa1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.sapa2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.sapa3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.sapa1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.sapa2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.sapa3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Biển mây và phong cảnh hùng vĩ tại Tà Xùa: Từ đỉnh Chiêu Lầu Thi đến sống lưng khủng long Háng Đồng, du khách có cơ hội trải nghiệm cảnh sắc mây núi đan xen, tạo nên không gian thiên nhiên vừa hoang sơ vừa trữ tình.
</div>
 <div class='ps-5 fs-5 '>
• Cây cô đơn giữa trời Tà Xùa: Hình ảnh cây đơn độc giữa khung cảnh rộng lớn và mây phủ nhẹ nhàng là một biểu tượng đặc trưng và cũng là điểm đến không thể bỏ qua.
</div>
<div class='ps-5 fs-5 '>
• Hồ Suối Chiếu trữ tình: Chuyến du thuyền trên mặt hồ xanh biếc cùng khung cảnh non nước xinh đẹp và những ngôi nhà sàn của người dân tộc tạo nên một bức tranh thủy mặc ấn tượng.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm tắm khoáng nóng Ban Mai retreat: Ngâm mình trong suối khoáng nóng giữa núi rừng là trải nghiệm thư giãn giúp du khách phục hồi sức khỏe và tận hưởng không gian thiên nhiên yên bình.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
SAPA
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Fansipan
</div>
<div class='pb-3 fs-5' >
Đỉnh núi cao nhất Đông Dương, Fansipan là "Nóc nhà Đông Dương," mang đến cho du khách cơ hội chinh phục đỉnh cao và chiêm ngưỡng cảnh sắc núi rừng hùng vĩ từ trên cao.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Đèo Ô Quy Hồ 
</div>
<div class='pb-3 fs-5' >
Một trong "tứ đại đỉnh đèo" của Việt Nam, nổi tiếng với khung cảnh tráng lệ của núi rừng Tây Bắc, đặc biệt đẹp vào lúc hoàng hôn và bình minh.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bản Cát Cát
</div>
<div class='pb-3 fs-5' >
Làng cổ của người H'Mông, với các nghề thủ công truyền thống, những ngôi nhà mộc mạc và cánh đồng ruộng bậc thang thơ mộng, là điểm đến lý tưởng để khám phá văn hóa vùng cao.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bản Y Tý
</div>
<div class='pb-3 fs-5' >
Địa điểm nổi tiếng với cảnh biển mây bồng bềnh và ruộng bậc thang đẹp mắt, đặc biệt là vào mùa lúa chín, thu hút du khách thích khám phá và chụp ảnh.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bản Lao Chải - Tả Van
</div>
<div class='pb-3 fs-5' >
Hai bản làng của người H'Mông và người Dáy, nổi bật với vẻ đẹp tự nhiên của những thửa ruộng bậc thang và văn hóa truyền thống đậm chất núi rừng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Bản Mường Hoa
</div>
<div class='pb-3 fs-5' >
Thung lũng nổi tiếng với những bãi đá cổ, khắc họa các hình vẽ bí ẩn cùng với đồng ruộng bậc thang, mang đến phong cảnh thơ mộng cho du khách.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Núi Hàm Rồng
</div>
<div class='pb-3 fs-5' >
Khu du lịch có vị trí đắc địa với vườn hoa đầy sắc màu và các khu vực ngắm nhìn toàn cảnh Sapa từ trên cao, nơi du khách có thể tận hưởng không khí trong lành và phong cảnh hữu tình.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Thác Tình Yêu
</div>
<div class='pb-3 fs-5' >
Thác nước đẹp và nên thơ, nằm giữa rừng cây xanh mát, là nơi lý tưởng cho các cặp đôi hoặc những người yêu thích cảnh thiên nhiên yên bình.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Chợ Bắc Hà
</div>
<div class='pb-3 fs-5' >
Phiên chợ vùng cao lớn nhất ở Lào Cai, nơi bày bán các sản vật đặc trưng và là nơi giao lưu văn hóa độc đáo của các dân tộc thiểu số như H'Mông, Dao, Tày.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hoangsuphi = `
 
<div class=' fs-1 ' >
Tour Hoàng Su Phì
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.suphi1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.suphi2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.suphi3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
 
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.suphi1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.suphi2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.suphi3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Chinh phục đỉnh Chiêu Lầu Thi – Trải nghiệm săn mây độc đáo, đứng trên đỉnh cao 2402m ngắm nhìn núi non và thác mây tuyệt đẹp.
</div>
 <div class='ps-5 fs-5 '>
• Khám phá bản Nậm Hồng (Thông Nguyên) – Chiêm ngưỡng những thửa ruộng bậc thang danh thắng quốc gia và tìm hiểu văn hóa đặc sắc của người Dao đỏ.
</div>
<div class='ps-5 fs-5 '>
• Tham quan Thảo nguyên Suôi Thầu hoặc Bản Phùng – Khung cảnh hùng vĩ, hoang sơ, với những cánh đồng ruộng bậc thang tuyệt đẹp, đậm nét thơ mộng vùng cao.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm chợ Bắc Hà – Hòa mình vào văn hóa đặc trưng của chợ phiên Tây Bắc, giao lưu với người dân tộc và khám phá ẩm thực địa phương.
</div>
<div class='ps-5 fs-5 '>
• Dinh Hoàng A Tưởng – Thăm quan công trình kiến trúc lịch sử, nơi lưu giữ giá trị văn hóa độc đáo của người H'Mông từ thời Pháp thuộc.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hoàng Su Phì
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Bản Nậm Hồng
</div>
<div class='pb-3 fs-5' >
Làng của người Dao đỏ nổi tiếng với ruộng bậc thang trải dài trên sườn đồi và chè Shan tuyết cổ thụ, tạo nên một bức tranh thiên nhiên xanh mướt và bình dị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chiêu Lầu Thi
</div>
<div class='pb-3 fs-5' >
Đỉnh núi cao 2.402m, nơi lý tưởng để săn mây, chiêm ngưỡng những dòng mây trắng mềm mại vờn quanh núi, mang đến trải nghiệm ngoạn mục và đáng nhớ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bản Phùng
</div>
<div class='pb-3 fs-5' >
Trái tim của Hoàng Su Phì với cảnh quan ruộng bậc thang tầng tầng lớp lớp, ôm ấp mái nhà dân tộc trong làn mây sớm, tạo nên vẻ đẹp thơ mộng không nơi nào sánh được.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thảo nguyên Suôi Thầu
</div>
<div class='pb-3 fs-5' >
Địa điểm mới nổi mang vẻ đẹp hoang sơ tựa trời Âu, với thảo nguyên rộng lớn xanh mát, là điểm đến hấp dẫn cho những người yêu thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Chợ Bắc Hà
</div>
<div class='pb-3 fs-5' >
Phiên chợ nổi tiếng và sôi động nhất vùng Tây Bắc, nơi hội tụ đa dạng các mặt hàng thủ công, sản vật và món ngon dân dã, giúp du khách hòa mình vào đời sống và văn hóa dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Dinh Hoàng A Tưởng
</div>
<div class='pb-3 fs-5' >
Công trình kiến trúc độc đáo từ thời Pháp thuộc, nơi ở của vua H'Mông, là một biểu tượng văn hóa, lịch sử quý báu của vùng Bắc Hà.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.suphi27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.suphi24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.suphi26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.suphi27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_taxua = `
 
<div class=' fs-1 ' >
Tour Tà Xùa
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.taxua1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.taxua2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.taxua3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.taxua1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.taxua2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.taxua3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Đồi chè Thanh Sơn (Phú Thọ) - Đồi chè Thanh Sơn xanh mướt trải dài tạo nên không gian trong lành, yên bình, mang đến cho du khách những giây phút thư giãn giữa thiên nhiên mát dịu.
</div>
 <div class='ps-5 fs-5 '>
• Cây cô đơn (Tà Xùa) - Cây cô đơn đứng lẻ loi giữa vùng núi mây Tà Xùa là điểm dừng chân lý tưởng, nổi bật với vẻ đẹp lãng mạn và là nơi cho ra những bức ảnh độc đáo.
</div>
<div class='ps-5 fs-5 '>
• Mỏm cá heo - Với hình dáng nhô ra độc đáo như cá heo, mỏm đá này là điểm check-in lý tưởng, giúp du khách có cảm giác như đang lơ lửng giữa biển mây.
</div>
<div class='ps-5 fs-5 '>
• Đỉnh Gió - Đỉnh Gió là điểm săn mây và ngắm hoàng hôn đẹp nhất tại Tà Xùa, nơi du khách được chiêm ngưỡng cảnh sắc mây núi hùng vĩ trong ánh chiều tà.

</div>
<div class='ps-5 fs-5 '>
• Sống lưng khủng long (Háng Đồng) - Nổi bật với con đường đèo cheo leo và biển mây trải dài, sống lưng khủng long tại Háng Đồng là điểm đến không thể bỏ lỡ trong hành trình khám phá Tà Xùa.

</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Tà Xùa
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đồi chè Thanh Sơn
</div>
<div class='pb-3 fs-5' >
Đồi chè xanh mướt tại Thanh Sơn là điểm dừng chân mang lại cảm giác trong lành, giúp du khách thư giãn giữa phong cảnh yên bình của đồi chè trải dài.
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cây cô đơn
</div>
<div class='pb-3 fs-5' >
"Cây cô đơn" nổi tiếng đứng lẻ loi giữa vùng mây núi Tà Xùa, là điểm đến hấp dẫn cho du khách muốn khám phá vẻ đẹp lãng mạn và chụp ảnh độc đáo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Mỏm cá heo
</div>
<div class='pb-3 fs-5' >
Mỏm đá hình cá heo nhô ra giữa trời tạo nên một điểm check-in cực chất, thu hút giới trẻ đến trải nghiệm cảm giác lơ lửng giữa biển mây huyền ảo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đỉnh Gió
</div>
<div class='pb-3 fs-5' >
Điểm săn mây lý tưởng, ngắm hoàng hôn đẹp nhất tại Tà Xùa. Từ đây, du khách có thể thấy toàn cảnh mây núi hùng vĩ khi trời chiều buông.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Sống lưng khủng long (Háng Đồng)
</div>
<div class='pb-3 fs-5' >
Với đường đèo hùng vĩ và biển mây ngút ngàn, sống lưng khủng long tại Háng Đồng là điểm nhấn đặc biệt trong hành trình khám phá Tà Xùa.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Hồ Suối Chiếu
</div>
<div class='pb-3 fs-5' >
Hồ nước xanh biếc, nơi du khách được ngắm cảnh và trải nghiệm du thuyền lòng hồ. Với vẻ đẹp yên bình, đây là điểm đến lý tưởng để thư giãn giữa thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.taxua27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.taxua24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.taxua26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.taxua27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_mucangchai = `
 
<div class=' fs-1 ' >
Tour Mù Cang Chải
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.cangchai1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.cangchai2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.cangchai3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.cangchai1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.cangchai2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.cangchai3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Chinh phục đèo Khau Phạ - Trải nghiệm hành trình đầy kịch tính qua đèo Khau Phạ, một trong "tứ đại đèo" của miền Bắc, với tầm nhìn ngoạn mục ra thung lũng lúa chín vàng.
</div>
 <div class='ps-5 fs-5 '>
• Khám phá ruộng bậc thang Mâm Xôi - Check-in tại ruộng bậc thang Mâm Xôi, nơi sở hữu cảnh quan thiên nhiên tuyệt đẹp, được ví như mâm xôi vàng óng vào mùa thu hoạch.
</div>
<div class='ps-5 fs-5 '>
• Thưởng thức đặc sản Tây Bắc - Tham gia bữa tối tại nhà hàng địa phương với những món ăn đặc trưng của vùng Tây Bắc, đem lại trải nghiệm ẩm thực độc đáo cho du khách.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm văn hóa bản làng - Tham quan bản người Thái và chợ Mù Cang Chải, nơi du khách có thể tìm hiểu đời sống địa phương và mua sắm các sản phẩm thủ công mỹ nghệ.
</div>
<div class='ps-5 fs-5 '>
• Ngắm cảnh thiên nhiên hùng vĩ - Thỏa sức đắm chìm trong khung cảnh thiên nhiên tuyệt đẹp của Mù Cang Chải, từ những cánh đồng lúa xanh mướt đến những dãy núi trùng điệp.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Mù Cang Chải
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đèo Khau Phạ
</div>
<div class='pb-3 fs-5' >
Đèo Khau Phạ, một trong tứ đại đèo nổi tiếng của miền Bắc, mang đến cảnh sắc hùng vĩ và những khúc cua hiểm trở tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Thung Lũng Lìm Mông
</div>
<div class='pb-3 fs-5' >
Thung lũng Lìm Mông nổi bật với những ruộng bậc thang uốn lượn, tạo nên cảnh quan thiên nhiên tươi đẹp, bình yên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Xã Tú Lệ
</div>
<div class='pb-3 fs-5' >
Xã Tú Lệ nổi tiếng với những cánh đồng lúa bậc thang tuyệt đẹp và văn hóa đặc sắc của các dân tộc Mông, Thái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đồi Mâm Xôi
</div>
<div class='pb-3 fs-5' >
Đồi Mâm Xôi là một đỉnh núi có hình dáng độc đáo như chiếc mâm xôi khổng lồ, tạo nên cảnh quan hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Đồi Móng Ngựa
</div>
<div class='pb-3 fs-5' >
Đồi Móng Ngựa có hình dáng giống chiếc móng ngựa khổng lồ, thu hút du khách với vẻ đẹp hoang sơ và hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Rừng Trúc Bản Nả Háng Túa
</div>
<div class='pb-3 fs-5' >
Rừng trúc Bản Nả Háng Túa là khu rừng trúc nguyên sinh tĩnh lặng, huyền bí, lý tưởng cho khám phá thiên nhiên hoang dã.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Suối Khoáng Nóng Trạm Tấu
</div>
<div class='pb-3 fs-5' >
Suối khoáng nóng Trạm Tấu là địa điểm thư giãn nổi tiếng với nước khoáng tự nhiên và cảnh sắc thiên nhiên tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cangchai31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cangchai28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cangchai30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cangchai31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hagiang = `
 
<div class=' fs-1 ' >
Tour Hà Giang
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hagiang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hagiang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hagiang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hagiang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hagiang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hagiang3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám phá Cổng Trời Quản Bạ: Ngắm nhìn toàn cảnh Núi đôi Cô Tiên và thị trấn Tam Sơn từ độ cao ấn tượng.
</div>
 <div class='ps-5 fs-5 '>
• Chinh phục Cao nguyên đá Đồng Văn: Chiêm ngưỡng vẻ đẹp hoang sơ và hùng vĩ của công viên địa chất toàn cầu, cùng với những ngôi nhà cổ của người H'mông.
</div>
<div class='ps-5 fs-5 '>
• Thăm Cột Cờ Lũng Cú: Đứng tại điểm cực Bắc của Tổ quốc, nơi mang lại cảm giác tự hào về đất nước.
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm Hẻm Tu Sản và du thuyền trên Sông Nho Quế: Thưởng ngoạn cảnh quan hùng vĩ của hẻm vực sâu nhất Việt Nam cùng dòng sông Nho Quế trong xanh.
</div>
<div class='ps-5 fs-5 '>
• Khám phá Chợ phiên Đồng Văn: Hòa mình vào không khí sôi động, tìm hiểu văn hóa địa phương và mua sắm các sản phẩm độc đáo từ vùng cao.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hà Giang
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Cột mốc số 0 TP. Hà Giang
</div>
<div class='pb-3 fs-5' >
Điểm khởi đầu của hành trình khám phá vùng núi phía Bắc, nơi du khách có thể chụp hình và ghi dấu ấn trên bản đồ Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cổng Trời Quản Bạ
</div>
<div class='pb-3 fs-5' >
Nằm ở độ cao lý tưởng, từ đây bạn có thể ngắm nhìn toàn cảnh thị trấn Tam Sơn và chiêm ngưỡng vẻ đẹp của Núi đôi Cô Tiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Cột Cờ Lũng Cú
</div>
<div class='pb-3 fs-5' >
Điểm cực Bắc của Tổ quốc, nơi du khách có thể chụp hình và cảm nhận niềm tự hào dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Đèo Mã Pì Lèng
</div>
<div class='pb-3 fs-5' >
Một trong những con đèo đẹp nhất Việt Nam, nối liền giữa Đồng Văn và Mèo Vạc, với cảnh quan thiên nhiên hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hẻm Tu Sản và Sông Nho Quế
</div>
<div class='pb-3 fs-5' >
Khám phá hẻm vực sâu nhất của Việt Nam, nơi có dòng sông Nho Quế chảy qua với khung cảnh tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chợ phiên Đồng Văn
</div>
<div class='pb-3 fs-5' >
Trải nghiệm không khí mua sắm sôi động tại chợ phiên, nơi quy tụ nhiều sản phẩm địa phương và văn hóa của người dân tộc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Phố Cổ Đồng Văn
</div>
<div class='pb-3 fs-5' >
Dạo chơi và thưởng thức cà phê tại phố cổ, nơi lưu giữ nhiều giá trị văn hóa và lịch sử của vùng cao Hà Giang.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hagiang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hagiang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hagiang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hagiang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_ninhbinh = `
 
<div class=' fs-1 ' >
Tour Ninh Bình
</div>
 
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.ninhbinh1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.ninhbinh2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.ninhbinh3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.ninhbinh1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.ninhbinh2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.ninhbinh3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Quần Thể Danh Thắng Tràng An: Chìm đắm trong cảnh quan thiên nhiên kỳ vĩ với núi non hùng vĩ và các hang động huyền bí, được UNESCO công nhận là Di sản thế giới.
</div>
 <div class='ps-5 fs-5 '>
• Tham Quan Chùa Bái Đính: Một trong những ngôi chùa lớn nhất Việt Nam, nổi bật với kiến trúc độc đáo và các tượng Phật hoành tráng, mang đậm giá trị văn hóa và tâm linh.
</div>
<div class='ps-5 fs-5 '>
• Viếng Cố Đô Hoa Lư: Tìm hiểu lịch sử Việt Nam tại di tích lịch sử quan trọng này, nơi có các đền thờ các vua Đinh, Lê và những dấu ấn văn hóa của triều đại đầu tiên.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Đi Thuyền Ở Tam Cốc: Chiêm ngưỡng vẻ đẹp tuyệt mỹ của "Vịnh Hạ Long trên cạn" với những cánh đồng xanh mướt và hang động tự nhiên trong hành trình đi thuyền dọc theo sông Ngô Đồng.
</div>
<div class='ps-5 fs-5 '>
• Chinh Phục Hang Múa: Leo lên đỉnh núi Múa để ngắm toàn cảnh Tam Cốc và tận hưởng không khí trong lành, cùng với cơ hội chụp những bức hình đẹp tuyệt vời.
</div>
</div>
 
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Ninh Bình
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Quần thể danh thắng Tràng An
</div>
<div class='pb-3 fs-5' >
Nơi có cảnh quan thiên nhiên tuyệt đẹp với những dãy núi đá vôi, hệ thống hang động phong phú và dòng sông trong xanh, được UNESCO công nhận là Di sản thế giới.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Bái Đính
</div>
<div class='pb-3 fs-5' >
Là một trong những ngôi chùa lớn nhất Việt Nam, nổi tiếng với bức tượng Phật Thích Ca bằng đồng nặng 100 tấn và kiến trúc độc đáo. Chùa Bái Đính còn thu hút du khách với các nghi lễ và lễ hội lớn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Cố đô Hoa Lư
</div>
<div class='pb-3 fs-5' >
Di tích lịch sử quan trọng với các đền thờ vua Đinh Tiên Hoàng và Lê Đại Hành, thể hiện giá trị văn hóa, lịch sử của triều đại đầu tiên trong lịch sử Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Tam Cốc - Bích Động
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "Vịnh Hạ Long trên cạn," nơi có cảnh quan tuyệt đẹp với những cánh đồng xanh, núi đá vôi, và những dòng sông uốn lượn. Du khách có thể tham gia trải nghiệm đi thuyền ngắm cảnh và khám phá các hang động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hang Múa
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với những bậc thang đá dẫn lên đỉnh núi, từ đây du khách có thể chiêm ngưỡng toàn cảnh Tam Cốc và những cánh đồng lúa chín vàng. Đây cũng là một địa điểm lý tưởng cho những bức ảnh sống động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.ninhbinh23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.ninhbinh20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.ninhbinh22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.ninhbinh23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_quangbinh = `
<div class=' fs-1 ' >
Tour Quảng Bình
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.quangbinh1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.quangbinh2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.quangbinh3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.quangbinh1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.quangbinh2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.quangbinh3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Hang Sơn Đoòng: Trải nghiệm khám phá hang động lớn nhất thế giới, nơi bạn sẽ được chiêm ngưỡng vẻ đẹp hùng vĩ và độc đáo của thiên nhiên cùng hệ sinh thái phong phú.
</div>
 <div class='ps-5 fs-5  '>
• Vườn Quốc Gia Phong Nha - Kẻ Bàng: Tham quan di sản thiên nhiên thế giới với những hang động nổi tiếng, trekking qua rừng nguyên sinh và tận hưởng vẻ đẹp hoang sơ của núi rừng.
</div>
<div class='ps-5 fs-5  '>
• Chìm Đắm Tại Bãi Biển Nhật Lệ: Thư giãn trên bãi biển cát trắng mịn, tắm biển và tham gia các hoạt động thể thao nước thú vị, hòa mình vào không gian biển xanh.
</div>
<div class='ps-5 fs-5  '>
• Tham Quan Thành Phố Đồng Hới: Khám phá những di tích lịch sử, văn hóa tại thành phố trung tâm, như Đồn Hải Thành và Nhà thờ Tam Tòa, đồng thời thưởng thức ẩm thực địa phương.
</div>
<div class='ps-5 fs-5  '>
• Viếng Đền Thánh Cha Quang: Trải nghiệm không gian tôn nghiêm và thanh tịnh tại đền thờ Cha Quang, nơi có kiến trúc đẹp mắt và giá trị văn hóa đặc sắc, thu hút du khách tìm hiểu về tín ngưỡng địa phương.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Quảng Bình
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Hang Sơn Đoòng
</div>
<div class='pb-3 fs-5' >
Được coi là hang động lớn nhất thế giới, Sơn Đoòng nổi tiếng với vẻ đẹp hoang sơ và hệ sinh thái đa dạng. Du khách có thể tham gia tour khám phá hang động để trải nghiệm không gian kỳ diệu này.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Vườn Quốc Gia Phong Nha - Kẻ Bàng
</div>
<div class='pb-3 fs-5' >
Di sản thiên nhiên thế giới với nhiều hang động nổi tiếng, như Hang Phong Nha và Hang Tiên Sơn. Nơi đây cũng là nơi lý tưởng để trekking, cắm trại và chiêm ngưỡng vẻ đẹp của rừng núi.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Bãi Biển Nhật Lệ
</div>
<div class='pb-3 fs-5' >
Với bãi cát trắng mịn và làn nước trong xanh, Nhật Lệ là điểm đến lý tưởng cho những ai muốn thư giãn và tắm biển. Nơi đây cũng có nhiều hoạt động thể thao nước thú vị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thành phố Đồng Hới
</div>
<div class='pb-3 fs-5' >
Là trung tâm kinh tế, văn hóa của Quảng Bình, Đồng Hới có nhiều di tích lịch sử như Đồn Hải Thành, Nhà thờ Tam Tòa, cùng các khu chợ địa phương sôi động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangbinh19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangbinh16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangbinh18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangbinh19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 

 
`;
export const tour_quangdongquangtay = `
<div class=' fs-1' >
Tour Quảng Đông - Quảng Tây
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.quangdong1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.quangdong2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.quangdong3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.quangdong1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.quangdong2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.quangdong3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• Thăm Lệ Ba - Di sản thiên nhiên Thế Giới
</div>
 <div class='ps-5 fs-5'>
• Thăm Tiểu Thất Khổng - được mệnh danh là “Cửu Trại Câu”của Quý Châu
</div>
<div class='ps-5 fs-5'>
• Thăm Thiên Hộ Miêu Trại - Làng dân tộc Miêu lớn nhất Trung Quốc
</div>
<div class='ps-5 fs-5 '>
• Quý khách sẽ được trải nghiệm hành trình hơn 800km bằng tàu cao tốc của Trung Quốc, có đoạn tàu chạy đến 350 km/giờ, trên đường quý khách vừa thoải mái vừa được ngắm nhìn sơn thủy hữu tình của đất nước TQ từ đường sắt cao tốc trên cao
</div>

</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Quảng Đông - Quảng Tây
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Thành phố Bằng Tường
</div>
<div class='pb-3 fs-5' >
Nằm ở tỉnh Quảng Tây, Trung Quốc, Bằng Tường là một thành phố phát triển với nhiều khu vực thương mại và dịch vụ. Nơi đây nổi bật với văn hóa và ẩm thực địa phương, cũng như các điểm tham quan tự nhiên gần kề.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangdong4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.quangdong7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.quangdong4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangdong5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.quangdong6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.quangdong7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Nam Ninh
</div>
<div class='pb-3 fs-5' >
Thủ phủ của tỉnh Quảng Tây, Trung Quốc, Nam Ninh nổi tiếng với phong cảnh thiên nhiên tươi đẹp, các công trình kiến trúc hiện đại và ẩm thực phong phú. Thành phố còn là trung tâm giao thương quan trọng giữa Việt Nam và Trung Quốc.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Phố cổ Lệ Ba
</div>
<div class='pb-3 fs-5' >
Là một trong những khu phố cổ nổi tiếng ở Trung Quốc, Lệ Ba thu hút du khách với kiến trúc truyền thống, đường phố nhỏ nhắn và các cửa hàng thủ công mỹ nghệ. Buổi tối, phố cổ trở nên sống động với ánh đèn và các hoạt động văn hóa.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Tiểu Thất Khổng
</div>
<div class='pb-3 fs-5' >
Là di sản thiên nhiên thế giới được UNESCO công nhận, Tiểu Thất Khổng có cảnh quan hùng vĩ với những thác nước đẹp mắt và những cây cầu đá cổ kính. Đây là điểm đến lý tưởng cho những ai yêu thích thiên nhiên và khám phá.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Trấn Viễn Cổ Trấn
</div>
<div class='pb-3 fs-5' >
Là một trấn cổ của người Miêu, nổi tiếng với cảnh đẹp ven sông và những cây cầu truyền thống. Trấn Viễn cũng là nơi lưu giữ nhiều giá trị văn hóa, với các hoạt động như thả hoa đăng trên sông.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Sông Vũ Dương
</div>
<div class='pb-3 fs-5' >
Dòng sông này nổi tiếng với vẻ đẹp thơ mộng và không gian yên bình, là nơi lý tưởng cho các hoạt động du thuyền. Khung cảnh hai bên bờ sông rất quyến rũ, mang đến cho du khách cảm giác thư giãn tuyệt vời.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thiên Hộ Miêu Trại:
</div>
<div class='pb-3 fs-5' >
Là một làng truyền thống của người Miêu, nơi du khách có thể khám phá văn hóa và phong tục tập quán của dân tộc này. Tại đây, bạn cũng có thể tham gia vào các hoạt động nghệ thuật và thưởng thức ẩm thực đặc sản.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.sapa28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.sapa30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.sapa31} class='rounded-3' width='49%' height='220'  />
</div>
</div>



`;
export const tour_bangkok = `
<div class=' fs-1' >
Tour Lệ Giang - Shangri-La
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.legiang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.legiang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.legiang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.legiang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.legiang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.legiang3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• 03 đêm Lệ Giang
</div>
 <div class='ps-5 fs-5'>
• TẶNG vé xem show diễn Ấn Tượng Lệ Giang
</div>
<div class='ps-5 fs-5'>
• Bay thẳng hàng không Bamboo Airways chỉ với 2 tiếng đồng hồ
</div>
<div class='ps-5 fs-5 '>
• Khám phá thành cổ Lệ Giang thơ mộng

</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm Núi tuyết Ngọc Long quanh năm bao phủ bởi tuyết, dưới chân núi tuyết là thung lũng Lam Nguyệt có màu xanh độc đáo, uốn lượn
</div>
<div class='ps-5 fs-5 '>
• Đi tìm “Đường chân trời đã mất” ở Shangri-La, nơi đất và trời hòa vào làm một
</div>
<div class='ps-5 fs-5 '>
• Lịch trình thông minh, tiết kiệm thời gian
</div>



</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Lệ Giang - Shangri-La
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Thành Cổ Lệ Giang

</div>
<div class='pb-3 fs-5' >
Thành Cổ Lệ Giang, được UNESCO công nhận là di sản văn hóa thế giới, có lịch sử hơn 800 năm. Nơi đây nổi bật với kiến trúc truyền thống độc đáo và hệ thống kênh rạch chảy qua từng con phố, tạo nên không gian thơ mộng và thanh bình, thường được mệnh danh là "Venice của phương Đông".
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Công viên Hắc Long Đàm
</div>
<div class='pb-3 fs-5' >
Công Viên Hắc Long Đàm là một địa điểm du lịch nổi tiếng tại Lệ Giang, nơi du khách có thể ngắm nhìn núi tuyết Ngọc Long hùng vĩ và thư giãn bên hồ nước trong xanh. Công viên còn là nơi lý tưởng để khám phá cảnh quan thiên nhiên và tận hưởng không khí trong lành.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Núi Tuyết Ngọc Long

</div>
<div class='pb-3 fs-5' >
Núi Tuyết Ngọc Long cao 5.596 m, là ngọn núi tuyết nổi tiếng ở Vân Nam. Nơi đây có cảnh sắc tuyệt đẹp và băng vĩnh cửu ánh lên màu xanh như ngọc. Núi được xem như một thách thức cho những người leo núi vì sự bí ẩn và hoang sơ của nó.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Thung Lũng Lam Nguyệt

</div>
<div class='pb-3 fs-5' >
Thung Lũng Lam Nguyệt nằm dưới chân núi Tuyết Ngọc Long, nổi bật với cảnh quan hùng vĩ và dòng Bạch Thủy Hà trong xanh. Đây là điểm đến lý tưởng để chụp ảnh và khám phá thiên nhiên tươi đẹp của vùng cao nguyên.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Ngọc Thủy Trại (Làng cổ của tộc người Naxi)

</div>
<div class='pb-3 fs-5' >
Ngọc Thủy Trại là ngôi làng cổ của tộc người Naxi, nơi gìn giữ những nét văn hóa độc đáo. Du khách có thể tìm hiểu về phong tục tập quán của người Naxi và tham quan những hồ nước xếp tầng đẹp mắt trong không gian thanh bình của làng quê.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Tu viện Songzanlin

</div>
<div class='pb-3 fs-5' >
Tu viện Songzanlin là tu viện Phật giáo Mật Tông Tây Tạng lớn nhất ở Vân Nam, xây dựng vào năm 1679. Nơi đây không chỉ nổi bật với kiến trúc giống cung điện Potala mà còn mang đậm không khí linh thiêng và cảnh quan thiên nhiên tuyệt đẹp xung quanh.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang27} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thành cổ DuKe Zong

</div>
<div class='pb-3 fs-5' >
Thành cổ DuKe Zong, có tuổi đời hơn 1.300 năm, là nơi cư trú lâu đời của người Tạng. Nơi đây có hàng trăm ngôi nhà cổ với kiến trúc Tây Tạng độc đáo, giúp du khách hiểu rõ hơn về văn hóa và lối sống của người dân địa phương.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang31} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Vườn Quốc gia Pudacuo

</div>
<div class='pb-3 fs-5' >
Vườn Quốc gia Pudacuo là công viên quốc gia đầu tiên của Trung Quốc, nổi bật với hệ động thực vật phong phú và cảnh quan thiên nhiên tuyệt đẹp. Nơi đây là thiên đường cho những người yêu thích chụp ảnh, với bốn mùa mỗi mùa có vẻ đẹp riêng.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.legiang35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.legiang32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.legiang34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.legiang35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_thanhdo = `
<div class=' fs-1' >
Tour Thành Đô - Trùng Khánh - Cửu Trại Câu
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.thanhdo1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.thanhdo2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.thanhdo3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.thanhdo1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.thanhdo2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.thanhdo3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>

<div class='ps-5 fs-5' >
• Khám Phá Văn Hóa Địa Phương: Hành trình mang đến cơ hội tìm hiểu về văn hóa và lịch sử của Trùng Khánh thông qua các cổ trấn, phố cổ và các di tích lịch sử

</div>
 <div class='ps-5 fs-5'>
• Thiên Nhiên Hùng Vĩ: Du khách sẽ được chiêm ngưỡng vẻ đẹp tuyệt mỹ của các hang động, thác nước và hồ nước trong xanh tại Hồng Nhai Động và Cửu Trại Câu

</div>
<div class='ps-5 fs-5'>
• Ẩm Thực Đặc Sắc: Thưởng thức các món ăn truyền thống độc đáo của khu vực Tứ Xuyên, đặc biệt là các món ăn cay và lẩu nổi tiếng tại Thành Đô

</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Độc Đáo Tại Các Ngõ Phố: Khám phá những ngõ rộng và ngõ hẹp đầy thú vị, nơi du khách có thể tìm thấy những món quà lưu niệm độc đáo và trải nghiệm cuộc sống hàng ngày của người dân địa phương
</div>
<div class='ps-5 fs-5 '>
• Chụp Ảnh Với Biểu Tượng Gấu Trúc: Dừng chân tại Quảng Trường Gấu Trúc để chụp ảnh với những bức tượng gấu trúc đáng yêu, biểu tượng đặc trưng của vùng Tứ Xuyên
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Thành Đô - Trùng Khánh - Cửu Trại Câu
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trùng Khánh


</div>
<div class='pb-3 fs-5' >
Trùng Khánh là một thành phố thuộc tỉnh Tứ Xuyên, Trung Quốc, nổi tiếng với phong cảnh thiên nhiên kỳ vĩ và các điểm du lịch lịch sử. Nơi đây có nhiều di tích văn hóa và cảnh quan thiên nhiên, bao gồm các hang động và khu bảo tồn đa dạng sinh học.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Từ Khí Khẩu Cổ Trấn

</div>
<div class='pb-3 fs-5' >
Từ Khí Khẩu Cổ Trấn là một trong những điểm du lịch nổi tiếng với kiến trúc cổ kính và các con phố nhỏ lát đá. Cổ trấn mang đậm dấu ấn văn hóa của người Trung Quốc, với nhiều cửa hàng truyền thống, quán trà và các món ăn đặc sản địa phương.

</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Hồng Nhai Động


</div>
<div class='pb-3 fs-5' >
Hồng Nhai Động là một trong những hang động lớn và đẹp nhất ở Trùng Khánh. Nơi đây nổi tiếng với những hình thù đá vôi kỳ lạ và các tác phẩm nghệ thuật tự nhiên, tạo ra một không gian huyền bí và hấp dẫn cho du khách.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Phố Cổ Cẩm Lý


</div>
<div class='pb-3 fs-5' >
Phố Cổ Cẩm Lý nổi bật với các ngôi nhà cổ kính và không khí truyền thống, nơi bạn có thể tìm thấy nhiều cửa hàng bán đồ thủ công mỹ nghệ và món ăn địa phương. Phố cổ mang đến cho du khách cảm giác trở về quá khứ với những con đường hẹp và những di tích lịch sử.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Ngõ Rộng


</div>
<div class='pb-3 fs-5' >
Ngõ Rộng là một khu vực với không gian thoáng đãng, được bao quanh bởi các tòa nhà cổ và cây xanh. Đây là nơi lý tưởng để thư giãn, trò chuyện và thưởng thức không khí yên bình của khu phố.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo23} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Ngõ Hẹp


</div>
<div class='pb-3 fs-5' >
Ngõ Hẹp mang lại trải nghiệm khám phá thú vị với những con đường nhỏ hẹp và các cửa hàng đặc trưng. Du khách có thể tìm hiểu về cuộc sống hàng ngày của người dân địa phương và khám phá những điều bất ngờ tại các góc phố.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo27} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Thành Đô


</div>
<div class='pb-3 fs-5' >
Thành Đô là thủ phủ của tỉnh Tứ Xuyên, nổi tiếng với văn hóa ẩm thực phong phú, đặc biệt là món lẩu Tứ Xuyên và các món ăn cay. Đây cũng là nơi có nhiều điểm tham quan nổi bật, như Công viên Nhân dân và Bảo tàng Tứ Xuyên.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo31} class='rounded-3' width='49%' height='220'  />
</div>

</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Cửu Trại Câu
</div>
<div class='pb-3 fs-5' >
Cửu Trại Câu là một trong những điểm đến nổi tiếng nhất ở Trung Quốc với cảnh quan thiên nhiên tuyệt đẹp, hồ nước trong xanh, thác nước hùng vĩ và hệ động thực vật phong phú. Nơi đây được UNESCO công nhận là Di sản Thiên nhiên Thế giới, thu hút hàng triệu du khách mỗi năm.


</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thanhdo35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thanhdo32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thanhdo34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thanhdo35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_han = `
<div class=' fs-1 ' >
Tour Hàn Quốc
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hanquoc1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hanquoc2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hanquoc3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hanquoc1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hanquoc2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hanquoc3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám phá 3 thành phố lớn nhất của Hàn Quốc
</div>
 <div class='ps-5 fs-5'>
• Chùa Haedong Yonggungsa: là 1 trong những ngôi chùa có địa thế đẹp và linh thiêng nhất Hàn Quốc
</div>
<div class='ps-5 fs-5'>
• Khám phá Gamcheon - một Santorini dịu dàng của Hàn Quốc
</div>
<div class='ps-5 fs-5'>
• Trải nghiệm đạp xe trượt trên đường ray qua Cầu Nakdong
</div>
<div class='ps-5 fs-5'>
• Ngắm hòn đảo Nami xinh đẹp với những hàng cây ngân hạnh vàng óng và những cây phong đỏ rực lá
</div>
<div class='ps-5 fs-5'>
• Tặng vé trải nghiệm đi Phà thả mồi cho Hải Âu ăn ngắm hoàng hôn
</div>
<div class='ps-5 fs-5'>
• Tham gia làm kim chi và diện trang phục truyền thống Hàn quốc – Hanbok
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
HÀN QUỐC
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đường hầm rượu vang Phúc Bồn Tử
</div>
<div class='pb-3 fs-5' >
Đường hầm rượu vang Phúc Bồn Tử là nơi bạn có thể thưởng thức rượu vang thơm ngon, lạc vào thế giới ánh sáng với hệ thống đèn LED lung linh trong không gian nghệ thuật.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Cầu Nakdong
</div>
<div class='pb-3 fs-5' >
Cầu Nakdong là một cây cầu sắt nổi tiếng ở Gimhae, nơi bạn có thể đạp xe và ngắm cảnh đẹp của dòng sông Nakdong trong một trải nghiệm thú vị.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Chùa Haedong Yonggungsa
</div>
<div class='pb-3 fs-5' >
Chùa Haedong Yonggungsa nằm bên bờ biển Busan, nổi bật với kiến trúc độc đáo và cảnh quan tuyệt đẹp, mang lại cảm giác bình yên cho du khách khi tham quan. 
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bãi biển Haeundae
</div>
<div class='pb-3 fs-5' >
Bãi biển Haeundae là bãi biển lớn nhất và nổi tiếng nhất tại Busan, nơi lý tưởng để tắm biển, thưởng thức hải sản và tham gia các hoạt động thể thao nước.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc19} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Làng văn hóa Gamcheon Culture Village
</div>
<div class='pb-3 fs-5' >
Làng văn hóa Gamcheon Culture Village là một khu phố nghệ thuật độc đáo với những ngôi nhà sắc màu rực rỡ, tạo ra không gian thú vị cho những ai yêu thích nghệ thuật và nhiếp ảnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc23} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Tàu cao tốc KTX (Busan - Seoul)
</div>
<div class='pb-3 fs-5' >
Tàu cao tốc KTX là phương tiện di chuyển nhanh nhất giữa Busan và Seoul, giúp rút ngắn thời gian di chuyển chỉ còn khoảng 2,5 giờ, mang lại sự tiện lợi cho du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Đảo Nami
</div>
<div class='pb-3 fs-5' >
Đảo Nami là một thiên đường lãng mạn nổi tiếng từ bộ phim "Bản tình ca mùa đông", nơi bạn có thể dạo chơi, chụp ảnh và tận hưởng không gian yên bình giữa thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Khu phố Myeongdong
</div>
<div class='pb-3 fs-5' >
Khu phố Myeongdong là một trong những điểm mua sắm sầm uất nhất ở Seoul, nổi bật với các cửa hàng thời trang, mỹ phẩm và những món ăn đường phố hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc35} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Cung điện Gyeongbok (Cảnh Phúc Cung)
</div>
<div class='pb-3 fs-5' >
Cung điện Gyeongbok là biểu tượng của triều đại Joseon, nổi bật với kiến trúc truyền thống và bảo tàng dân gian, mang lại cái nhìn sâu sắc về lịch sử Hàn Quốc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc39} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

</div>
<div class='pb-3 fs-5' >

</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc43} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Bảo tàng dân gian Quốc gia (National Folk Museum)
</div>
<div class='pb-3 fs-5' >
Bảo tàng dân gian Quốc gia là nơi trưng bày hơn 4.000 hiện vật văn hóa, giúp du khách hiểu rõ hơn về đời sống và phong tục tập quán của người Hàn Quốc qua các thời kỳ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc44} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc45} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc46} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc47} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc44} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc45} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc46} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc47} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
11. Nhà Xanh (The Blue House)
</div>
<div class='pb-3 fs-5' >
Nhà Xanh là nơi làm việc và cư trú của Tổng thống Hàn Quốc, nổi bật với kiến trúc truyền thống và khuôn viên rộng lớn, là biểu tượng của chính phủ Hàn Quốc.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc48} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc49} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc50} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc51} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc48} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc49} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc50} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc51} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
12. Khu phố tàu China town
</div>
<div class='pb-3 fs-5' >
Khu phố tàu China town ở Incheon là nơi sinh sống của cộng đồng người Hoa, nổi bật với ẩm thực phong phú và những hoạt động văn hóa đặc sắc, thu hút du khách đến khám phá.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc52} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc53} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc54} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hanquoc55} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hanquoc52} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc53} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hanquoc54} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hanquoc55} class='rounded-3' width='49%' height='220'  />
</div>
</div>


`;
export const tour_nhat = `
<div class=' fs-1 ' >
Tour Nhật Bản
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.nhatban1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.nhatban2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.nhatban3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.nhatban1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.nhatban2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.nhatban3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-5' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Cố Đô Nara: Tham quan Đền Todaiji và Công viên Nara, nơi có hươu nai tự do và kiến trúc gỗ cổ kính
</div>
 <div class='ps-5 fs-5'>
• Trải Nghiệm Osaka: Chiêm ngưỡng Lâu đài Osaka lừng lẫy và thưởng thức món lẩu Chanko đặc trưng của Nhật Bản
</div>
<div class='ps-5 fs-5'>
• Di Sản Văn Hóa Kyoto: Tham quan Chùa Kiyomizudera và Đền Fushimi Inari, khám phá vẻ đẹp và linh thiêng của cố đô Kyoto
</div>
<div class='ps-5 fs-5'>
• Chinh Phục Núi Phú Sĩ: Ngắm nhìn Đỉnh Núi Phú Sĩ từ Trạm số 5 và dạo chơi tại Làng cổ Oshino Hakkai dưới chân núi
</div>
<div class='ps-5 fs-5'>
• Khám Phá Tokyo Hiện Đại: Tham quan Chùa Asakusa, Tháp Tokyo Skytree và mua sắm tại Akihabara và Ginza, trải nghiệm sự kết hợp giữa truyền thống và hiện đại
</div>
</div>
<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
NHẬT BẢN
</div>
</div>



<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Đền Todaiji
</div>
<div class='pb-3 fs-5' >
Nằm ở Nara, đền Todaiji là công trình gỗ lớn nhất thế giới, nơi có tượng Phật Daibutsu khổng lồ, biểu tượng của đạo Phật Nhật Bản.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Công viên Nara
</div>
<div class='pb-3 fs-5' >
Công viên Nara nổi tiếng với đàn hươu nai hoang dã tự do đi lại. Đây là nơi lý tưởng để thư giãn và tận hưởng không khí trong lành.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Osaka
</div>
<div class='pb-3 fs-5' >
Là thành phố lớn thứ ba của Nhật Bản, Osaka nổi tiếng với nền ẩm thực phong phú, văn hóa đa dạng và những điểm tham quan hấp dẫn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Lâu đài Osaka
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Osaka, lâu đài này có kiến trúc nguy nga với chi tiết mạ vàng, mang đến trải nghiệm tham quan lịch sử độc đáo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban19} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Chùa Kiyomizudera
</div>
<div class='pb-3 fs-5' >
Ngôi chùa cổ kính ở Kyoto, nổi tiếng với tầm nhìn tuyệt đẹp và kiến trúc độc đáo, là nơi thu hút đông đảo du khách đến chiêm bái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban23} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Đền Fushimi Inari
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với hàng ngàn cổng Torii màu đỏ, đền Fushimi Inari là địa điểm linh thiêng, biểu tượng của sự thịnh vượng và may mắn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Núi Phú Sĩ
</div>
<div class='pb-3 fs-5' >
Biểu tượng nổi bật của Nhật Bản, núi Phú Sĩ mang đến cảnh đẹp hùng vĩ và là điểm đến lý tưởng cho những người yêu thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban31} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Làng cổ Oshino Hakkai
</div>
<div class='pb-3 fs-5' >
Nằm dưới chân núi Phú Sĩ, ngôi làng cổ này nổi bật với kiến trúc truyền thống và 8 hồ nước trong vắt, tạo nên khung cảnh yên bình.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban35} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Tokyo
</div>
<div class='pb-3 fs-5' >
Thủ đô sầm uất của Nhật Bản, Tokyo kết hợp giữa truyền thống và hiện đại, nổi bật với những công trình kiến trúc độc đáo và nền văn hóa phong phú.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban39} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Chùa Asakusa – Sensoji
</div>
<div class='pb-3 fs-5' >
Ngôi chùa Phật giáo cổ nhất Tokyo, Sensoji thu hút du khách với không khí linh thiêng và kiến trúc đặc sắc cùng khu phố mua sắm sôi động.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban43} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
11. Tháp Tokyo Skytree
</div>
<div class='pb-3 fs-5' >
Là tháp truyền hình cao nhất thế giới, Tokyo Skytree mang đến tầm nhìn 360 độ về thành phố, là một biểu tượng hiện đại của Tokyo.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban44} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban45} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban46} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban47} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban44} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban45} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban46} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban47} class='rounded-3' width='49%' height='220'  />
</div>
</div>


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
12. Akihabara
</div>
<div class='pb-3 fs-5' >
Khu phố nổi tiếng với đồ điện tử, game và văn hóa otaku, Akihabara thu hút người yêu công nghệ và fan hâm mộ anime.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban48} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban49} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban50} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban51} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban48} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban49} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban50} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban51} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
13. Ginza
</div>
<div class='pb-3 fs-5' >
Trung tâm mua sắm sầm uất của Tokyo, Ginza nổi tiếng với các thương hiệu cao cấp, cửa hàng thời trang và ẩm thực tinh tế.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban52} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban53} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban54} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatban55} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatban52} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban53} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatban54} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatban55} class='rounded-3' width='49%' height='220'  />
</div>
</div>

`;
export const tour_danang = `
<div class=' fs-1 ' >
Tour Đà Nẵng
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.danang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.danang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.danang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.danang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.danang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.danang3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Bà Nà Hills: Trải nghiệm cáp treo dài nhất thế giới, tham quan các công trình kiến trúc độc đáo như chùa Linh Ứng và ngắm cảnh từ cầu Vàng nổi tiếng.
</div>
 <div class='ps-5 fs-5 '>
• Tham Quan Cầu Rồng: Chiêm ngưỡng vẻ đẹp của cầu Rồng, một biểu tượng của Đà Nẵng, và không bỏ lỡ màn phun lửa và phun nước vào các buổi tối cuối tuần.
</div>
<div class='ps-5 fs-5 '>
• Tắm Biển Mỹ Khê: Thư giãn trên bãi biển Mỹ Khê với cát trắng và nước biển trong xanh, tham gia các hoạt động thể thao nước hoặc chỉ đơn giản là thưởng thức không khí biển.
</div>
<div class='ps-5 fs-5 '>
• Khám Phá Ngũ Hành Sơn: Leo núi và khám phá các hang động, chùa chiền tại Ngũ Hành Sơn, nơi mang đến những cảnh quan tuyệt đẹp và không gian tĩnh lặng.
</div>
<div class='ps-5 fs-5 '>
• Tham Gia Asia Park: Vui chơi tại Asia Park với nhiều trò chơi giải trí hấp dẫn và chiêm ngưỡng thành phố từ vòng quay Sun Wheel, một trong những vòng quay cao nhất thế giới.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Đà Nẵng
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

1. Bà Nà Hills
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với cáp treo dài nhất thế giới, Bà Nà Hills còn có những cảnh quan tuyệt đẹp, chùa Linh Ứng và cầu Vàng ấn tượng, mang đến trải nghiệm tham quan không thể quên.
</div>
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

2. Cầu Rồng
</div>
<div class='pb-3' >
Biểu tượng của thành phố Đà Nẵng, cầu Rồng không chỉ là một công trình kiến trúc đẹp mà còn có màn phun lửa và phun nước vào cuối tuần, thu hút đông đảo du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Ngũ Hành Sơn
</div>
<div class='pb-3' >
Khu danh thắng với những ngọn núi đá vôi và hang động huyền bí, là nơi lý tưởng để khám phá và chiêm ngưỡng các di tích văn hóa, chùa chiền.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bãi biển Mỹ Khê
</div>
<div class='pb-3' >
Một trong những bãi biển đẹp nhất thế giới, với cát trắng mịn và nước biển trong xanh, nơi du khách có thể tắm biển, tham gia các hoạt động thể thao nước hoặc thư giãn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bán Đảo Sơn Trà
</div>
<div class='pb-3' >
Bán đảo Sơn Trà là một khu bảo tồn thiên nhiên nổi tiếng, với cảnh quan hoang sơ, bãi biển trong xanh và những đỉnh núi hùng vĩ.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Đèo Hải Vân
</div>
<div class='pb-3' >
Đèo Hải Vân, với những khúc cua uốn lượn, nối liền Huế và Đà Nẵng, là một trong những con đèo đẹp nhất Việt Nam, mang đến cảnh sắc tuyệt vời của núi non và biển cả.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Rạn Nam Ô
</div>
<div class='pb-3' >
Rạn Nam Ô là khu vực biển đá tự nhiên, nổi bật với làn nước trong xanh và những rạn san hô phong phú, là điểm đến lý tưởng cho những ai yêu thích khám phá dưới nước.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Ghềnh Bàng
</div>
<div class='pb-3' >
Ghềnh Bàng, với bãi đá đẹp mắt và nước biển trong vắt, là một điểm du lịch nổi tiếng cho những ai yêu thích vẻ đẹp hoang sơ và yên tĩnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang35} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Cầu Sông Hàn
</div>
<div class='pb-3' >
Cầu Sông Hàn là biểu tượng của Đà Nẵng, nổi bật với kiến trúc độc đáo và khả năng quay 90 độ vào mỗi đêm, tạo nên cảnh tượng ấn tượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
10. Bảo Tàng Nghệ Thuật Điêu Khắc Chăm
</div>
<div class='pb-3' >
Bảo tàng Nghệ thuật Điêu khắc Chăm lưu giữ và trưng bày những tác phẩm điêu khắc độc đáo của nền văn hóa Champa, là điểm đến không thể bỏ qua cho những ai yêu thích nghệ thuật và lịch sử.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang40} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang41} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang42} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.danang43} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.danang40} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang41} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.danang42} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.danang43} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_hoian = `
<div class=' fs-1 ' >
Tour Hội An
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hoian1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hoian2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hoian3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hoian1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hoian2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hoian3} class='rounded-3' width='49%' height='220' />
</div>
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Phố Cổ Hội An: Dạo bộ trong khu phố cổ kính, ngắm nhìn các ngôi nhà gỗ và những chiếc đèn lồng lung linh, hòa mình vào không khí nhộn nhịp của chợ đêm và thưởng thức các món ăn đường phố.
</div>
 <div class='ps-5 fs-5 '>
• Chùa Cầu - Biểu Tượng Hội An: Tham quan Chùa Cầu, một công trình kiến trúc độc đáo kết hợp giữa văn hóa Nhật Bản và Việt Nam, nơi du khách có thể tìm hiểu về tín ngưỡng và phong tục của người dân địa phương.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Làm Gốm tại Làng Gốm Thanh Hà: Tham gia vào quy trình làm gốm truyền thống tại Làng Gốm Thanh Hà, nơi bạn có thể tự tay tạo ra sản phẩm gốm sứ và hiểu rõ hơn về nghệ thuật gốm của người dân nơi đây.
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn Tại Biển Cửa Đại: Dành thời gian thư giãn tại bãi biển Cửa Đại với cát trắng mịn và nước biển trong xanh, tham gia các hoạt động thể thao nước hoặc đơn giản là tắm nắng.
</div>
<div class='ps-5 fs-5 '>
• Khám Phá Rừng Dừa Bảy Mẫu: Đi thuyền thúng khám phá hệ sinh thái rừng ngập mặn tại Rừng Dừa Bảy Mẫu, nơi bạn có thể thưởng thức các món ăn địa phương ngon miệng và tìm hiểu về cuộc sống của ngư dân địa phương.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hội An
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3 ' >
1. Phố Cổ Hội An
</div>
<div class='pb-3 fs-5' >
Nổi tiếng với kiến trúc cổ kính, những ngôi nhà được bảo tồn tốt và không khí lịch sử. Đây là nơi tuyệt vời để dạo bộ, ngắm nhìn đèn lồng và trải nghiệm văn hóa địa phương.

<div class='d-none d-sm-block'>
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian7} class='rounded-3' width='49%' height='220'  />
</div>
</div>



<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Cầu
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Hội An, chùa được xây dựng vào thế kỷ 17 với kiến trúc độc đáo, kết hợp giữa văn hóa Nhật Bản và Trung Quốc. Đây là nơi lý tưởng để chụp ảnh và tìm hiểu về tín ngưỡng địa phương.
</div>

 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

3. Nhà Cổ Tấn Ký
</div>
<div class='pb-3 fs-5' >
Một trong những ngôi nhà cổ nổi tiếng nhất ở Hội An, được xây dựng từ thế kỷ 17. Nhà cổ này mang đậm dấu ấn văn hóa giao thoa của người Việt, Trung Quốc và Nhật Bản.

</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

4. Bảo Tàng Văn Hóa Dân Gian
</div>
<div class='pb-3 fs-5' >
Nơi trưng bày các hiện vật văn hóa đặc sắc của Hội An và các vùng lân cận, giúp du khách hiểu rõ hơn về đời sống và phong tục tập quán của người dân nơi đây.
</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

5. Biển Cửa Đại
</div>
<div class='pb-3 fs-5 ' >
Một bãi biển tuyệt đẹp với cát trắng mịn và nước biển trong xanh. Đây là nơi lý tưởng để thư giãn, tắm biển và tham gia các hoạt động thể thao nước.
 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Làng Gốm Thanh Hà
</div>
<div class='pb-3 fs-5' >
Du khách có thể tham gia vào quá trình làm gốm truyền thống và tìm hiểu về nghệ thuật gốm sứ của người dân địa phương tại làng gốm nổi tiếng này.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Rừng Dừa Bảy Mẫu
</div>
<div class='pb-3' >
Một khu vực rừng ngập mặn độc đáo, nơi du khách có thể trải nghiệm đi thuyền thúng và khám phá hệ sinh thái phong phú, đồng thời thưởng thức các món ăn địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hoian31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hoian28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hoian30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hoian31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
`;
export const tour_hue = `
<div class=' fs-1 ' >
Tour Huế
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.hue1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hue2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hue3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none'>
<img src=${TourInlandImages.hue1} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hue2} class='rounded-3 my-2' width='100%' height='300' />
<img src=${TourInlandImages.hue3} class='rounded-3' width='100%' height='300' />
</div>

<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Cố Đô Huế - Tham quan di sản văn hóa thế giới Cố Đô Huế, nơi lưu giữ những công trình kiến trúc hoàng gia như Đại Nội và các lăng tẩm của triều đại Nguyễn.
</div>
 <div class='ps-5 fs-5 '>
• Thuyền Trên Sông Hương - Tận hưởng cảm giác thư giãn khi du thuyền trên dòng sông Hương, ngắm cảnh sắc thơ mộng và khám phá các làng nghề ven sông.
</div>
<div class='ps-5  fs-5 '>
• Chiêm Ngưỡng Chùa Thiên Mụ - Thăm ngôi chùa nổi tiếng bên bờ sông Hương, với tháp Phước Duyên và không gian yên bình, mang đậm nét văn hóa tâm linh của người Việt.

</div>
<div class='ps-5 fs-5 '>
• Tham Quan Lăng Tự Đức: Khám phá lăng mộ của vua Tự Đức, nơi có kiến trúc độc đáo và phong cảnh hữu tình, phản ánh sự kết hợp hoàn hảo giữa thiên nhiên và nghệ thuật.
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Ẩm Thực Địa Phương: Thưởng thức các món ăn đặc sản nổi tiếng của Huế như bún bò Huế, bánh khoái và nem lụi tại các quán ăn truyền thống và chợ Đông Ba.
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
HUẾ
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success' >
1. Cố Đô Huế
</div>
<div class='pb-3 fs-5' >
Di sản văn hóa thế giới, nơi lưu giữ những công trình kiến trúc hoàng gia như Đại Nội, nơi từng là trung tâm chính trị của triều đại Nguyễn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue7} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Chùa Thiên Mụ
</div>
<div class='pb-3 fs-5' >
Một trong những ngôi chùa nổi tiếng nhất Việt Nam, tọa lạc bên bờ sông Hương, với tháp Phước Duyên 7 tầng và không gian thanh bình.
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >

3. Lăng Tự Đức
</div>
<div class='pb-3 fs-5' >
Kiến trúc độc đáo và cảnh quan tuyệt đẹp, lăng mộ của vua Tự Đức là nơi kết hợp hài hòa giữa thiên nhiên và nghệ thuật kiến trúc.
</div>


<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue15} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Sông Hương
</div>
<div class='pb-3 fs-5' >
Biểu tượng của thành phố Huế, du khách có thể tận hưởng cảnh đẹp thơ mộng khi đi thuyền trên sông và thưởng thức các món ăn địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Đại Nội
</div>
<div class='pb-3 fs-5' >
Khu di tích lịch sử quan trọng, nơi từng là cung điện của các vua triều Nguyễn, với kiến trúc mang đậm dấu ấn văn hóa truyền thống Việt Nam.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue27} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chợ Đông Ba
</div>
<div class='pb-3 fs-5' >
Trung tâm thương mại sầm uất, nơi du khách có thể khám phá ẩm thực địa phương, mua sắm hàng hóa đặc sản và quà lưu niệm.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hue31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hue28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hue30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hue31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_nhatrang = `
<div class=' fs-1 ' >
Tour Nha Trang
</div>

<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>

<img src=${TourInlandImages.nhatrang1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.nhatrang2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.nhatrang3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>

<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.nhatrang1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.nhatrang2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.nhatrang3} class='rounded-3' width='49%' height='220' />
</div>
</div>


<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5 ' >
• Khám Phá Vịnh Nha Trang: Tham gia các tour du thuyền khám phá các hòn đảo xung quanh, như Hòn Mun và Hòn Tằm, nổi bật với làn nước trong xanh và hệ sinh thái san hô đa dạng.

</div>
 <div class='ps-5 fs-5 '>
• Thăm Tháp Bà Ponagar: Chiêm ngưỡng kiến trúc độc đáo của tháp Chăm và tìm hiểu về văn hóa, lịch sử của dân tộc Chăm tại một trong những công trình cổ xưa nhất Việt Nam.

</div>
<div class='ps-5 fs-5 '>
• Vui Chơi Tại Vinpearl Land: Trải nghiệm các trò chơi giải trí hấp dẫn và tham gia vào công viên nước tại Vinpearl Land, một điểm đến lý tưởng cho gia đình và trẻ em.
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn Tại Biển Trần Phú: Thỏa sức tắm biển, tham gia các hoạt động thể thao nước hoặc đơn giản là nằm dài trên bãi biển cát trắng, tận hưởng không khí biển trong lành.
</div>
<div class='ps-5 fs-5 '>
• Chiêm Bái Tượng Phật Trắng Tại Chùa Long Sơn: Tham quan chùa Long Sơn, nơi có tượng Phật Trắng lớn nổi tiếng, và tận hưởng không gian yên bình cùng những giá trị tâm linh đặc sắc.
</div>
</div>

<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
NHA TRANG
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Vịnh Nha Trang
</div>
<div class='pb-3 fs-5 ' >
Nổi tiếng với vẻ đẹp hoang sơ, làn nước trong xanh và các hòn đảo xung quanh như Hòn Mun, Hòn Tằm, nơi lý tưởng để lặn biển và ngắm san hô.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 








<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Tháp Bà Ponagar
</div>
<div class='pb-3 fs-5' >
Một trong những công trình kiến trúc cổ xưa nhất, tháp Bà Ponagar mang đậm dấu ấn văn hóa Chăm Pa và là nơi thờ nữ thần Ponagar, rất thu hút khách du lịch đến tham quan và cầu nguyện.
</div>


 
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang11} class='rounded-3' width='49%' height='220'  />
</div>
</div>

<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Vinpearl Land Nha Trang
</div>
<div class='pb-3 fs-5' >
Khu vui chơi giải trí trên đảo với nhiều trò chơi hấp dẫn, công viên nước, khu nghỉ dưỡng và khu vực mua sắm, tạo nên một điểm đến lý tưởng cho cả gia đình
</div>

<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 


<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Biển Trần Phú
</div>
<div class='pb-3 fs-5 ' >
Bãi biển đẹp và sôi động nhất Nha Trang, nơi du khách có thể tắm biển, tham gia các hoạt động thể thao nước hoặc đơn giản là thư giãn dưới ánh nắng mặt trời.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang19} class='rounded-3' width='49%' height='220'  />
</div>
</div>





<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Hòn Nội
</div>
<div class='pb-3 fs-5' >
Hòn Nội nổi tiếng với bãi tắm đôi độc đáo và làn nước trong xanh, nơi du khách có thể lặn ngắm san hô, tận hưởng thiên nhiên hoang sơ, và thư giãn giữa cảnh sắc tuyệt đẹp.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Nhà thờ Nha Trang
</div>
<div class='pb-3 fs-5' >
Nhà thờ Nha Trang (Nhà thờ Núi) mang kiến trúc Gothic cổ kính và nằm trên một ngọn đồi cao, là địa điểm thu hút với vẻ đẹp cổ điển và là nơi lý tưởng để ngắm cảnh thành phố.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
 






<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Hòn Mun
</div>
<div class='pb-3 fs-5' >
Hòn Mun là khu bảo tồn biển nổi tiếng với hệ sinh thái san hô đa dạng, nơi tuyệt vời cho những ai yêu thích lặn biển và muốn khám phá vẻ đẹp dưới lòng đại dương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.nhatrang31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.nhatrang28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.nhatrang30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.nhatrang31} class='rounded-3' width='49%' height='220'  />
</div>
</div>

 `;
export const tour_phanthiet = `
 <div class=' fs-1 ' >
 Tour Phan Thiết
 </div>
 
 <div class='d-none d-md-block'>
 <div class='d-flex align-items-center gap-2 pt-3 '>
 
 <img src=${TourInlandImages.phanthiet1} class='rounded-3' width='60%' height='608' />
 <div class='w-100 gap-2 d-flex flex-column'>
 <img src=${TourInlandImages.phanthiet2} class='rounded-3' width='100%' height='300' />
 <img src=${TourInlandImages.phanthiet3} class='rounded-3' width='100%' height='300' />
 </div>
 </div>
 </div>
 
 
 
 <div class='d-block d-md-none pt-3 '>
 <img src=${TourInlandImages.phanthiet1} class='rounded-3' width='100%' height='300' />
 <div class='d-flex align-items-center gap-3'>
 <img src=${TourInlandImages.phanthiet2} class='rounded-3 my-2' width='49%' height='220' />
 <img src=${TourInlandImages.phanthiet3} class='rounded-3' width='49%' height='220' />
 </div>
 </div>
 
 
 <div class='bg-light p-3 rounded-3 mt-3' >
 <div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
 Điểm nhấn của hành trình:
 </div>
 <div class='ps-5 fs-5 ' >
 • Khám Phá Đồi Cát Mũi Né: Trải nghiệm những cồn cát trắng mịn, nơi bạn có thể tham gia hoạt động trượt cát và ngắm hoàng hôn tuyệt đẹp.
 </div>
  <div class='ps-5 fs-5 '>
 • Tham Quan Suối Tiên: Chiêm ngưỡng vẻ đẹp độc đáo của suối với dòng nước chảy qua những cồn cát, tạo nên cảnh quan thiên nhiên tuyệt vời cho những bức ảnh ấn tượng.
 </div>
 <div class='ps-5 fs-5 '>
 • Thăm Ngọn Hải Đăng Mũi Né: Leo lên ngọn hải đăng cổ kính để thưởng ngoạn toàn cảnh biển Mũi Né và khám phá những câu chuyện lịch sử thú vị.
 </div>
 <div class='ps-5 fs-5 '>
 • Thư Giãn Tại Bãi Biển Mũi Né: Thưởng thức làn sóng vỗ về và tham gia các hoạt động thể thao nước như lướt ván diều và lặn biển tại bãi biển nổi tiếng.
 </div>
 <div class='ps-5 fs-5 '>
 • Khám Phá Chùa Phú Hải: Tìm hiểu về văn hóa và tâm linh địa phương tại chùa Phú Hải, nơi có kiến trúc độc đáo và không gian thanh tịnh, lý tưởng để cầu bình an.
 </div>
 </div>
 <div class='fw-bolder fs-4 pt-4' >
 Các điểm đến nổi bật:
 </div>
 <div class='d-flex w-100 justify-content-center'>
 <div class='fw-bolder fs-4 text-success' >
 Phan Thiết
 </div>
 </div>
 
 
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3 ' >
 1. Hồ Tàu Ô
 </div>
 <div class='pb-3 fs-5 ' >
 Nằm giữa cánh đồng xanh mướt, hồ Tàu Ô nổi tiếng với màu nước trong xanh và cảnh quan thơ mộng. Đây là nơi lý tưởng để thư giãn và chụp ảnh.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet4} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet5} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet6} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet7} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet4} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet5} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet6} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet7} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 2. Đồi Cát Mũi Né
 </div>
 <div class='pb-3 fs-5' >
 Điểm đến không thể bỏ qua với những cồn cát vàng trắng, nơi du khách có thể tham gia các hoạt động như trượt cát, đi xe địa hình và ngắm hoàng hôn tuyệt đẹp.
 </div>
<div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet8} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet9} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet10} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet11} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet8} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet9} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet10} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet11} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 3. Lighthouse (Ngọn Hải Đăng) Mũi Né
 </div>
 <div class='pb-3 fs-5 ' >
 Ngọn hải đăng cổ kính nằm trên đỉnh đồi cao, nơi du khách có thể chiêm ngưỡng toàn cảnh biển Mũi Né và vùng biển bao la.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet12} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet13} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet14} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet15} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet12} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet13} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet14} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet15} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 4. Bãi Biển Mũi Né
 </div>
 <div class='pb-3 fs-5' >
 Bãi biển nổi tiếng với cát trắng mịn, làn sóng nhẹ nhàng và các dịch vụ thể thao nước như lướt ván diều và lặn biển, thu hút nhiều du khách.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet16} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.phanthiet18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.phanthiet19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.phanthiet16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.phanthiet17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.phanthiet18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.phanthiet19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 5. Suối Tiên
 </div>
 <div class='pb-3 fs-5 ' >
 Một suối nước chảy qua những cồn cát với màu nước đỏ cam nổi bật, tạo nên một khung cảnh độc đáo và đẹp mắt, lý tưởng cho những bức ảnh sống động.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet20} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet21} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet22} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet23} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet20} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet21} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet22} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet23} class='rounded-3' width='49%' height='220'  />
 </div>
 </div>
 <div class='w-100 border border-1 border-dark'>
 </div>
 <div class='fw-bolder fs-4  text-success pt-3' >
 6. Tháp Chàm Poshanư
 </div>
 <div class='pb-3 fs-5' >
 Di tích văn hóa Chăm cổ nằm trên đỉnh đồi, với kiến trúc độc đáo và tầm nhìn tuyệt đẹp ra biển. Đây là nơi lý tưởng để tìm hiểu về văn hóa và lịch sử Chăm.
 </div>
 <div class='d-none d-sm-block'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet24} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet25} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet26} class='rounded-3' width='22.2%' height='220'  />
 <img src=${TourInlandImages.phanthiet27} class='rounded-3' width='22.2%' height='220'  />
 </div>
 </div>
 <div class='d-block d-sm-none'>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
 <img src=${TourInlandImages.phanthiet24} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet25} class='rounded-3' width='49%' height='220'  />
 </div>
 <div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
 <img src=${TourInlandImages.phanthiet26} class='rounded-3' width='49%' height='220'  />
 <img src=${TourInlandImages.phanthiet27} class='rounded-3' width='49%' height='220'  />
 </div>
 </div> 
 `;
export const tour_phuonghoangcotran = `
<div class=' fs-1' >
Tour Phượng Hoàng Cổ Trấn
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.cotran1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.cotran2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.cotran3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.cotran1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.cotran2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.cotran3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Cảnh Quan Hùng Vĩ: Chiêm ngưỡng vẻ đẹp ngoạn mục của Trương Gia Giới với những dãy núi đá vôi và thung lũng xanh tươi, được UNESCO công nhận là Di sản Thiên nhiên Thế giới
</div>
 <div class='ps-5 fs-5'>
• Tham Quan Phượng Hoàng Cổ Trấn: Khám phá nét đẹp cổ kính và văn hóa đặc sắc của Phượng Hoàng Cổ Trấn, nơi có những ngôi nhà gỗ truyền thống và các cây cầu đá duyên dáng bên dòng Đà Giang
</div>
<div class='ps-5 fs-5'>
• Trải Nghiệm Văn Hóa Người Miêu: Tìm hiểu về văn hóa và phong tục tập quán độc đáo của người Miêu tại Miêu Trại Tương Tây, nơi có các hoạt động văn hóa và ẩm thực đặc sắc
</div>
<div class='ps-5 fs-5 '>
• Thám Hiểm Thiên Môn Sơn: Trải nghiệm cáp treo dài nhất thế giới và đi bộ trên Sạn Đạo Kính, nơi mang đến cảm giác hồi hộp và cơ hội ngắm nhìn cảnh sắc thiên nhiên tuyệt đẹp từ trên cao
</div>
<div class='ps-5 fs-5 '>
• Thả Thuyền Tại Hồ Bảo Phong: Thư giãn trên hồ Bảo Phong, nơi từng là bối cảnh trong phim Tây Du Ký, với phong cảnh như tranh vẽ và âm nhạc truyền thống du dương từ người dân địa phương
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Phượng Hoàng Cổ Trấn
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trương Gia Giới
</div>
<div class='pb-3 fs-5' >
Trương Gia Giới nổi tiếng với những dãy núi đá vôi hùng vĩ và cảnh quan thiên nhiên tuyệt đẹp. Nơi đây được UNESCO công nhận là Di sản Thiên nhiên Thế giới, thu hút du khách bởi những vách đá cao chót vót, thung lũng xanh tươi và hệ sinh thái phong phú. Đây cũng là nguồn cảm hứng cho khung cảnh trong bộ phim nổi tiếng "Avatar".
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Phượng Hoàng Cổ Trấn
</div>
<div class='pb-3 fs-5' >
Phượng Hoàng Cổ Trấn là một trong những cổ trấn đẹp nhất Trung Quốc, nổi bật với kiến trúc cổ kính và cảnh quan thơ mộng bên dòng Đà Giang. Tại đây, du khách có thể khám phá những ngôi nhà gỗ cổ, những cây cầu đá xinh đẹp, cùng nhiều điểm tham quan nổi tiếng như Tháp Vạn Danh và Cầu Hồng Kiều, tạo nên bức tranh sống động của văn hóa và lịch sử.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Miêu Trại Tương Tây
</div>
<div class='pb-3 fs-5' >
Miêu Trại Tương Tây là ngôi làng lớn nhất của người Miêu tại Hồ Nam, nổi bật với nét văn hóa độc đáo và tập tục truyền thống. Tại đây, du khách có thể tìm hiểu về cuộc sống, trang phục và các phong tục tập quán của người Miêu, thưởng thức các món ăn đặc sản và tham gia vào các hoạt động văn hóa truyền thống.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Vũ Lăng Nguyên
</div>
<div class='pb-3 fs-5' >
Vũ Lăng Nguyên là một khu vực nổi tiếng với cảnh quan thiên nhiên hùng vĩ và những kỳ quan kiến trúc độc đáo như Tòa nhà 72 kỳ lầu. Nơi đây mang đến cho du khách trải nghiệm tuyệt vời với các khung cảnh đẹp mắt, các hoạt động ngoài trời thú vị và cơ hội tìm hiểu về lịch sử và văn hóa địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Thiên Môn Sơn
</div>
<div class='pb-3 fs-5' >
Thiên Môn Sơn được biết đến với cáp treo dài nhất thế giới và Sạn Đạo Kính - con đường kính nổi tiếng trên vách núi. Đứng từ đỉnh núi, du khách có thể ngắm toàn cảnh thiên nhiên hùng vĩ, các đỉnh núi và những làn mây trắng lững lờ trôi, tạo nên một không gian như chốn bồng lai tiên cảnh.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Hồ Bảo Phong
</div>
<div class='pb-3 fs-5' >
Hồ Bảo Phong nằm trong khu thắng cảnh Vũ Lăng Nguyên, nổi tiếng với phong cảnh thơ mộng và vẻ đẹp tự nhiên tuyệt vời. Du khách có thể thả thuyền trên hồ, ngắm nhìn những dãy núi xanh ngắt và nghe những bài hát truyền thống du dương của người dân địa phương.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Cầu Kính Đại Hiệp Cốc
</div>
<div class='pb-3 fs-5' >
Cầu Kính Đại Hiệp Cốc là cầu kính dài 430m và cao 300m, nổi bật với thiết kế ấn tượng và cảnh quan tuyệt đẹp. Được xây dựng trên vách đá, cầu mang đến trải nghiệm đầy cảm giác hồi hộp cho du khách khi ngắm nhìn vẻ đẹp hùng vĩ của công viên Trương Gia Giới bên dưới.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.cotran31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.cotran28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.cotran30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.cotran31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_singapore = `
<div class=' fs-1 ' >
Tour Singapore
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.singapore1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.singapore2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.singapore3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.singapore1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.singapore2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.singapore3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5  fs-5' >
• Khám Phá Sân bay Changi: Trải nghiệm sân bay nổi tiếng với kiến trúc ấn tượng và các tiện ích độc đáo như khu vườn trong nhà và thác nước trong nhà lớn nhất thế giới
</div>
 <div class='ps-5 fs-5'>
• Tham Quan Đảo Sentosa: Tận hưởng các hoạt động giải trí đa dạng, từ bãi biển đẹp đến các công viên giải trí hấp dẫn như Universal Studios Singapore
</div>
<div class='ps-5 fs-5'>
• Chiêm Ngưỡng Bay South Gardens by the Bay: Tham quan công viên sinh thái độc đáo với những "siêu cây" và khu nhà kính, nơi có nhiều loài thực vật đa dạng và phong phú
</div>
<div class='ps-5 fs-5'>
• Thưởng Thức Vịnh Marina Bay: Dạo chơi bên bờ vịnh, chiêm ngưỡng cảnh quan hiện đại của thành phố và các công trình nổi tiếng như Marina Bay Sands
</div>
<div class='ps-5 fs-5'>
• Khám Phá Công viên sư tử biển Merlion Park: Chụp hình bên biểu tượng Merlion của Singapore, hòa mình vào không khí nhộn nhịp và ngắm nhìn vẻ đẹp của vịnh Marina
</div>
</div>
<div class='fw-bolder fs-5 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
SINGAPORE
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Sân bay Changi
</div>
<div class='pb-3 fs-5' >
Được biết đến là một trong những sân bay tốt nhất thế giới, Changi nổi bật với kiến trúc hiện đại, các khu vườn trong nhà, và nhiều tiện ích giải trí hấp dẫn cho hành khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Đảo Sentosa
</div>
<div class='pb-3 fs-5' >
Hòn đảo nghỉ dưỡng nổi tiếng với bãi biển tuyệt đẹp, công viên giải trí, khu nghỉ dưỡng sang trọng, và nhiều hoạt động vui chơi giải trí cho cả gia đình.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Universal Studios
</div>
<div class='pb-3 fs-5' >
Công viên giải trí theo chủ đề đầu tiên ở Đông Nam Á, nơi du khách có thể thưởng thức các trò chơi cảm giác mạnh, biểu diễn hấp dẫn và trải nghiệm thế giới phim ảnh nổi tiếng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore15} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Bay South Gardens by the Bay
</div>
<div class='pb-3 fs-5' >
Công viên sinh thái độc đáo với những "siêu cây" năng lượng mặt trời, vườn hoa tuyệt đẹp và khu nhà kính đa dạng, tạo nên không gian xanh mát giữa lòng thành phố.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore19} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Nhà hát Esplanade
</div>
<div class='pb-3 fs-5' >
Trung tâm nghệ thuật biểu diễn nổi bật với kiến trúc độc đáo, nơi tổ chức nhiều sự kiện văn hóa, hòa nhạc và biểu diễn nghệ thuật đẳng cấp quốc tế.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore23} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Vịnh Marina Bay
</div>
<div class='pb-3 fs-5' >
Khu vực đẹp tuyệt vời với cảnh quan hiện đại, bao gồm Marina Bay Sands, Gardens by the Bay và nhiều địa điểm giải trí khác, mang lại trải nghiệm tuyệt vời cho du khách.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore27} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Công viên sư tử biển Merlion Park
</div>
<div class='pb-3 fs-5' >
Biểu tượng của Singapore, Merlion là bức tượng nửa sư tử nửa cá, thu hút hàng triệu du khách đến tham quan và chụp hình bên bờ vịnh Marina.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.singapore31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.singapore28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.singapore30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.singapore31} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_thailan = `
<div class=' fs-1' >
Tour Thái Lan: Bangkok - Pattaya
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.thailan1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.thailan2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.thailan3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.thailan1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.thailan2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.thailan3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Thăm quan vườn nhiệt đới Nong Nooch
</div>
 <div class='ps-5 fs-5'>
• Tham quan bảo tàng nghệ thuật ánh sáng và khinh khí cầu
</div>
<div class='ps-5 fs-5'>
• Show Alcazar/Colosseum
</div>
<div class='ps-5 fs-5 '>
• Đi tàu cao tốc và tắm biển tại đảo san hô – Coral Island
</div>
<div class='ps-5 fs-5 '>
• Khách sạn tiêu chuẩn 4* tại Bangkok và 5* tại Pattaya
</div>
<div class='ps-5 fs-5 '>
• Bay hàng không vietnam airline
</div>
<div class='ps-5 fs-5 '>
• Trải nghiệm ăn tối trên du thuyền 5* sông Chaophraya
</div>
<div class='ps-5 fs-5 '>
• Lễ phật bốn mặt vô cùng linh thiêng tại đền thờ Erawan
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Bangkok - Pattaya
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Trân Bảo Phật Sơn Khao Chee Chan
</div>
<div class='pb-3 fs-5' >
Là ngọn núi nổi tiếng với bức tượng Phật khắc bằng vàng lớn nhất Thái Lan, cao 130m và được chế tác từ 999kg vàng. Xung quanh là khu vườn xinh đẹp, mang đến không gian yên bình cho du khách chiêm bái và thưởng ngoạn.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Wat Phra Yai (Chùa Phật Lớn)
</div>
<div class='pb-3 fs-5' >
Nằm trên đồi Pratumnak, chùa có bức tượng Phật cao 18m, được xây dựng từ những năm 1940. Đây là ngôi chùa linh thiêng, thu hút đông đảo du khách và người dân địa phương đến cầu nguyện, ngắm nhìn vẻ đẹp hùng vĩ của bức tượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Đảo San Hô (Coral Island)
</div>
<div class='pb-3 fs-5' >
Là điểm đến lý tưởng cho những ai yêu thích biển xanh và cát trắng. Du khách có thể tham gia nhiều hoạt động thể thao dưới nước như lướt ván, lặn biển và tắm nắng trên bãi biển tuyệt đẹp, tận hưởng không khí trong lành của thiên nhiên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Vườn Nhiệt Đới Nong Nooch
</div>
<div class='pb-3 fs-5' >
Khu vườn nhiệt đới nổi tiếng với những cảnh quan thiên nhiên tuyệt đẹp, đa dạng loài thực vật và các chương trình biểu diễn văn hóa. Đây là nơi lý tưởng để khám phá vẻ đẹp tự nhiên và tìm hiểu về văn hóa Thái Lan.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Bảo Tàng Nghệ Thuật Ánh Sáng và Khinh Khí Cầu
</div>
<div class='pb-3 fs-5' >
Một địa điểm nghệ thuật độc đáo, nơi ánh sáng và nghệ thuật kết hợp để tạo ra những trải nghiệm trực quan sống động. Du khách có thể khám phá các tác phẩm nghệ thuật mô phỏng các địa danh nổi tiếng của Thái Lan.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Chùa Thuyền (Wat Yannawa)
</div>
<div class='pb-3 fs-5' >
Chùa có hình dáng như một con thuyền buôn Trung Hoa, mang đậm kiến trúc Thái Lan. Nơi đây có bức tượng nhà vua Rama I, tạo nên không khí trang nghiêm, giúp du khách cảm nhận được sự thanh tịnh và vẻ đẹp văn hóa Thái.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan27} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
7. Sông Chaophraya
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "dòng sông của các vị vua", Chaopraya chảy qua trung tâm Bangkok, mang lại vẻ đẹp thơ mộng cho thành phố. Du khách có thể thưởng ngoạn phong cảnh sông nước, khám phá các điểm đến lịch sử và văn hóa ven sông.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan28} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan29} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan30} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan31} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan28} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan29} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan30} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan31} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
8. Đền Phật Bốn Mặt Erawan
</div>
<div class='pb-3 fs-5' >
Là một trong những ngôi đền linh thiêng nhất Thái Lan, thu hút đông đảo du khách và người dân địa phương đến cầu nguyện. Đền nổi tiếng với bức tượng Phật Bốn Mặt, biểu tượng của sự may mắn và thịnh vượng.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan32} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan33} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan34} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan35} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan32} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan33} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan34} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan35} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
9. Central World
</div>
<div class='pb-3 fs-5' >
Là trung tâm mua sắm lớn nhất Thái Lan, Central World không chỉ là nơi mua sắm mà còn là địa điểm giải trí sôi động. Tại đây, du khách có thể tìm thấy hàng ngàn sản phẩm từ thời trang đến ẩm thực, cùng với các sự kiện hấp dẫn diễn ra thường xuyên.
</div>
<div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan36} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan37} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan38} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.thailan39} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.thailan36} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan37} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.thailan38} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.thailan39} class='rounded-3' width='49%' height='220'  />
</div>
</div>
`;
export const tour_trungquoc = `
<div class=' fs-1' >
Tour Hải Nam - Hải Khẩu - Tam Á
</div>
<div class='d-none d-md-block'>
<div class='d-flex align-items-center gap-2 pt-3'>
<img src=${TourInlandImages.hainam1} class='rounded-3' width='60%' height='608' />
<div class='w-100 gap-2 d-flex flex-column'>
<img src=${TourInlandImages.hainam2} class='rounded-3' width='100%' height='300' />
<img src=${TourInlandImages.hainam3} class='rounded-3' width='100%' height='300' />
</div>
</div>
</div>
<div class='d-block d-md-none pt-3'>
<img src=${TourInlandImages.hainam1} class='rounded-3' width='100%' height='300' />
<div class='d-flex align-items-center gap-3'>
<img src=${TourInlandImages.hainam2} class='rounded-3 my-2' width='49%' height='220' />
<img src=${TourInlandImages.hainam3} class='rounded-3' width='49%' height='220' />
</div>
</div>
<div class='bg-light p-3 rounded-3 mt-3' >
<div class='text-primary pt-2 ps-4 fw-bolder fs-4' >
Điểm nhấn của hành trình:
</div>
<div class='ps-5 fs-5' >
• Khám Phá Di Sản Văn Hóa: Tham quan Phố cổ Kỳ Lầu với kiến trúc cổ kính và lịch sử hơn 700 năm, mang lại cái nhìn sâu sắc về văn hóa và truyền thống của Trung Quốc
</div>
<div class='ps-5 fs-5'>
• Trải Nghiệm Hải Hoa Đảo: Khám phá Hải Hoa Đảo, hòn đảo nhân tạo lớn nhất thế giới với nhiều hoạt động giải trí hấp dẫn, từ bãi biển tuyệt đẹp đến các khu vui chơi và ẩm thực độc đáo
</div>
<div class='ps-5 fs-5'>
• Thiên Nhiên Hùng Vĩ tại Tam Á: Thưởng ngoạn cảnh đẹp tại khu du lịch văn hóa Nam Sơn với bức tượng Quan Âm nổi tiếng và khung cảnh núi biển hòa quyện tuyệt đẹp
</div>
<div class='ps-5 fs-5 '>
• Thư Giãn tại Bãi Biển: Tận hưởng không gian thư giãn tại Vịnh Á Long, với bãi cát trắng mịn và nước biển trong xanh, là nơi lý tưởng cho các hoạt động tắm biển và vui chơi
</div>
<div class='ps-5 fs-5 '>
• Trải Nghiệm Độc Đáo: Bay trực thăng trên Đảo Phượng Hoàng, mang đến cảm giác phiêu lưu và cái nhìn toàn cảnh đẹp mắt về vùng biển tuyệt vời của Tam Á
</div>
</div>
<div class='fw-bolder fs-4 pt-4' >
Các điểm đến nổi bật:
</div>
<div class='d-flex w-100 justify-content-center'>
<div class='fw-bolder fs-4 text-success' >
Hải Nam - Hải Khẩu - Tam Á
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
1. Phố Cổ Kỳ Lầu
</div>
<div class='pb-3 fs-5' >
Nằm ở thành phố Hải Khẩu, Phố cổ Kỳ Lầu là một trong những địa điểm du lịch nổi tiếng với kiến trúc lịch sử độc đáo. Đặc biệt, tòa nhà Sipai Lou có lịch sử hơn 700 năm, mang đậm nét văn hóa và lịch sử Trung Quốc, thu hút du khách đến khám phá và chụp ảnh.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam4} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam5} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam6} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam7} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam4} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam5} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam6} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam7} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
2. Hải Hoa Đảo
</div>
<div class='pb-3 fs-5' >
Được mệnh danh là "Dubai của Phương Đông," Hải Hoa Đảo là hòn đảo nhân tạo lớn nhất thế giới. Nơi đây có nhiều điểm tham quan thú vị như bãi biển, công viên giải trí và khu vực ẩm thực phong phú, là điểm đến lý tưởng cho những ai yêu thích sự sôi động và thư giãn.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam8} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam9} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam10} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam11} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam8} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam9} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam10} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam11} class='rounded-3' width='49%' height='220'  />
</div>
</div>
<div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
3. Tam Á
</div>
<div class='pb-3 fs-5' >
Là một trong những địa điểm du lịch biển nổi tiếng tại Trung Quốc, Tam Á thu hút du khách bởi bãi biển đẹp và khí hậu ôn hòa. Nơi đây cung cấp nhiều hoạt động giải trí dưới nước và khám phá thiên nhiên tuyệt đẹp.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam12} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam13} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam14} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam15} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam12} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam13} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam14} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam15} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
4. Khu du lịch văn hóa Nam Sơn
</div>
<div class='pb-3 fs-5' >
Đây là một trong những danh lam thắng cảnh nổi bật của Trung Quốc, kết hợp văn hóa Phật giáo với cảnh sắc thiên nhiên. Tượng Quan Âm cao 108m là điểm nhấn chính, thu hút đông đảo du khách đến cầu nguyện và chiêm ngưỡng.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam16} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam17} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam18} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam19} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam16} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam17} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam18} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam19} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
5. Thiên Nhai Hải Giác
</div>
<div class='pb-3 fs-5' >
Nằm bên bờ biển, Thiên Nhai Hải Giác là danh lam thắng cảnh cấp cao với phong cảnh tự nhiên tuyệt đẹp, bao gồm biển xanh, cát trắng và những khối đá kỳ vĩ. Đây là nơi lý tưởng để thư giãn và chụp những bức ảnh ấn tượng.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam20} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam21} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam22} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam23} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam20} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam21} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam22} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam23} class='rounded-3' width='49%' height='220'  />
</div>
</div><div class='w-100 border border-1 border-dark'>
</div>
<div class='fw-bolder fs-4  text-success pt-3' >
6. Vịnh Á Long
</div>
<div class='pb-3 fs-5' >
Với bãi biển trải dài 7 km và nước biển trong xanh, Vịnh Á Long được biết đến là "vịnh đẹp nhất thế giới." Đây là điểm đến lý tưởng cho những ai yêu thích sự bình yên và vẻ đẹp thiên nhiên.
</div>
 <div class='d-none d-sm-block'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam24} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam25} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam26} class='rounded-3' width='22.2%' height='220'  />
<img src=${TourInlandImages.hainam27} class='rounded-3' width='22.2%' height='220'  />
</div>
</div>
<div class='d-block d-sm-none'>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-3'>
<img src=${TourInlandImages.hainam24} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam25} class='rounded-3' width='49%' height='220'  />
</div>
<div class='d-flex align-items-center justify-content-center gap-3 w-100 mb-5'>
<img src=${TourInlandImages.hainam26} class='rounded-3' width='49%' height='220'  />
<img src=${TourInlandImages.hainam27} class='rounded-3' width='49%' height='220'  />
</div>
`;
export const ky_thuat_tai_uc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.uc} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Tại Sao Học Kỹ Thuật Tại Úc Là Lựa Chọn Hoàn Hảo Cho Bạn?
</div>
<div class='ps-5  fs-5'>
Học kỹ thuật luôn là một trong những lựa chọn hàng đầu của các bạn trẻ, đặc biệt khi hướng tới những cơ hội nghề nghiệp tiềm năng và môi trường học tập tiên tiến. Úc, với hệ thống giáo dục đẳng cấp thế giới, đang ngày càng khẳng định vị thế là điểm đến lý tưởng cho những ai muốn theo đuổi lĩnh vực kỹ thuật. Vậy tại sao học kỹ thuật tại Úc là lựa chọn hoàn hảo cho bạn? Dưới đây là những lý do thuyết phục mà bạn không thể bỏ qua. </div>
<div class='fw-bolder text-success fs-4'>
1. CHẤT LƯỢNG GIÁO DỤC ĐẲNG CẤP THẾ GIỚI </div>
<div class='ps-5  fs-5'>
• Úc tự hào là nơi có nhiều trường đại học hàng đầu thế giới về đào tạo kỹ thuật, bao gồm Đại học Melbourne, Đại học Sydney, Đại học New South Wales (UNSW), và Đại học Monash. Các trường này liên tục được xếp hạng cao trong bảng xếp hạng toàn cầu như QS World Rankings hay Times Higher Education.  </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Điểm nổi bật trong giáo dục kỹ thuật tại Úc: </div>
<div class='fs-5 ps-5'>
• Chương trình đào tạo được thiết kế bài bản, cập nhật theo nhu cầu thực tiễn của thị trường lao động toàn cầu. </div>
<div class='fs-5 ps-5'>
• Các khóa học mang tính ứng dụng cao, kết hợp giữa lý thuyết và thực hành, giúp sinh viên sẵn sàng bước vào môi trường làm việc ngay sau khi tốt nghiệp. </div>
<div class='fs-5 ps-5'>
• Đội ngũ giảng viên là các chuyên gia trong ngành, sở hữu kinh nghiệm phong phú và kết nối chặt chẽ với ngành công nghiệp. </div>
<div class='fw-bolder text-success fs-4'>
2. HỆ THỐNG TRANG THIẾT BỊ HIỆN ĐẠI </div>
<div class='fs-5 ps-5'>
• Ngành kỹ thuật đòi hỏi cơ sở vật chất tiên tiến để phục vụ cho nghiên cứu và thực hành. Úc nổi tiếng với các phòng thí nghiệm hiện đại, hệ thống máy móc kỹ thuật công nghệ cao và các trung tâm nghiên cứu đạt chuẩn quốc tế.   
Sinh viên kỹ thuật tại Úc được tiếp cận với: </div>
<div class='fs-5 ps-5'>
• Các dự án nghiên cứu tiên tiến trong các lĩnh vực như trí tuệ nhân tạo, năng lượng tái tạo, kỹ thuật hàng không, và xây dựng bền vững. </div>
<div class='fs-5 ps-5'>
• Các chương trình thực tập tại các tập đoàn lớn như Boeing, Siemens, và BHP, giúp tích lũy kinh nghiệm thực tế quý báu. </div>
<div class='fw-bolder text-success fs-4'>
3. CHƯƠNG TRÌNH HỌC ĐA DẠNG VÀ LINH HOẠT </div>
<div class='ps-5  fs-5'>
• Úc cung cấp nhiều lựa chọn ngành học trong lĩnh vực kỹ thuật, từ kỹ thuật cơ khí, kỹ thuật dân dụng, kỹ thuật điện tử đến kỹ thuật phần mềm và công nghệ sinh học. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Lợi ích từ sự đa dạng trong chương trình học:  </div>
<div class='fs-5 ps-5'>
• Bạn có thể chọn chuyên ngành phù hợp với sở thích và mục tiêu nghề nghiệp của mình. </div>
<div class='fs-5 ps-5'>
Nhiều trường đại học tại Úc còn cho phép sinh viên kết hợp các chuyên ngành để mở rộng kiến thức và cơ hội việc làm sau này.  </div>
<div class='fw-bolder text-success fs-4'>
4. CƠ HỘI NGHỀ NGHIỆP RỘNG MỞ </div>
<div class='ps-5  fs-5'>
Úc đang phải đối mặt với tình trạng thiếu hụt lao động kỹ thuật chất lượng cao, điều này mang lại lợi thế lớn cho sinh viên ngành kỹ thuật. Sau khi tốt nghiệp, bạn có thể dễ dàng tìm kiếm việc làm trong các lĩnh vực như xây dựng, công nghệ thông tin, kỹ thuật môi trường, và năng lượng. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Ưu điểm khi làm việc tại Úc: </div>
<div class='fs-5 ps-5'>
• Chính sách thị thực làm việc sau tốt nghiệp (Post-Study Work Visa) cho phép bạn ở lại Úc làm việc từ 2-4 năm. </div>
<div class='fs-5 ps-5'>
• Mức lương cạnh tranh, với mức lương khởi điểm cho kỹ sư mới tốt nghiệp dao động từ 60.000 đến 80.000 AUD/năm. </div>
<div class='fs-5 ps-5'>
• Cơ hội định cư cao nhờ các chính sách ưu tiên định cư cho ngành kỹ thuật. </div>
<div class='fw-bolder text-success fs-4'>
5. MÔI TRƯỜNG SỐNG VÀ HỌC TẬP LÝ TƯỞNG </div>
<div class='ps-5  fs-5'>
• Úc không chỉ nổi tiếng với hệ thống giáo dục hàng đầu mà còn là một quốc gia có chất lượng cuộc sống cao. Các thành phố lớn như Sydney, Melbourne, Brisbane và Perth đều nằm trong danh sách những thành phố đáng sống nhất thế giới. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
 Những điểm hấp dẫn của cuộc sống tại Úc: </div>
<div class='fs-5 ps-5'>
• Xã hội an toàn, đa văn hóa và thân thiện.</div>
<div class='fs-5 ps-5'>
• Khí hậu ôn hòa, dễ chịu, phù hợp với du học sinh từ khắp nơi trên thế giới. </div>
<div class='fs-5 ps-5'>
• Nhiều cơ hội tham gia các hoạt động ngoại khóa, từ thể thao, nghệ thuật đến các sự kiện cộng đồng. </div>
<div class='fw-bolder text-success fs-4'>
6. CƠ HỘI KẾT NỐI TOÀN CẦU </div>
<div class='ps-5  fs-5'>
• Du học Úc mang lại cơ hội mở rộng mạng lưới quan hệ quốc tế. Sinh viên kỹ thuật tại đây thường xuyên được tham gia các hội thảo, cuộc thi sáng tạo kỹ thuật và các chương trình trao đổi sinh viên quốc tế. </div>
<div class='ps-3 fw-bolder  fs-5'>
Bạn sẽ có cơ hội: </div>
<div class='fs-5 ps-5'>
• Làm quen với bạn bè từ khắp nơi trên thế giới, mở rộng hiểu biết và kỹ năng giao tiếp đa văn hóa. </div>
<div class='fs-5 ps-5'>
• Hợp tác với các doanh nghiệp lớn thông qua các dự án thực tập và nghiên cứu. </div>
<div class='fw-bolder text-success fs-4'>
7. HỌC BỔNG VÀ HỖ TRỢ TÀI CHÍNH </div>
<div class='ps-5  fs-5'>
• Chi phí du học Úc có thể là một rào cản, nhưng đừng lo lắng, các trường đại học tại Úc cung cấp rất nhiều chương trình học bổng dành cho sinh viên quốc tế, đặc biệt là trong lĩnh vực kỹ thuật. </div>
<div class='py-2 ps-3 fw-bolder fs-5'>
Một số học bổng tiêu biểu: </div>
<div class='fs-5 ps-5'>
• Học bổng Chính phủ Úc (Australia Awards Scholarships). </div>
<div class='fs-5 ps-5'>
• Học bổng từ các trường đại học như UNSW Engineering International Scholarships, Melbourne Graduate Research Scholarships. </div>
<div class='fs-5 ps-5'>
• Học bổng hỗ trợ từ các tổ chức công nghiệp. </div>
<div class='ps-5  fs-5'>
• Với chất lượng giáo dục hàng đầu, cơ sở vật chất hiện đại, cơ hội nghề nghiệp rộng mở và môi trường sống lý tưởng, Úc chính là lựa chọn hoàn hảo cho những ai muốn theo đuổi ngành kỹ thuật. Nếu bạn đang tìm kiếm một nơi để phát triển bản thân và xây dựng sự nghiệp bền vững, hãy cân nhắc đến Úc – vùng đất của những cơ hội và thành công.
Hãy bắt đầu hành trình chinh phục giấc mơ kỹ thuật của bạn tại Úc ngay hôm nay! </div>
`;
export const  top5_hanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.han} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Top 5 Khu Vực Nên Chọn Khi Đi Du Học Ở Hàn Quốc
</div>
<div class='ps-5  fs-5'>
Hàn Quốc, một trong những điểm đến hấp dẫn của du học sinh quốc tế, không chỉ nổi tiếng với nền giáo dục chất lượng mà còn sở hữu những thành phố sôi động, môi trường sống hiện đại và nền văn hóa phong phú. Tuy nhiên, việc chọn khu vực học tập phù hợp không hề dễ dàng, bởi mỗi nơi đều có những điểm mạnh riêng. Dưới đây là danh sách 5 khu vực nổi bật nhất tại Hàn Quốc mà bạn nên cân nhắc khi du học. </div>
<div class='fw-bolder text-success fs-4'>
1. SEOUL - TRÁI TIM CỦA HÀN QUỐC?  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Seoul?  </div>
<div class='ps-5  fs-5'>
• Seoul, thủ đô và trung tâm kinh tế, chính trị, văn hóa của Hàn Quốc, là nơi lý tưởng cho du học sinh quốc tế. Thành phố này không chỉ sở hữu những trường đại học hàng đầu như Đại học Quốc gia Seoul, Đại học Yonsei và Đại học Korea mà còn mang đến cơ hội tiếp xúc với công nghệ hiện đại, mạng lưới giao thông thuận tiện và cuộc sống năng động.  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Các chương trình học bổng đa dạng từ các trường danh tiếng. </div>
<div class='ps-5 fs-5'>
• Cơ hội việc làm thêm tại các công ty, quán cà phê và trung tâm thương mại. </div>
<div class='ps-5 fs-5'>
• Văn hóa giải trí phát triển mạnh với những khu phố như Hongdae, Myeongdong và Gangnam. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế:  </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt cao, đặc biệt là tiền thuê nhà và chi phí ăn uống. </div>
<div class='fw-bolder text-success fs-4'>
2. BUSAN - THÀNH PHỐ BIỂN NĂNG ĐỘNG  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Busan?  </div>
<div class='ps-5 fs-5'>
• Busan, thành phố lớn thứ hai của Hàn Quốc, là điểm đến hoàn hảo cho những ai yêu thích cuộc sống gần biển. Với các trường đại học chất lượng như Đại học Quốc gia Busan, thành phố này mang đến một môi trường học tập thoải mái và giá cả hợp lý hơn so với Seoul. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Khí hậu dễ chịu, thích hợp với những ai yêu thiên nhiên và biển cả.  </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt thấp hơn, đặc biệt là giá thuê nhà.  </div>
<div class='ps-5 fs-5'>
• Các sự kiện văn hóa độc đáo như Liên hoan phim Quốc tế Busan (BIFF).  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Ít cơ hội làm thêm hơn so với Seoul. </div>
<div class='ps-5 fs-5'>
• Cách xa trung tâm kinh tế và chính trị của Hàn Quốc. </div>
<div class='fw-bolder text-success fs-4'>
3. DAEGU - TRUNG TÂM CỦA GIÁO DỤC MIỀN TRUNG HÀN QUỐC 
</div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Daegu? </div>
<div class='ps-5 fs-5'>
• Daegu nổi tiếng là trung tâm giáo dục và văn hóa của miền Trung Hàn Quốc, với các trường đại học uy tín như Đại học Kyungpook. Thành phố này thích hợp cho những ai muốn học tập trong một môi trường yên tĩnh nhưng không kém phần hiện đại. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật:  </div>
<div class='ps-5 fs-5'>
• Học phí và chi phí sinh hoạt thấp. </div>
<div class='ps-5 fs-5'>
• Cơ hội khám phá văn hóa truyền thống qua các lễ hội và di sản lịch sử. </div>
<div class='ps-5 fs-5'>
• Hệ thống giao thông hiện đại, dễ dàng kết nối với các thành phố lớn khác. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Không có nhiều hoạt động giải trí so với Seoul và Busan. </div>
<div class='fw-bolder text-success fs-4'>
4. Incheon – Cửa ngõ quốc tế của Hàn Quốc </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Incheon?  </div>
<div class='ps-5 fs-5'>
• Là nơi có sân bay quốc tế lớn nhất Hàn Quốc, Incheon không chỉ là cửa ngõ kết nối thế giới mà còn là một trung tâm giáo dục đang phát triển nhanh chóng. Các trường đại học nổi bật ở đây bao gồm Đại học Inha và Đại học Quốc gia Incheon.   </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật:  </div>
<div class='ps-5 fs-5'>
• Gần Seoul, thuận tiện cho việc đi lại. </div>
<div class='ps-5 fs-5'>
• Cơ hội tham gia các chương trình học tập quốc tế.</div>
<div class='ps-5 fs-5'>
• Môi trường sống hiện đại và năng động. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Không khí có thể bị ảnh hưởng bởi ô nhiễm từ các khu công nghiệp. </div>
<div class='fw-bolder text-success fs-4'>
5. GWANGJU - THÀNH PHỐ VĂN HOÁ VÀ NGHỆ THUẬT </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Tại sao nên chọn Gwangju? </div>
<div class='ps-5 fs-5'>
• Gwangju, thành phố nổi tiếng với di sản văn hóa và nghệ thuật, là nơi lý tưởng cho những du học sinh yêu thích sự sáng tạo. Đại học Chosun và Đại học Quốc gia Gwangju là những trường học tiêu biểu tại đây.  </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Điểm nổi bật: </div>
<div class='ps-5 fs-5'>
• Môi trường học tập yên bình, tập trung cao cho việc nghiên cứu. </div>
<div class='ps-5 fs-5'>
• Chi phí sinh hoạt thấp, phù hợp với du học sinh. </div>
<div class='ps-5 fs-5'>
• Nhiều hoạt động văn hóa như triển lãm nghệ thuật, lễ hội âm nhạc. </div>
<div class='py-2 ps-3 fw-bolder  fs-5'>
Hạn chế: </div>
<div class='ps-5 fs-5'>
• Ít cơ hội tiếp xúc với môi trường kinh doanh quốc tế. </div>
<div class='ps-5 fs-5'>
• Việc lựa chọn khu vực du học ở Hàn Quốc phụ thuộc vào sở thích, mục tiêu học tập và khả năng tài chính của mỗi cá nhân. Nếu bạn muốn trải nghiệm sự hiện đại và náo nhiệt, Seoul là lựa chọn hàng đầu. Ngược lại, nếu bạn tìm kiếm một môi trường học tập bình yên và chi phí hợp lý, Busan hay Daegu sẽ phù hợp hơn. Hãy cân nhắc kỹ lưỡng và chọn cho mình một điểm đến phù hợp để có trải nghiệm du học tuyệt vời tại Hàn Quốc! </div>
`;
export const  camnang_duhoc_nendiduhocnhatbanhayhanquoc = `
 
   <div class='d-flex flex-column align-items-center'>
	<img src=${ProgramImages.nhatban} class='w-100' />
   </div>
<div class='fw-bolder fs-1  text-success py-3'>
Nên đi du học Nhật Bản hay Hàn Quốc?
</div>
<div class='ps-5 pb-3 fs-5'>
- Khi cân nhắc giữa việc du học Nhật Bản và Hàn Quốc, bạn cần xem xét nhiều yếu tố như điều kiện nhập học, chi phí, môi trường học tập, cơ hội việc làm và văn hóa. Dưới đây là phân tích chi tiết giúp bạn đưa ra quyết định phù hợp. </div>
<div class='fw-bolder text-success pb-3 fs-4'>
ĐIỀU KIỆN DU HỌC
</div>
<div class='ps-3 fw-bolder  fs-5'>
Độ tuổi: </div>

<div class='ps-5 pb-3 fs-5'>
- Cả Nhật Bản và Hàn Quốc đều chấp nhận du học sinh từ 18 đến 30 tuổi, tùy thuộc vào chương trình học.</div>
<div class=' ps-3 fw-bolder  fs-5'>
Trình độ ngôn ngữ: </div>
<div class='ps-3 fw-bolder  fs-5'>
+ Nhật Bản: </div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Nhật: Yêu cầu tối thiểu N5 hoặc đã học cơ bản ít nhất 150 giờ tại Việt Nam.</div>
<div class='ps-5  fs-5'>
- Chương trình Đại học: Yêu cầu tối thiểu N2.</div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Anh: Không yêu cầu tiếng Nhật đầu vào.</div>
<div class='ps-3 fw-bolder  fs-5'>
+ Hàn Quốc: </div>
<div class='ps-5  fs-5'>
- Chương trình tiếng Hàn: Không yêu cầu TOPIK nhưng cần học cơ bản 3-6 tháng tại Việt Nam. </div>
<div class='ps-5  fs-5'>
- Chương trình Đại học: Yêu cầu tối thiểu TOPIK 3 hoặc 4. </div>
<div class='ps-5 pb-3 fs-5'>
- Chương trình tiếng Anh: Không yêu cầu tiếng Hàn đầu vào. </div>
<div class=' ps-3 fw-bolder  fs-5'>
Trình độ học vấn: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Tốt nghiệp THPT với điểm trung bình trên 6.5.  </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Tốt nghiệp THPT với điểm trung bình trên 6.0.  </div>
<div class=' ps-3 fw-bolder  fs-5'>
Chứng minh tài chính:  </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Sổ tiết kiệm tối thiểu 500 – 600 triệu VNĐ, mở trước ít nhất 3 tháng. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Hệ tiếng: Sổ ngân hàng tối thiểu 10.000 USD; Hệ Đại học/Thạc sĩ: Sổ ngân hàng tối thiểu 20.000 USD. </div>

<div class='fw-bolder pb-3 text-success fs-4'>
THỜI GIAN DU HỌC </div>
<div class='ps-5  fs-5'>
- Thời gian học tập tại Nhật Bản và Hàn Quốc tương đương nhau đối với các chương trình Đại học, Cao đẳng, Thạc sĩ và học nghề. Riêng chương trình tiếng, thời gian học phụ thuộc vào từng trường và kỳ nhập học. </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Kỳ nhập học vào tháng 1, 4, 7, 10. </div>
<div class='ps-5  fs-5 pb-3'>
- Hàn Quốc: Kỳ nhập học vào tháng 3, 6, 9, 12. </div>
<div class='fw-bolder text-success pb-3 fs-4'>
CHI PHÍ DU HỌC </div>
<div class=' ps-3 fw-bolder  fs-5'>
Học phí: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Học phí năm đầu khoảng 160 – 215 triệu VNĐ, tùy khu vực và trường học. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Học phí dao động từ 3.000 – 6.000 USD/năm, tùy ngành học và trường. </div>
<div class='ps-3 fw-bolder  fs-5'>
Chi phí sinh hoạt: </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Khoảng 800 – 1.000 USD/tháng. </div>
<div class='ps-5 pb-3 fs-5'>
- Hàn Quốc: Khoảng 600 – 800 USD/tháng. </div>

<div class='fw-bolder pb-3 text-success fs-4'>
CƠ HỘI VIỆC LÀM THÊM </div>

<div class='ps-5  fs-5'>
- Cả hai quốc gia đều cho phép du học sinh làm thêm. </div>
<div class='ps-5  fs-5'>
- Nhật Bản: Được làm 28 giờ/tuần với mức lương trung bình 800 – 1.000 JPY/giờ. </div>
<div class='ps-5  fs-5'>
- Hàn Quốc: Được làm 20 – 25 giờ/tuần với mức lương trung bình 7.000 – 10.000 KRW/giờ. </div>

<div class='fw-bolder py-3 text-success fs-4'>
CƠ HỘI VIỆC LÀM SAU TỐT NGHIỆP </div>
<div class='ps-5  fs-5'>
- Nhật Bản và Hàn Quốc đều có nhu cầu nhân lực cao trong các lĩnh vực như kỹ thuật, công nghệ thông tin, y tế và dịch vụ. Tuy nhiên, Nhật Bản có tỷ lệ già hóa dân số cao, dẫn đến nhu cầu tuyển dụng lao động nước ngoài lớn hơn. </div>

<div class='fw-bolder py-3 text-success fs-4'>
VĂN HOÁ VÀ MÔI TRƯỜNG SỐNG
</div>
<div class='ps-5  fs-5'>
- Cả hai quốc gia đều có nền văn hóa phong phú và môi trường sống an toàn. Nhật Bản nổi tiếng với sự kỷ luật và công nghệ tiên tiến, trong khi Hàn Quốc được biết đến với sự năng động và ngành công nghiệp giải trí phát triển. </div>

<div class='fw-bolder text-success py-3 fs-4'>
KẾT LUẬN
</div>
<div class='ps-5  fs-5'>
- Việc lựa chọn du học Nhật Bản hay Hàn Quốc phụ thuộc vào mục tiêu cá nhân, khả năng tài chính và sở thích văn hóa của bạn. Hãy cân nhắc kỹ lưỡng các yếu tố trên để đưa ra quyết định phù hợp nhất cho tương lai của mình. </div>
`;





