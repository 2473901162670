import React from "react";
import { useNavigate } from "react-router-dom";
import { TravelGuide } from "src/constant/Array";

const Travel_Guide = ({ aos }: any) => {
  const navigate = useNavigate()
  
  return (
    <div
      data-aos={aos}
      style={{
        background: "linear-gradient(210deg,#007261, #21c586)",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-3">Cẩm nang du lịch</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#fed842",
            }}
          />
        </div>

        <div className="row mt-4">
          {TravelGuide.map((item) => {
            return (
              <div key={item.id} className="col-6 col-md-6 col-lg-3 mb-4">
                <div
                  className="btn-item-travel pb-3 w-100"
                 
                >
                  <div className="w-100">
                    <img
                      src={item.image}
                     className="img-item-tour-inland"
                    />
                    <div className="fw-semibold py-2 px-3 title-travel-guide">
                      {item.title}
                    </div>
                  </div>
                  <button onClick={() => navigate('/chi-tiet-cam-nang')} className="btn-see-more">Đọc tiếp</button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button onClick={() => navigate('/cam-nang-du-lich')} className="btn-see-all-tour-inland mb-4">Xem tất cả</button>
      </div>
    </div>
  );
};

export default Travel_Guide;
