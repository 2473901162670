import React, { useEffect } from "react";
import Programs from "./Components/Programs";
import Handbook from "./Components/Handbook";
import Event from "./Components/Event";
import Typical from "./Components/Typical";
import StudyAbroad from "./Components/StudyAbroad";
import AOS from "aos";
import "aos/dist/aos.css";

const Study_Abroad = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div>
      <StudyAbroad aos="fade-up" />
      <Programs aos="fade-up" />
      <Handbook aos="fade-up" />
      {/* <Event aos="fade-up" /> */}
      <Typical aos="fade-up" />
    </div>
  );
};

export default Study_Abroad;
