import React from "react";
import CourseImages from "src/assets/Course";
import HeadTab from "src/components/HeadTab/HeadTab";

const Course = () => {
  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            Các khoá học ngoại ngữ tại DABOSA
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[1]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              Khoá học tiếng Nhật Bản
            </div>
            <div className="fw-semibold">Hình thức học Online</div>
            <br />
            <div className="text-success fw-bolder fs-5">
              Thông tin khoá học:
            </div>
            <div className="pt-2">• Độ tuổi từ 16 - 30</div>
            <div className="py-1">
              • Đối tượng: Học sinh/sinh viên/người đi làm
            </div>
            <div>• Đầu ra N4 hoặc N5</div>
            <div className="py-1">• Giáo trình Minano Nihongo</div>
            <div>• Học đủ 4 kỹ năng, giao tiếp cơ bản</div>
            <div className="py-1">• Được giao lưu cùng người bản địa</div>
            <div>• Hình thức học Online</div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 mb-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              Khoá học tiếng Hàn Quốc
            </div>
            <div className="fw-semibold">Hình thức học Online</div>
            <br />
            <div className="text-success fw-bolder fs-5">
              Thông tin khoá học:
            </div>
            <div className="pt-2">• Độ tuổi từ 16 - 30</div>
            <div className="py-1">
              • Đối tượng: Học sinh/sinh viên/người đi làm
            </div>
            <div>• Đầu ra Topik 2 </div>
            <div className="py-1">• Giáo trình tiếng Hàn tổng hợp</div>
            <div>• Học đủ 4 kỹ năng, giao tiếp cơ bản</div>
            <div className="py-1">• Được giao lưu cùng người bản địa</div>
            <div>• Hình thức học Online</div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[2]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
        </div>

        <div
          className="row col-md-12 rounded-3 p-3 my-4"
          style={{
            background: "#f5f5f5",
          }}
        >
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={CourseImages[3]}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="text-success fw-bolder fs-3">
              Khoá học tiếng Trung Quốc
            </div>
            <div className="fw-semibold">Hình thức học Online</div>
            <br />
            <div className="text-success fw-bolder fs-5">
              Thông tin khoá học:
            </div>
            <div className="pt-2">• Độ tuổi từ 16 - 30</div>
            <div className="py-1">
              • Đối tượng: Học sinh/sinh viên/người đi làm
            </div>
            <div>• Đầu ra HSK 3</div>
            <div className="py-1">
              • Giáo trình HSK Học đủ 4 kỹ năng, giao tiếp cơ bản Được giao lưu
              cùng người bản địa
            </div>
            <button
              onClick={() => window.scrollTo(0, 1800)}
              className="btn-see-register-course mb-5 mt-3"
            >
              Đăng ký ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Course;
