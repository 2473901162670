import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import vn from './vn.json'

const resources: any = {
    en: en,
    vn: vn,
};

i18n.use(initReactI18next).init({
    compatibilityJSON: 'v3',
    resources,
    lng: 'vn',
});


export default i18n;