import React from "react";
import { useNavigate } from "react-router-dom";
import IntroImages from "src/assets/Intro";
import HeadTab from "src/components/HeadTab/HeadTab";

const Col1 = ({ aos }: any) => {
  return (
    <div data-aos={aos}>
      <HeadTab />
      <div
        className="py-5"
        style={{
          backgroundImage: `url(${IntroImages.bg_1})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          height: "max-content",
        }}
      >
        <div className="container-xxl">
          <div className="row col-md-12 justify-content-md-between">
            <div className="col-md-6">
              <div
                className="d-flex justify-content-center flex-column"
                style={{
                  width: "max-content",
                }}
              >
                <div className="fs-2 fw-bolder" style={{ color: "#ffd75c" }}>
                  Thư ngỏ
                </div>
                <div
                  style={{
                    height: 3,
                    backgroundColor: "#ffd75c",
                  }}
                />
              </div>
              <div
                className="fw-semibold pt-5 fs-5"
                style={{
                  color: "#ffd75c",
                }}
              >
                Công ty Cổ phần Tập đoàn DABOSA xin kính chào Quý khách hàng và
                Đối tác!
              </div>
              <br />
              <div className="text-white fw-semibold">
                Lời đầu tiên, chúng tôi xin gửi lời chúc sức khỏe, thành công
                đến Quý khách hàng và Đối tác!
              </div>
              <br />
              <div className="text-white fw-semibold">
                Công ty Cổ phần Tập đoàn DABOSA ra đời với sứ mệnh trở thành cầu
                nối đáng tin cậy, mang đến những giải pháp dịch vụ tối ưu trong
                lĩnh vực Du học - Du lịch và đào tạo ngoại ngữ, góp phần chắp
                cánh ước mơ của thế hệ trẻ Việt Nam và cung cấp những trải
                nghiệm du lịch tuyệt vời.
              </div>
              <div className="text-white pt-5 fw-semibold">
            Quy tụ đội ngũ chuyên gia hàng đầu từ 05 - 20 năm kinh nghiệm trong
            lĩnh vực, DABOSA GROUP cam kết mang đến những thông tin minh bạch,
            tư vấn chương trình du học và du lịch với chi phí hợp lý, cá nhân
            hóa phù hợp với từng nhu cầu của Quý khách hàng và Đối tác. Chúng
            tôi luôn sẵn sàng lắng nghe và không ngừng đổi mới để trở thành đơn
            vị tư vấn du học, đào tạo ngoại ngữ và cung cấp dịch vụ du lịch lữ
            hành uy tín trên thị trường.
          </div>
          <br />
          <div className="text-white fw-semibold">
            Cuối cùng, chúng tôi xin gửi lời chúc sức khỏe, bình an, hạnh phúc,
            công việc hanh thông đến toàn thể Quý khách hàng, Đối tác đã, đang
            và sẽ sử dụng dịch vụ của DABOSA GROUP.
          </div>
          <br />
          <div
            className="fw-semibold"
            style={{
              color: "#ffd75c",
            }}
          >
            Xin trân trọng cảm ơn và rất hân hạnh được phục vụ!
          </div>
            </div>
            <div className="d-block d-sm-none mt-3" />
            <div className="col-md-5 ">
              <img
                src={IntroImages.avt_right}
                className="avt-right shadow-sm "
                alt=""
              />
            </div>
          </div>

          
        </div>
      </div>
    </div>
  );
};

export default Col1;
