import React from "react";
import { useLocation } from "react-router-dom";
import HeadTab from "src/components/HeadTab/HeadTab";


const NewDetail = () => {
    const { state } = useLocation();
    const content = state?.content;

    return (
        <div>
            <HeadTab />
            <div className="container-xxl py-5">
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </div>
        </div>
    )
}

export default NewDetail