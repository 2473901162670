import { CaretRightFilled, CaretUpFilled } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import EventImages from "src/assets/HomePage/Event";

const Event = ({ aos }: any) => {
  const navigate = useNavigate()

  return (
    <div
      data-aos={aos}
      style={{
        background: "#fef8e3",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-2">Tin tức & Sự kiện</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>
        <div className="row mt-4">
          <div className="col-md-12 col-lg-6">
            <div
              className="event-image-1"
              style={{
                backgroundImage: `url(${EventImages[1]})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham
                    quan...
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-lg-6">
            <div className="d-block d-md-none mt-2" />
            <div
              className="event-image-2"
              style={{
                backgroundImage: `url(${EventImages[2]})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    Ký kết hợp tác giáo viên nước Đức và Anh
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mt-2">
              <div
                className="event-image-3"
                style={{
                  backgroundImage: `url(${EventImages[3]})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">
                      Trung Thu cùng các bạn học viên
                    </div>
                    <button className="btn-see-more-event">
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder">Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
              <div
                className="event-image-4"
                style={{
                  backgroundImage: `url(${EventImages[4]})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  height: 280,
                }}
              >
                <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                  <div
                    style={{
                      backgroundColor: `rgba(0,0,0,0.3)`,
                      borderRadius: 8,
                      padding: 8,
                    }}
                  >
                    <div className="txt-title-event">
                      Dabosa tiếp nối chương trình...
                    </div>
                    <button className="btn-see-more-event">
                      <img
                        src={HomePageImages.arrow_right}
                        width={18}
                        height={12}
                      />
                      <div className="fw-bolder">Đọc tiếp</div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row col-lg-12 mt-2">
          <div className="col-lg-3">
            <div
              className="event-image-5"
              style={{
                backgroundImage: `url(${EventImages[5]})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    Món quà từ Ấn Độ của Chủ tịch ...
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 event-image-6">
            <div
              className="event-image-6"
              style={{
                backgroundImage: `url(${EventImages[6]})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    Những khoảnh khắc ấn tượng trong hội nghị khách hàng Hasuka
                    - Dabosa
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />{" "}
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 event-image-7">
            <div
              className="event-image-7"
              style={{
                backgroundImage: `url(${EventImages[7]})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                height: 280,
                borderRadius: 8,
              }}
            >
              <div className="p-3 d-flex flex-column justify-content-end w-100 h-100 ">
                <div
                  style={{
                    backgroundColor: `rgba(0,0,0,0.3)`,
                    borderRadius: 8,
                    padding: 8,
                  }}
                >
                  <div className="txt-title-event">
                    Khách quốc tế Ấn Độ đến tìm hiểu...
                  </div>
                  <button className="btn-see-more-event">
                    <img
                      src={HomePageImages.arrow_right}
                      width={18}
                      height={12}
                    />
                    <div className="fw-bolder">Đọc tiếp</div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4">
          <button onClick={() => navigate('/news')} className="btn-see-all-tour-inland mb-5">Xem tất cả</button>
        </div>
      </div>
    </div>
  );
};

export default Event;
