import HandBookImages from "src/assets/Home/Handbook"
import ProgramImages from "src/assets/Home/Progams"
import TourInlandImages from "src/assets/Home/TourInland"
import TourInternationalImages from "src/assets/Home/TourInternational"
import TravelGuideImages from "src/assets/Home/TravelGuide"
import HomePageImages from "src/assets/HomePage"
import ReviewImages from "src/assets/HomePage/Reviews"
import NewImages from "src/assets/News"
import RecImages from "src/assets/Rec"
import { cam_nang_hanquoc, cam_nang_nghe_duc, cam_nang_nhat, cam_nang_uc, Study_DaiLoan, Study_Duc, Study_Han, Study_Nhat, Study_TrungQuoc, Study_UC, tour_bangkok, tour_danang, tour_hagiang, tour_phuonghoangcotran , tour_han, tour_hoangsuphi, tour_hoian, tour_hue, tour_mucangchai, tour_nhat, tour_nhatrang, tour_ninhbinh, tour_phanthiet, tour_quangbinh, tour_quangdongquangtay, tour_sapa, tour_taxua, tour_singapore, tour_thailan, tour_trungquoc, tour_thanhdo, cam_nang_phuquoc, ky_thuat_tai_uc, top5_hanquoc, camnang_duhoc_nendiduhocnhatbanhayhanquoc,} from "./Choore"

const Footer_Button = [
    {
        id: 0,
        text: 'Du học Nhật Bản',
        content: Study_Nhat
        },
    {
        id: 1,
        text: 'Du học Hàn Quốc',
        content: Study_Han
    },
    {
        id: 2,
        text: 'Du học Đức',
        content: Study_Duc
    },
    {
        id: 3,
        text: 'Du học Úc',
        content: Study_UC
    },
    {
        id: 4,
        text: 'Du học Trung Quốc',
        content: Study_TrungQuoc
    },
    {
        id: 5,
        text: 'Du học Đài Loan',
        content: Study_DaiLoan
    },
    {
        id: 10,
        text: 'Du học New Zealand',
        content: ''
    },
    {
        id: 6,
        text: 'Du học Mỹ',
        content: ''
    },
    {
        id: 7,
        text: 'Du lịch nội địa',
        
    },
    {
        id: 8,
        text: 'Du lịch quốc tế',
    },
    {
        id: 9,
        text: 'Cẩm nang du lịch',
    },
]

export const TourInland = [
    {
        id: -1,
        image: TourInlandImages.sapa,
        title: 'Tour Sapa',
        content: tour_sapa
    },
    {
        id: 0,
        image: TourInlandImages.suphi,
        title: 'Tour Hoàng Su Phì',
        content: tour_hoangsuphi
    },
    {
        id: 1,
        image: TourInlandImages.taxua,
        title: 'Săn mây Tà Xùa',
        content: tour_taxua
    },
    {
        id: 2,
        image: TourInlandImages.mucangchai,
        title: 'Tour Mù Cang Chải',
        content: tour_mucangchai
    },
    {
        id: 3,
        image: TourInlandImages.bia_hagiang,
        title: 'Tour Hà Giang',
        content: tour_hagiang
    },
    {
        id: 4,
        image: TourInlandImages.phanthiet,
        title: 'Tour Phan Thiết',
        content: tour_phanthiet
    },
    {
        id: 5,
        image: TourInlandImages.ninhbinh,
        title: 'Tour Ninh Bình - Yên Tử',
        content: tour_ninhbinh
    },
    {
        id: 6,
        image: TourInlandImages.quangbinh,
        title: 'Tour Quảng Bình',
        content: tour_quangbinh
    },
    {
        id: 7,
        image: TourInlandImages.hue,
        title: 'Tour HUẾ',
        content: tour_hue
    },
    {
        id: 8,
        image: TourInlandImages.bia_danang,
        title: 'Tour ĐÀ NẴNG',
        content: tour_danang
    },
    {
        id: 9,
        image: TourInlandImages.bia_nhatrang,
        title: 'Tour NHA TRANG',
        content: tour_nhatrang
    },
    {
        id: 10,
        image: TourInlandImages.bia_hoian,
        title: 'Tour Hội An',
        content: tour_hoian
    },
]

export const TourInternational = [
    {
        id: -1,
        image: TourInternationalImages.quangdong,
        title: 'Tour Quảng Đông - Quảng Tây',
        content: tour_quangdongquangtay
    },
    {
        id: 0,
        image: TourInternationalImages.legiang,
        title: 'Tour Lệ Giang - Shangri-La',
        content: tour_bangkok
    },
    {
        id: 1,
        image: TourInternationalImages.thanhdo,
        title: 'Tour Thành Đô - Trùng Khánh ',
        content: tour_thanhdo
    },
   
    {
        id: 2,
        image: TourInternationalImages.cotran,
        title: 'Phượng Hoàng Cổ Trấn',
        content: tour_phuonghoangcotran
    },
    {
        id: 3,
        image: TourInternationalImages.hainam,
        title: 'Tour Hải Nam - Hải Khẩu - Tam Á',
        content: tour_trungquoc
    },
    {
        id: 4,
        image: TourInternationalImages.bangkok,
        title: 'Tour Thái Lan: Bangkok - Pattaya',
        content: tour_thailan
    },
    {
        id: 5,
        image: TourInternationalImages.sing,
        title: 'Singapore',
        content: tour_singapore
    },
    {
        id: 6,
        image: TourInternationalImages.seoul,
        title: 'Tour Hàn Quốc',
        content: tour_han
    },
    {
        id: 7,
        image: TourInternationalImages.nhat,
        title: 'Nhật Bản',
        content: tour_nhat
    },
]

export const TravelGuide = [
    {
        id: 0,
        image: TravelGuideImages[1],
        title: 'Top 10 bãi biển đẹp nhất miền Trung'
    },
    {
        id: 1,
        image: TravelGuideImages[2],
        title: 'Cẩm nang du lịch Mù Cang Chải từ A-Z'
    },
    {
        id: 2,
        image: TravelGuideImages[3],
        title: `Review Du lịch Phú Quốc`
    },
    {
        id: 3,
        image: TravelGuideImages[4],
        title: '5 món ăn bạn không thể bỏ qua khi đến Hội An'
    },
    {
        id: 4,
        image: TravelGuideImages[5],
        title: 'Top 5 điểm check-on cháy máy tại Đà Nẵng'
    },
    {
        id: 5,
        image: TravelGuideImages[6],
        title: 'Bỏ túi bí kíp du lịch Hải Nam'
    },
    {
        id: 6,
        image: TravelGuideImages[7],
        title: 'Hướng dẫn tự làm hộ chiếu online tại nhà'
    },
    {
        id: 7,
        image: TravelGuideImages[8],
        title: 'Nên mang gì khi đi du lịch Thái Lan 5 ngày 4 đêm?'
    },
]

export const StudyPrograms = [
    {
        id: 0,
        title: 'Du học Nhật Bản',
        image: ProgramImages.nhatban,
        content: Study_Nhat
    },
    {
        id: 1,
        title: 'Du học Hàn Quốc',
        image: ProgramImages.han,
        content: Study_Han
    },
    {
        id: 4,
        title: 'Du học Đức',
        image: ProgramImages.duc,
        content: Study_Duc
    },
    {
        id: 5,
        title: 'Du học Úc',
        image: ProgramImages.uc,
        content: Study_UC
    },
    {
        id: 2,
        title: 'Du học Trung Quốc',
        image: ProgramImages.trungquoc,
        content: Study_TrungQuoc
    },
    {
        id: 3,
        title: 'Du học Đài Loan',
        image: ProgramImages.dailoan,
        content: Study_DaiLoan
    },
    {
        id: 6,
        title: 'Du học New Zealand',
        image: ProgramImages.new
    },
    {
        id: 8,
        title: 'Du học Mỹ',
        image: ProgramImages.my
    },

]

export const Hand_Book = [
    {
        id: 0,
        title: 'Du học Nhật Bản 2024 ngành gì?',
        image: HandBookImages[1],
        content: cam_nang_nhat
    },
    {
        id: 1,
        title: 'Cẩm nang Du học nghề Đức từ A – Z',
        image: HandBookImages[2],
        content: cam_nang_nghe_duc
    },
    {
        id: 2,
        title: 'Những điều thú vị khi sinh sống và học tâp tại Úc',
        image: HandBookImages[3],
        content: cam_nang_uc
    },
    {
        id: 3,
        title: '8 lý do bạn nên du học Hàn Quốc',
        image: HandBookImages[4],
        content: cam_nang_hanquoc
    },
    {
        id: 4,
        title: 'Nên đi du học Nhật Bản hay Hàn Quốc?',
        image: HandBookImages[5],
        content: camnang_duhoc_nendiduhocnhatbanhayhanquoc
    },
    {
        id: 5,
        title: 'Top 5 khu vực nên chọn khi đi du học ở Hàn Quốc',
        image: HandBookImages[6],
        content: top5_hanquoc
    },
    {
        id: 6,
        title: 'Tại sao học kỹ thuật tại Úc là lựa chọn hoàn hảo cho bạn?',
        image: HandBookImages[7],
        content:ky_thuat_tai_uc
    },
]

export const Introduce = [
    {
        id: 0,
        image: HomePageImages.vtv
    },
    {
        id: 1,
        image: HomePageImages.dantri
    },
    {
        id: 2,
        image: HomePageImages.bacninh_online
    },
    {
        id: 3,
        image: HomePageImages.vn
    },
]

export const ReasonRight = [
    {
        id: 0,
        title: 'Đội ngũ chuyên gia lên đến 20 năm kinh nghiệm',
        image: HomePageImages.right_1,
    },
    {
        id: 1,
        title: 'Chi phí hợp lý, nhiều ưu đãi tốt',
        image: HomePageImages.right_2,
    },
    {
        id: 2,
        title: '+ 1000 tour trong nước và quốc tế',
        image: HomePageImages.right_3,
    },
    {
        id: 3,
        title: 'Đào tạo bài bản ngoại ngữ, kỹ năng mềm',
        image: HomePageImages.right_4,
    },
    {
        id: 4,
        title: 'Cơ sở vật chất và ký túc xá tiện nghi, hiện đại.',
        image: HomePageImages.right_5,
    },
    {
        id: 5,
        title: 'Đồng hành hỗ trợ xuyên suốt quá trình',
        image: HomePageImages.right_6,
    },
]

export const Review = [
    {
        id: 0,
        avatar: ReviewImages[1],
        full_name: 'Trần Thị Thu',
        comp: 'Công ty V99 Group',
        content: "Tôi xin gửi lời cảm ơn chân thành đến Dabosa Travel vì đã tổ chức một chuyến đi Thái Lan thật sự tuyệt vời. Sự chuyên nghiệp trong việc lên kế hoạch, sự tận tâm trong phục vụ và sự am hiểu sâu sắc về văn hóa địa phương của các bạn đã để lại ấn tượng sâu sắc trong lòng tôi. Nhờ có Dabosa Travel, chuyến đi của tôi trở nên thật sự ý nghĩa và đáng nhớ."
    },
    {
        id: 1,
        avatar: ReviewImages[2],
        full_name: 'Nguyễn Trà My',
        comp: 'Học viên lớp tiếng Đức',
        content: "Ở đây, mình không chỉ học tiếng mà còn được tham gia rất nhiều hoạt động, chương trình bổ ích. Kết thúc khoá học, Dabosa đã giúp mình tự tin hơn rất nhiều khi đi học tại nước ngoài. "
    },
    {
        id: 2,
        avatar: ReviewImages[3],
        full_name: 'Chị Hà',
        comp: 'Công ty TNHH Nhật Tiến',
        content: 'Từ lúc tư vấn đến lúc lên lịch trình tour cụ thể, nhân viên rất nhiệt tình và luôn đồng hành cùng đoàn. Trong quá trình đi tour, Dabosa cũng chuẩn bị rất chu đáo, đầy đủ các vật dụng như cờ, mũ, thuốc y tế… cho đoàn. Tôi rất hài lòng với dịch vụ của công ty Dabosa'
    },
    {
        id: 3,
        avatar: ReviewImages[4],
        full_name: 'Vũ Thị Thuỷ',
        comp: 'Phụ huynh',
        content: "Không chỉ đơn giản là công ty du học, ở đây con mình còn được học và nâng cao rất nhiều kỹ năng mềm như giao tiếp, làm việc nhóm, ứng xử… Mình rất an tâm và cảm thấy vui khi con có sự thay đổi tích cực. "
    }

]

export const News_List = [
    {
        id: 0,
        img: TourInlandImages.bia_danang,
        title: "Tour Đà Nẵng",
     
    },
    {
        id: 1,
        img: NewImages[2],
        title: "Chào mừng Ngày Phụ nữ Việt Nam 20/10!",
        content:''
    },
    {
        id: 2,
        img: NewImages[3],
        title: "Hiệu trưởng và thầy cô giáo trường Nhật ngữ Aoyama đến tham quan trụ sở Dabosa Group.",
        content:''
    },
]

export const Rec_List = [
    {
        id: 0,
        img: RecImages[1],
        title: '[TUYỂN DỤNG] Chuyên viên Content Marketing'
    },
    {
        id: 1,
        img: RecImages[2],
        title: '[TUYỂN DỤNG] Chuyên viên tuyển sinh du học'
    },
    {
        id: 2,
        img: RecImages[3],
        title: '[TUYỂN DỤNG] Chuyên viên Content Tour du lịch'
    },
    {
        id: 3,
        img: RecImages[4],
        title: '[TUYỂN DỤNG] 02 Chuyên viên Sale + 01 chuyên viên Điều hành Tour du lịch'
    },
]

export default Footer_Button