const HandBookImages = {
    "1": require('./1.png'),
    "2": require('./2.png'),
    "3": require('./3.png'),
    "4": require('./4.png'),
    "5": require('./5.png'),
    "6": require('./6.png'),
    "7": require('./7.png'),
}

export default HandBookImages