import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IntroImages from "src/assets/Intro";
import "src/pages/IntroDabosa/intro.css";
import AOS from "aos";
import "aos/dist/aos.css";
import HeadTab from "src/components/HeadTab/HeadTab";

const Leader = () => {
  const navigate = useNavigate();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <div data-aos="fade-up">
     <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">
            Ban lãnh đạo DABOSA GROUP
          </div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>
        <div className="row col-md-12 pt-5">
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={IntroImages.p_1}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="fs-4 fw-bolder text-success">
              Mr. Nguyễn Minh Quý
            </div>
            <div className="fs-5 fw-bolder text-success">
              Chủ tịch Hội đồng Quản trị
            </div>
            <div className="fw-semibold pt-2">
              • Trình độ: Chuyên gia Kinh tế
            </div>
            <div className="fw-semibold py-2">
              • Ngoại ngữ: Tiếng Trung cao cấp
            </div>
            <div className="fw-semibold">
              • Kinh nghiệm: Có thâm niên làm việc cho các tập đoàn lớn trong và
              ngoài nước. Hiện là Chủ tịch HĐQT kiêm Tổng Giám đốc Công ty Cổ
              phần Quý Phát cùng thương hiệu gia dụng HASUKA hơn 14 năm hoạt
              động, 4 năm liên tiếp được nhận Giải thưởng Chất lượng Quốc gia do
              Thủ tướng Chính phủ trao tặng (2019, 2020, 2021, 2022).
            </div>
          </div>
        </div>

        <div className="w-100 border border-1 border-success my-5" />

        <div className="row col-md-12 pt-5">
          <div className="col-md-6">
            <div className="container-img-p-1">
              <img
                src={IntroImages.p_2}
                width={"100%"}
                style={{ borderRadius: 8 }}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-6 pt-2">
            <div className="fs-4 fw-bolder text-success">
              Mr. Đoàn Quang Quân
            </div>
            <div className="fs-5 fw-bolder text-success">Tổng Giám đốc</div>
            <div className="fw-semibold pt-2">
              • Trình độ: Quản trị Kinh doanh Quốc tế
            </div>
            <div className="fw-semibold py-2">• Ngoại ngữ: Tiếng Anh</div>
            <div className="fw-semibold">
              • Kinh nghiệm: Hơn 08 năm làm việc và đảm nhiệm các vị trí trưởng
              phòng. Điều hành Tour Du lịch, Giám đốc Phát triển Dự án tại một
              số doanh nghiệp về du lịch lữ hành, tư vấn du học và xuất khẩu lao
              động.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leader;
