import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DropdownGioiThieu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <button
          onClick={() => navigate("/introducing-dabosa-group")}
          className="btn border border-0 p-0"
        >
          Về Dabosa Group
        </button>
      ),
    },
    {
      key: "2",
      label: (
        <button
          onClick={() => navigate("/lanh-dao")}
          className="btn border border-0 p-0"
        >
          Ban Lãnh Đạo
        </button>
      ),
    },
  ];

  return (
    <div>
      <Dropdown menu={{ items }} placement="bottomRight">
        <button className="btn-right fw-medium gap-1 d-flex align-items-center">
          {t("INTRODUCE")}
          <DownOutlined style={{ fontSize: 10 }} />
        </button>
      </Dropdown>
    </div>
  );
};

export default DropdownGioiThieu;
