import React from "react";
import FooterImages from "src/assets/Footer";
import IntroImages from "src/assets/Intro";

const Col2 = ({ aos }: any) => {
  return (
    <div>
      <div
        data-aos={aos}
        className="img_col_2"
        style={{
          backgroundImage: `url(${IntroImages.min_3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="container-xxl py-5">
        <div className="text-success fw-bolder fs-3">Thông tin công ty:</div>
        <br />
        <div className="col-md-12 row">
          <div className="col-md-6">
            <div className="text-success">Tên công ty:</div>
            <div className="text-success fw-bolder">
              Công ty Cổ phần Tập đoàn DABOSA
            </div>
            <br />
            <div className="d-flex align-items-center gap-1">
              <div className="text-success">Mã số Doanh nghiệp:</div>
              <div className="fw-bolder text-success">0110668093</div>
            </div>
            <div className="text-success">Giấy phép Tư vấn Du học:</div>
            <div className="text-success">Giấy phép Lữ hành Quốc tế:</div>
            <div className="text-success fw-bolder">
              01-2634/2024 /CDLQGVN-GP LHQT
            </div>
          </div>
          <div className="col-md-6">
            <div className="text-success">Slogan:</div>
            <div className="text-success fw-bolder">
              Chúng tôi là chuyên gia - We are specialists
            </div>
            <br />
            <div className="text-success">Trụ sở chính:</div>
            <div className="text-success fw-bolder">
              Biệt thự số 2 - OBT2 Khu đô thị
            </div>
            <div className="text-success fw-bolder">
              Bắc Linh Đàm, Đại Kim, Hoàng Mai, Hà Nội
            </div>
          </div>
        </div>
        <br />
        <div className="col-md-12 row">
          <div className="col-md-6">
            <div className="fs-3 fw-bolder text-success">
              Thông tin liên hệ:
            </div>
            <br />
            <div className="d-flex align-items-center gap-1">
              <div className="text-success">Hotline Du Học:</div>
              <div className="text-success fw-bolder">096 163 1818</div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <div className="text-success">Hotline Du Lịch:</div>
              <div className="text-success fw-bolder">096 135 1818</div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <div className="text-success">Email:</div>
              <div className="text-success fw-bolder">
                dabosagroup@gmail.com
              </div>
            </div>
            <div className="d-flex align-items-center gap-1">
              <div className="text-success">Website:</div>
              <div className="text-success fw-bolder">dabosagroup.com</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="fs-3 fw-bolder text-success">
              Liên kết mạng xã hội:
            </div>
            <div>
              <div className="d-flex align-items-center justify-content-center gap-3 pt-4">
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(
                        `https://www.facebook.com/DabosaGroup.Official`,
                        "_blank"
                      )
                      ?.focus()
                  }
                >
                  <img
                    src={FooterImages.facebook}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA GROUP
                  </div>
                </button>
                <button
                  className="btn-fb-link"
                  onClick={() =>
                    window
                      .open(`https://www.facebook.com/DabosaTravel/`, "_blank")
                      ?.focus()
                  }
                >
                  <img
                    src={FooterImages.facebook}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <div className="fw-semibold" style={{ fontSize: 14 }}>
                    DABOSA TRAVEL
                  </div>
                </button>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.youtube.com/@GROUPDABOSA`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.youtube}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
                <div className="d-none d-md-block">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.tiktok.com/@dabosa.group`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `https://www.tiktok.com/@dabosa.group`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
              <div className="d-block d-md-none">
                <div className="d-flex justify-content-center align-items-center gap-3 pt-3">
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.youtube.com/@GROUPDABOSA`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.youtube}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                  <button
                    className="btn-fb-link"
                    onClick={() =>
                      window
                        .open(`https://www.tiktok.com/@dabosa.group`, "_blank")
                        ?.focus()
                    }
                  >
                    <img
                      src={FooterImages.tiktok}
                      onClick={() =>
                        window
                          .open(
                            `https://www.tiktok.com/@dabosa.group`,
                            "_blank"
                          )
                          ?.focus()
                      }
                      className="btn border border-0 p-0"
                      width={24}
                      height={24}
                      alt=""
                    />
                    <div className="fw-semibold" style={{ fontSize: 14 }}>
                      DABOSA GROUP
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Col2;
