const ProgramImages = {
    canada: require('./canada.png'),
    dailoan: require('./dailoan.png'),
    duc: require('./duc.png'),
    han: require('./han.png'),
    my: require('./my.png'),
    new: require('./new.png'),
    nhatban: require('./nhatban.png'),
    trungquoc: require('./trungquoc.png'),
    uc: require('./uc.png'),
    nhatban1: require('./nhatban1.png'),
    trungquoc1: require('./trungquoc1.png'),
    duc1: require('./duc1.png'),
    uc1: require('./uc1.png'),
    dailoan1: require('./dailoan1.png'),
    hanquoc1: require('./hanquoc1.png'),
    new1: require('./new1.png'),
}

export default ProgramImages