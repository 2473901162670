import { ArrowRightOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HomePageImages from "src/assets/HomePage";
import HeadTab from "src/components/HeadTab/HeadTab";
import { News_List } from "src/constant/Array";

const News = () => {
  const navigate = useNavigate()

  useEffect(() => {
    window.scrollTo(0, 0);
  },[])

  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="fw-bolder fs-2 text-success">Tin tức & sự kiện</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#589a83",
            }}
          />
        </div>
        <div className="row col-md-12">
          {News_List.map((i) => (
            <div className="col-md-4 mt-3" key={i.id}>
              <button 
              onClick={() => {
                if (i.content) {
                  navigate('chi-tiet-tin-tuc',{
                    state: {content:i.content}
                  })
                }
                else return
              }}
              className="btn border border-0 p-0 d-flex flex-column text-start w-100">
                <img
                  src={i.img}
                  style={{ width: "100%", height: 250, borderRadius: 8 }}
                />
                <div className="text-success py-2 fw-semibold">{i.title}</div>
                <div onClick={() => navigate('/chi-tiet-tin-tuc')} className="d-flex align-items-center gap-2">
                  <ArrowRightOutlined className="text-success" />
                  <div className="fw-bolder text-success">Đọc tiếp</div>
                </div>
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default News;
