import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeadTab from "src/components/HeadTab/HeadTab";

const DetailStudyProgram = () => {
  const { state } = useLocation();
  const content = state?.content;

  useEffect(() => {
    window.scrollTo(0, 0)
  },[content])

  return (
    <div>
      <HeadTab />
      <div className="container-xxl py-5">
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  );
};

export default DetailStudyProgram;
