import React from "react";
import { useNavigate } from "react-router-dom";
import TourInlandImages from "src/assets/Home/TourInland";
import { TourInternational } from "src/constant/Array";

const Tour_International = ({ aos }: any) => {
  const navigate = useNavigate();

  return (
    <div data-aos={aos} className="container-xxl">
      <div
        className="d-flex justify-content-center flex-column"
        style={{
          width: "max-content",
        }}
      >
        <div className="title-body-2">Tour quốc tế</div>
        <div
          style={{
            height: 3,
            backgroundColor: "#00b09b",
          }}
        />
      </div>
      <div className="row mt-4">
        {TourInternational.map((item) => {
          return (
            <div key={item.id} className="col-6 col-md-6 col-lg-3 mb-4">
              <button
                onClick={() => {
                  if (item.content) {
                    navigate("/chi-tiet-du-lich", {
                      state: { content: item.content },
                    });
                  } else {
                  }
                }}
                className="item-tour-inland"
              >
                <div className="d-flex align-items-center flex-column">
                  <div>
                    <img src={item.image} className="img-item-tour-inland" />
                    <div className="title-item-tour-inland">{item.title}</div>
                  </div>
                  <button className="btn-item-tour-inland">Xem chi tiết</button>
                </div>
              </button>
            </div>
          );
        })}
      </div>
      <div className="d-flex align-items-center justify-content-center my-4">
        <button className="btn-see-all-tour-inland">Xem tất cả</button>
      </div>
    </div>
  );
};

export default Tour_International;
