import {
  CaretRightFilled,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Button, Input } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import bg from "src/assets/Home/duhoc.png";
import HomePageImages from "src/assets/HomePage";
import "../homepage.css";
import { Introduce } from "src/constant/Array";
import YouTube from "react-youtube";
import HeadTab from "src/components/HeadTab/HeadTab";

const Advise = ({ aos }: any) => {
  const navigate = useNavigate();
  const slider = React.useRef<any>(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    swipeToSlide: true,
  };

  const opts = {
    height: "720",
    width: "1200",
  };

  const handleLink = (id: number) => {
    switch (id) {
      case 0:
        window.open(`https://youtu.be/T_murKakFWU/`, "_blank")?.focus();
        break;
      case 1:
        window
          .open(
            `https://dantri.com.vn/tam-long-nhan-ai/ban-doc-dan-tri-gop-yeu-thuong-huong-ve-vung-lu-20240917144252762.htm`,
            "_blank"
          )
          ?.focus();
        break;
      case 2:
        window
          .open(
            `https://baobacninh.com.vn/news/-/details/20182/uy-ban-mttq-tinh-tiep-nhan-qua-tang-nhan-ky-niem-77-nam-ngay-thuong-binh-liet-si?fbclid=IwY2xjawGVYk5leHRuA2FlbQIxMAABHciJu2FRPnCmTFA31HBq0Awc_Tgtuc_JM8Kp5RldeV1OANaAGLMsikJkRg_aem_j4sCwvUL9NRz0IpsHGLbUQ`,
            "_blank"
          )
          ?.focus();
        break;
      case 3:
        // window
        //   .open(`https://www.facebook.com/DabosaTravel/`, "_blank")
        //   ?.focus()
        break;

      default:
        break;
    }
  };

  return (
    <div data-aos={aos}>
      <HeadTab />

      <div className="w-100">
        <Slider ref={slider} {...settings}>
          <div className="container-advise">
            <div
              className="container-advise"
              style={{
                backgroundImage: `url(${HomePageImages.bg_reason1})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="w-100 h-100 px-5">
                <div className="d-flex align-items-center justify-content-between w-100 advise-slick">
                  <button
                    className="btn border border-0 p-0"
                    onClick={() => slider?.current?.slickPrev()}
                  >
                    <LeftOutlined className="ic-arrow-advise" />
                  </button>
                  <button
                    className="btn border border-0 p-0"
                    onClick={() => slider?.current?.slickNext()}
                  >
                    <RightOutlined className="ic-arrow-advise" />
                  </button>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="d-flex align-items-center justify-content-center flex-column btn-bottom-advise">
                    <button
                      onClick={() => navigate("/du-hoc")}
                      className="btn-tuvan"
                    >
                      Tư vấn Du học
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column btn-bottom-advise">
                    <button
                      onClick={() => navigate("/du-lich")}
                      className="btn-tuvan"
                    >
                      Tư vấn Du lịch
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-advise">
            <div
              className="container-advise"
              style={{
                backgroundImage: `url(${HomePageImages.bg_reason1})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="w-100 h-100 px-5">
                <div className="d-flex align-items-center justify-content-between w-100 advise-slick">
                  <button
                    className="btn border border-0 p-0"
                    onClick={() => slider?.current?.slickPrev()}
                  >
                    <LeftOutlined className="ic-arrow-advise" />
                  </button>
                  <button
                    className="btn border border-0 p-0"
                    onClick={() => slider?.current?.slickNext()}
                  >
                    <RightOutlined className="ic-arrow-advise" />
                  </button>
                </div>
                <div className="d-flex justify-content-center gap-3">
                  <div className="d-flex align-items-center justify-content-center flex-column btn-bottom-advise">
                    <button
                      onClick={() => navigate("/du-hoc")}
                      className="btn-tuvan"
                    >
                      Tư vấn Du học
                    </button>
                  </div>
                  <div className="d-flex align-items-center justify-content-center flex-column btn-bottom-advise">
                    <button
                      onClick={() => navigate("/du-lich")}
                      className="btn-tuvan"
                    >
                      Tư vấn Du lịch
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
      <div
        className="container-introduce"
        style={{
          backgroundImage: `url(${HomePageImages.bg_introduce})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="container-xxl">
          <div className="d-flex flex-column align-items-center">
            <div className="title-introduce">Giới thiệu về Dabosa Group</div>
            <div className="text-white fs-5 text-center pt-2 w-50 d-none d-sm-block">
              Chúng tôi luôn nỗ lực trở thành cầu nối đáng tin cậy, mang đến
              giải pháp tối ưu với chất lượng dịch vụ vượt trội và chi phí hợp
              lý.
            </div>
            <div className="text-white text-center pt-2 d-block d-sm-none">
              Chúng tôi luôn nỗ lực trở thành cầu nối đáng tin cậy, mang đến
              giải pháp tối ưu với chất lượng dịch vụ vượt trội và chi phí hợp
              lý.
            </div>
            <div className="d-none d-md-block">
              <div className="container-video-advise">
                <YouTube opts={opts} videoId="T_murKakFWU" />
              </div>
            </div>
            {/* <div className="d-block d-md-none">
              <div className="container-video-advise">
                <YouTube
                  opts={{
                    height: "450",
                    width: "600",
                  }}
                  videoId="VkbgbDH_VrE"
                />
              </div>
            </div> */}
            <div className="d-block d-md-none">
              <div className="container-video-advise">
                <YouTube
                  opts={{
                    height: "230",
                    width: "350",
                  }}
                  videoId="T_murKakFWU"
                />
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-center flex-column"
            style={{
              width: "max-content",
            }}
          >
            <div className="text-white w-100 fw-semibold fs-2 pt-5">
              Báo chí nói về chúng tôi
            </div>
            <div
              style={{
                height: 3,
                backgroundColor: "#027662",
              }}
            />
          </div>
          <div className="pt-4">
            <Slider
              dots={false}
              infinite
              slidesToShow={4}
              slidesToScroll={1}
              swipeToSlide
              autoplay
              speed={800}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: true,
                    speed: 500,
                  },
                },
              ]}
            >
              {Introduce.map((i) => (
                <div
                  onClick={() => handleLink(i.id)}
                  key={i.id}
                  className="btn border border-0 p-0"
                >
                  <img src={i.image} className="item-introduce" alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Advise;
