import React, { useEffect, useState } from "react";
import { Dropdown, MenuProps } from "antd";
import { DownOutlined, UnorderedListOutlined } from "@ant-design/icons";
import HeaderImages from "../../assets/Header";
import "./nav.css";
import ItemRightForMobile from "./Components/ItemRightForMobile";
import { useNavigate } from "react-router-dom";
import DropdownDuHoc from "./Components/DropdownDuHoc";
import DropdownDuLich from "./Components/DropdownDuLich";
import i18n from "src/locales/i18n";
import { useTranslation } from "react-i18next";
import {
  current_language,
  GetCookies,
  SaveCookies,
} from "src/constant/Cookies";
import DropdownGioiThieu from "./Components/DropdownGioiThieu";

const Header = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState<any>("vn");

  useEffect(() => {
    getCurrentLanguage();
  }, []);

  const getCurrentLanguage = () => {
    const language = GetCookies(current_language);
    if (language) {
      setCurrentLanguage(language);
      i18n.changeLanguage(language);
    }
  };

  const onChangeLanguage = (locale: string) => {
    SaveCookies(current_language, locale);
    i18n.changeLanguage(locale);
    setCurrentLanguage(locale);
  };

  return (
    <div className="container-xxl py-4 d-flex justify-content-between align-items-center">
      <button className="btn border border-0 p-0" onClick={() => navigate("/")}>
        <img src={require("src/assets/Logo/logo_app.jpg")} height={45} />
      </button>
      <div className="d-none d-lg-block">
        <div className="d-flex gap-4">
          <DropdownGioiThieu />
          <button
            onClick={() => navigate("/course")}
            className="btn-right fw-medium"
          >
            KHOÁ HỌC
          </button>
          <DropdownDuHoc />
          <DropdownDuLich />
          <button
            onClick={() => navigate("/news")}
            className="btn-right fw-medium"
          >
            TIN TỨC
          </button>
          <button
            onClick={() => navigate("/contact")}
            className="btn-right fw-medium"
          >
            LIÊN HỆ
          </button>
          <button
            onClick={() => navigate("/recruitment")}
            className="btn-right fw-medium"
          >
            TUYỂN DỤNG
          </button>
          {/* {currentLanguage === "vn" ? (
            <button
              className="btn-right fw-medium d-flex align-items-center gap-2"
              onClick={() => onChangeLanguage("en")}
            >
              EN <img src={HeaderImages.my} width={24} />
            </button>
          ) : (
            <button
              className="btn-right fw-medium d-flex align-items-center gap-2"
              onClick={() => onChangeLanguage("vn")}
            >
              VN <img src={HeaderImages.vn} width={24} />
            </button>
          )} */}
        </div>
      </div>
      <ItemRightForMobile />
    </div>
  );
};

export default Header;
