import React from "react";
import { useNavigate } from "react-router-dom";
import { Hand_Book, TravelGuide } from "src/constant/Array";

const Handbook = ({ aos }: any) => {
  const navigate = useNavigate();

  return (
    <div
      data-aos={aos}
      style={{
        background: "#fed842",
      }}
    >
      <div className="container-xxl">
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-study">Cẩm nang du học</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>

        <div className="row mt-4">
          {Hand_Book.map((item) => {
            return (
              <div key={item.id} className="col-6 col-md-6 col-lg-3 mb-4">
                <div className="btn-item-travel pb-3 w-100">
                  <div className="w-100">
                    <img src={item.image} className="img-item-travel" />
                    <div className="txt-title-study">{item.title}</div>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/cam-nang-chi-tiet", {
                        state: { content: item.content },
                      })
                    }
                    className="btn-see-more"
                  >
                    Đọc tiếp
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <button className="btn-see-all-tour-inland mb-4">Xem tất cả</button>
      </div>
    </div>
  );
};

export default Handbook;
