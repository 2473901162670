import React from "react";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";
import { StudyPrograms } from "src/constant/Array";

const Programs = (props: any) => {
  const { aos } = props;
  const navigate = useNavigate();
  const opts = {
    height: "720",
    width: "1300",
  };

  return (
    <div
      style={{
        background: "#fef8e3",
      }}
    >
      <div data-aos={aos} className="container-xxl">
        <div className="d-none d-md-block">
          <div className="container-video-advise">
            <YouTube opts={opts} videoId="JAfmZ7KLd_c" />
          </div>
        </div>
        <div className="d-block d-md-none">
          <div className="container-video-advise">
            <YouTube
              opts={{
                width: 400,
                height: 230,
              }}
              videoId="JAfmZ7KLd_c"
            />
          </div>
        </div>
        <div
          className="d-flex justify-content-center flex-column"
          style={{
            width: "max-content",
          }}
        >
          <div className="title-body-study">Các chương trình du học</div>
          <div
            style={{
              height: 3,
              backgroundColor: "#00b09b",
            }}
          />
        </div>

        <div className="row mt-5">
          {StudyPrograms.map((item) => {
            return (
              <div key={item.id} className="col-6 col-md-6 col-lg-3 mb-4">
                <div className="btn-item-study">
                  <div className="w-100">
                    <img
                      src={item.image}
                      style={{
                        width: "100%",
                        height: 200,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                      }}
                    />
                    <div
                      className="fw-semibold py-2 px-3"
                      style={{
                        color: "#007261",
                      }}
                    >
                      {item.title}
                    </div>
                  </div>
                  <button
                    onClick={() =>
                      navigate("/chi-tiet-du-hoc", {
                        state: { content: item.content },
                      })
                    }
                    className="btn-see-more"
                  >
                    Đọc tiếp
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="d-flex align-items-center justify-content-center">
        <button className="btn-see-all-tour-inland mb-4">Xem tất cả</button>
      </div> */}
    </div>
  );
};

export default Programs;
